import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
// Import uniquement les types et fonctions asynchrones
import { 
  type User, 
  type Auth,
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  isEmailVerified,
  updateUserProfile,
  updateUserEmail,
  updateUserPassword,
  reauthenticateWithCredential,
  deleteUserAccount,
  getFirestore
} from '../firebase';
import { ExtendedUser, UserRole } from '../types/roles';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

interface AuthContextType {
  currentUser: ExtendedUser | null;
  register: (email: string, password: string, displayName?: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  loading: boolean;
  isEmailVerified: (user: User | null) => boolean;
  updateProfile: (profile: { displayName?: string, photoURL?: string }) => Promise<void>;
  updateEmail: (newEmail: string) => Promise<void>;
  updatePassword: (newPassword: string) => Promise<void>;
  reauthenticate: (email: string, password: string) => Promise<void>;
  deleteAccount: (password: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
}

// Liste spéciale d'utilisateurs administrateurs
const ADMIN_EMAILS = ["autopancake.copier075@simplelogin.com"];

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [authInstance, setAuthInstance] = useState<Auth | null>(null);

  // Initialiser l'instance Auth
  useEffect(() => {
    const initAuth = async () => {
      try {
        const auth = await getAuth();
        setAuthInstance(auth);
      } catch (error) {
        console.error("Erreur lors de l'initialisation de l'authentification:", error);
      }
    };
    
    initAuth();
  }, []);

  // Fonction pour mettre à jour ou créer le document utilisateur dans Firestore
  const updateUserFirestoreDocument = async (user: User) => {
    try {
      const db = await getFirestore();
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
      
      // Déterminer le rôle
      let role: UserRole = "client"; // Par défaut, tout utilisateur est un client
      
      // Si l'email est dans la liste des admins, donner le rôle d'admin
      if (user.email && ADMIN_EMAILS.includes(user.email)) {
        role = "admin";
      }
      
      if (userSnap.exists()) {
        // Mettre à jour l'utilisateur existant
        const userData = userSnap.data();
        
        // Ne pas écraser un rôle existant sauf si c'est un admin spécifique
        if (userData.role && role !== "admin") {
          role = userData.role as UserRole;
        }
        
        await updateDoc(userRef, {
          email: user.email,
          displayName: user.displayName || "",
          role: role,
          updatedAt: new Date()
        });
      } else {
        // Créer un nouvel utilisateur
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || "",
          role: role,
          createdAt: new Date(),
          updatedAt: new Date()
        });
      }
      
      // Ajouter le rôle à l'objet utilisateur étendu
      const extendedUser = user as ExtendedUser;
      extendedUser.role = role;
      return extendedUser;
      
    } catch (error) {
      console.error("Erreur lors de la mise à jour du document utilisateur:", error);
      // En cas d'erreur, retourner l'utilisateur sans rôle
      return user as ExtendedUser;
    }
  };

  // Écouter les changements d'état d'authentification
  useEffect(() => {
    if (!authInstance) return;
    
    let unsubscribe: (() => void) | undefined;
    
    const setupAuthListener = async () => {
      try {
        // La fonction onAuthStateChanged retourne une fonction de désinscription
        // Nous devons attendre la promesse et stocker la fonction retournée
        const authStateHandler = async (user: User | null) => {
          if (user) {
            // Si l'utilisateur est connecté, mettre à jour ses informations dans Firestore
            const extendedUser = await updateUserFirestoreDocument(user);
            setCurrentUser(extendedUser);
          } else {
            setCurrentUser(null);
          }
          setLoading(false);
        };
        
        // Appel à onAuthStateChanged qui retourne une Promesse résolvant en fonction de désinscription
        const unsubscribeFunc = await onAuthStateChanged(authStateHandler);
        unsubscribe = unsubscribeFunc as unknown as (() => void);
      } catch (error) {
        console.error("Erreur lors de l'écoute des changements d'authentification:", error);
        setLoading(false);
      }
    };
    
    setupAuthListener();
    
    // Cleanup function
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [authInstance]);

  async function register(email: string, password: string, displayName?: string) {
    try {
      const userCredential = await createUserWithEmailAndPassword(email, password, displayName);
      // Envoyer un email de vérification après l'inscription
      if (userCredential.user) {
        await sendEmailVerification(userCredential.user);
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement:", error);
      throw error;
    }
  }

  async function login(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(email, password);
      
      // Après une connexion réussie, nous naviguons vers le dashboard pour améliorer l'expérience utilisateur
      // Cette ligne sera utilisée par le composant Login pour rediriger l'utilisateur
      window.location.href = '/dashboard';
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      throw error;
    }
  }

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      throw error;
    }
  }

  async function handleSendVerificationEmail() {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    try {
      await sendEmailVerification(currentUser);
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email de vérification:", error);
      throw error;
    }
  }

  async function handleResetPassword(email: string) {
    try {
      await sendPasswordResetEmail(email);
    } catch (error) {
      console.error("Erreur lors de la réinitialisation du mot de passe:", error);
      throw error;
    }
  }

  async function handleUpdateProfile(profile: { displayName?: string, photoURL?: string }) {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    try {
      await updateUserProfile(currentUser, profile);
      // Force le rafraîchissement de l'utilisateur
      const authInstance = await getAuth();
      if (authInstance.currentUser) {
        await authInstance.currentUser.reload();
        // Mettre à jour l'état local de l'utilisateur
        setCurrentUser(authInstance.currentUser as ExtendedUser);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil:", error);
      throw error;
    }
  }

  async function handleUpdateEmail(newEmail: string) {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    try {
      await updateUserEmail(currentUser, newEmail);
      // Envoyer un email de vérification
      await sendEmailVerification(currentUser);
      // Force le rafraîchissement de l'utilisateur
      const authInstance = await getAuth();
      if (authInstance.currentUser) {
        await authInstance.currentUser.reload();
        // Mettre à jour l'état local de l'utilisateur
        setCurrentUser(authInstance.currentUser as ExtendedUser);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'email:", error);
      throw error;
    }
  }

  async function handleUpdatePassword(newPassword: string) {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    try {
      await updateUserPassword(currentUser, newPassword);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du mot de passe:", error);
      throw error;
    }
  }

  async function handleReauthenticate(email: string, password: string) {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    try {
      await reauthenticateWithCredential(currentUser, email, password);
    } catch (error) {
      console.error("Erreur lors de la réauthentification:", error);
      throw error;
    }
  }

  async function handleDeleteAccount(password: string) {
    if (!currentUser) {
      throw new Error("Aucun utilisateur connecté");
    }
    
    try {
      // Réauthentifier l'utilisateur avant de supprimer le compte
      await handleReauthenticate(currentUser.email || '', password);
      
      // Supprimer le compte et les données associées
      await deleteUserAccount(currentUser);
    } catch (error) {
      console.error("Erreur lors de la suppression du compte:", error);
      throw error;
    }
  }

  const value = {
    currentUser,
    register,
    login,
    signOut: handleSignOut,
    sendVerificationEmail: handleSendVerificationEmail,
    resetPassword: handleResetPassword,
    loading,
    isEmailVerified,
    updateProfile: handleUpdateProfile,
    updateEmail: handleUpdateEmail,
    updatePassword: handleUpdatePassword,
    reauthenticate: handleReauthenticate,
    deleteAccount: handleDeleteAccount
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && authInstance && children}
    </AuthContext.Provider>
  );
} 