import { collection, addDoc, serverTimestamp, query, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { getFirestore } from '../firebase';

export type ActivityType = 
  | 'user_registered'
  | 'user_deleted'
  | 'smtp_error' 
  | 'update_success'
  | 'new_ticket'
  | 'ticket_resolved'
  | 'user_login'
  | 'user_logout'
  | 'settings_updated'
  | 'role_updated'
  | 'payment_received'
  | 'payment_failed';

export interface ActivityItem {
  id: string;
  type: ActivityType;
  timestamp: Timestamp;
  message: string;
  data?: any;
}

/**
 * Hook pour gérer les logs d'activité système
 */
export function useActivityLogger() {
  
  /**
   * Ajoute une entrée d'activité dans le log d'activités
   */
  const logActivity = async (
    type: ActivityType,
    message: string,
    data?: any
  ): Promise<void> => {
    try {
      const db = await getFirestore();
      
      // On utilise un bloc try/catch imbriqué pour gérer spécifiquement les erreurs de Firestore
      try {
        const activityRef = collection(db, "activity_logs");
        
        await addDoc(activityRef, {
          type,
          message,
          data,
          timestamp: serverTimestamp(),
          createdAt: new Date()
        });
      } catch (firestoreError) {
        console.warn("Collection activity_logs non accessible ou autre erreur Firestore:", firestoreError);
        // Ne pas bloquer l'application en cas d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'activité:", error);
      // Ne pas relancer l'erreur pour éviter les blocages
    }
  };

  /**
   * Récupère les activités récentes
   */
  const getRecentActivities = async (count: number = 5): Promise<ActivityItem[]> => {
    try {
      const db = await getFirestore();
      
      try {
        const activityRef = collection(db, "activity_logs");
        const recentActivityQuery = query(
          activityRef,
          orderBy("timestamp", "desc"),
          limit(count)
        );
        
        const activitySnap = await getDocs(recentActivityQuery);
        const activityItems: ActivityItem[] = [];
        
        activitySnap.forEach(doc => {
          const data = doc.data();
          // Vérifier que les propriétés nécessaires existent
          if (data && data.type && data.timestamp) {
            activityItems.push({
              id: doc.id,
              type: data.type as ActivityType,
              timestamp: data.timestamp,
              message: data.message || "Activité sans description",
              data: data.data
            });
          }
        });
        
        return activityItems;
      } catch (firestoreError) {
        console.warn("Collection activity_logs non accessible ou autre erreur Firestore:", firestoreError);
        return []; // Retourner un tableau vide en cas d'erreur
      }
    } catch (error) {
      console.error("Erreur lors du chargement des activités récentes:", error);
      return []; // Retourner un tableau vide en cas d'erreur
    }
  };

  return {
    logActivity,
    getRecentActivities
  };
} 