import React, { useState, useEffect } from "react";
import { collection, addDoc, updateDoc, deleteDoc, query, getDocs, doc, serverTimestamp, Timestamp } from "firebase/firestore";
import { getFirestore } from "../../firebase";

// Interface pour définir le type SMTP client
interface SmtpConfig {
  id: string;
  clientName: string;
  clientEmail: string;
  smtpHost: string;
  smtpPort: number;
  smtpUser: string;
  smtpPassword?: string;
  smtpSecurity: "TLS" | "SSL" | "Aucune";
  status: "active" | "inactive";
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

// Interface pour le formulaire
interface SmtpFormData {
  id?: string;
  clientName: string;
  clientEmail: string;
  smtpHost: string;
  smtpPort: number;
  smtpUser: string;
  smtpPassword: string;
  smtpSecurity: "TLS" | "SSL" | "Aucune";
  status: "active" | "inactive";
}

const AdminSmtp: React.FC = () => {
  // États
  const [smtpConfigs, setSmtpConfigs] = useState<SmtpConfig[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [testingId, setTestingId] = useState<string | null>(null);
  const [testResult, setTestResult] = useState<{success: boolean, message: string} | null>(null);
  
  // État du formulaire
  const initialFormState: SmtpFormData = {
    clientName: "",
    clientEmail: "",
    smtpHost: "",
    smtpPort: 587,
    smtpUser: "",
    smtpPassword: "",
    smtpSecurity: "TLS",
    status: "active"
  };
  
  const [formData, setFormData] = useState<SmtpFormData>(initialFormState);

  // Récupérer les configurations SMTP depuis Firebase
  useEffect(() => {
    fetchSmtpConfigs();
  }, []);

  const fetchSmtpConfigs = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const db = await getFirestore();
      const smtpQuery = query(collection(db, "smtp_configs"));
      const querySnapshot = await getDocs(smtpQuery);
      
      const configs: SmtpConfig[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        configs.push({
          id: doc.id,
          clientName: data.clientName || "Client sans nom",
          clientEmail: data.clientEmail || "",
          smtpHost: data.smtpHost || "",
          smtpPort: data.smtpPort || 587,
          smtpUser: data.smtpUser || "",
          smtpSecurity: data.smtpSecurity || "TLS",
          status: data.status || "inactive",
          createdAt: data.createdAt || Timestamp.now(),
          updatedAt: data.updatedAt || Timestamp.now()
        });
      });
      
      setSmtpConfigs(configs);
    } catch (err) {
      console.error("Erreur lors de la récupération des configurations SMTP:", err);
      setError("Impossible de charger les configurations SMTP. Veuillez réessayer plus tard.");
    } finally {
      setIsLoading(false);
    }
  };

  // Gérer les changements dans le formulaire
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    
    setFormData(prev => ({
      ...prev,
      [name]: type === "number" ? parseInt(value) : value
    }));
  };

  // Soumettre le formulaire
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      const db = await getFirestore();
      
      // Préparer les données à sauvegarder
      const smtpData = {
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        smtpHost: formData.smtpHost,
        smtpPort: formData.smtpPort,
        smtpUser: formData.smtpUser,
        smtpSecurity: formData.smtpSecurity,
        status: formData.status,
        updatedAt: Timestamp.now()
      };
      
      // Si nous éditons une configuration existante
      if (editingId) {
        const smtpRef = doc(db, "smtp_configs", editingId);
        await updateDoc(smtpRef, smtpData);
        
        // Mettre à jour l'état local
        setSmtpConfigs(prev => 
          prev.map(config => 
            config.id === editingId 
              ? { ...config, ...smtpData } 
              : config
          )
        );
        
        // Journaliser l'action dans activity_logs
        try {
          await addDoc(collection(db, "activity_logs"), {
            type: "smtp_updated",
            message: `Configuration SMTP mise à jour pour ${formData.clientName}`,
            timestamp: serverTimestamp(),
            data: { clientId: editingId, updatedBy: "admin" }
          });
        } catch (logError) {
          console.warn("Erreur lors de la journalisation:", logError);
        }
      } else {
        // Ajouter le mot de passe pour les nouvelles configurations uniquement
        const newSmtpData = {
          ...smtpData,
          smtpPassword: formData.smtpPassword,
          createdAt: Timestamp.now()
        };
        
        // Créer une nouvelle configuration
        const docRef = await addDoc(collection(db, "smtp_configs"), newSmtpData);
        
        // Ajouter à l'état local
        setSmtpConfigs(prev => [
          ...prev, 
          { 
            id: docRef.id, 
            ...newSmtpData, 
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now()
          } as SmtpConfig
        ]);
        
        // Journaliser l'action
        try {
          await addDoc(collection(db, "activity_logs"), {
            type: "smtp_created",
            message: `Nouvelle configuration SMTP créée pour ${formData.clientName}`,
            timestamp: serverTimestamp(),
            data: { clientId: docRef.id, createdBy: "admin" }
          });
        } catch (logError) {
          console.warn("Erreur lors de la journalisation:", logError);
        }
      }
      
      // Réinitialiser le formulaire
      setFormData(initialFormState);
      setEditingId(null);
      setShowForm(false);
      
    } catch (err) {
      console.error("Erreur lors de l'enregistrement de la configuration SMTP:", err);
      setError("Impossible d'enregistrer la configuration SMTP. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Modifier une configuration existante
  const handleEdit = (config: SmtpConfig) => {
    setFormData({
      clientName: config.clientName,
      clientEmail: config.clientEmail,
      smtpHost: config.smtpHost,
      smtpPort: config.smtpPort,
      smtpUser: config.smtpUser,
      smtpPassword: "", // Ne pas afficher le mot de passe, même masqué
      smtpSecurity: config.smtpSecurity,
      status: config.status
    });
    
    setEditingId(config.id);
    setShowForm(true);
    setTestResult(null);
  };

  // Supprimer une configuration
  const handleDelete = async (id: string) => {
    if (!window.confirm("Êtes-vous sûr de vouloir supprimer cette configuration SMTP ?")) {
      return;
    }
    
    try {
      const db = await getFirestore();
      const smtpRef = doc(db, "smtp_configs", id);
      await deleteDoc(smtpRef);
      
      // Mettre à jour l'état local
      setSmtpConfigs(prev => prev.filter(config => config.id !== id));
      
      // Journaliser l'action
      try {
        await addDoc(collection(db, "activity_logs"), {
          type: "smtp_deleted",
          message: "Configuration SMTP supprimée",
          timestamp: serverTimestamp(),
          data: { clientId: id, deletedBy: "admin" }
        });
      } catch (logError) {
        console.warn("Erreur lors de la journalisation:", logError);
      }
      
    } catch (err) {
      console.error("Erreur lors de la suppression:", err);
      setError("Impossible de supprimer la configuration. Veuillez réessayer.");
    }
  };

  // Tester une configuration SMTP
  const handleTestSmtp = async (id: string) => {
    setTestingId(id);
    setTestResult(null);
    
    try {
      const config = smtpConfigs.find(c => c.id === id);
      if (!config) return;
      
      // Normalement, cette fonction devrait appeler une API backend qui teste réellement la connexion SMTP
      // Pour cette démonstration, nous simulons simplement une requête qui réussit ou échoue aléatoirement
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simuler un délai réseau
      
      const success = Math.random() > 0.3; // 70% de chance de succès
      
      setTestResult({
        success,
        message: success 
          ? `Connexion réussie au serveur ${config.smtpHost}`
          : `Échec de connexion au serveur ${config.smtpHost}. Vérifiez les identifiants.`
      });
      
      // Journaliser le test
      const db = await getFirestore();
      await addDoc(collection(db, "activity_logs"), {
        type: success ? "smtp_test_success" : "smtp_test_failed",
        message: `Test de configuration SMTP ${success ? 'réussi' : 'échoué'} pour ${config.clientName}`,
        timestamp: serverTimestamp(),
        data: { clientId: id, testedBy: "admin", success }
      });
      
    } catch (err) {
      console.error("Erreur lors du test SMTP:", err);
      setTestResult({
        success: false,
        message: "Erreur technique lors du test. Veuillez réessayer."
      });
    } finally {
      setTestingId(null);
    }
  };

  // Filtrer les configurations
  const filteredConfigs = smtpConfigs.filter(config => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      config.clientName.toLowerCase().includes(searchLower) ||
      config.clientEmail.toLowerCase().includes(searchLower) ||
      config.smtpHost.toLowerCase().includes(searchLower) ||
      config.smtpUser.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Configuration SMTP des clients</h1>
      <p className="text-gray-600 mb-6">
        Cette page affiche les configurations SMTP de tous vos clients pour faciliter le dépannage et les tests.
      </p>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher un client..."
            className="pl-10 pr-4 py-2 border rounded-lg w-full sm:w-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
        
        <button 
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          onClick={() => {
            setEditingId(null);
            setFormData(initialFormState);
            setShowForm(true);
          }}
        >
          Ajouter une configuration SMTP
        </button>
      </div>
      
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {testResult && (
        <div className={`mb-6 ${testResult.success ? 'bg-green-50 border-green-400' : 'bg-red-50 border-red-400'} border-l-4 p-4`}>
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className={`h-5 w-5 ${testResult.success ? 'text-green-400' : 'text-red-400'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                {testResult.success ? (
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                ) : (
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                )}
              </svg>
            </div>
            <div className="ml-3">
              <p className={`text-sm ${testResult.success ? 'text-green-700' : 'text-red-700'}`}>{testResult.message}</p>
            </div>
          </div>
        </div>
      )}
      
      {showForm && (
        <div className="mb-6 bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-medium mb-4">{editingId ? 'Modifier' : 'Ajouter'} une configuration SMTP</h2>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Nom du client</label>
                <input
                  type="text"
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Email du client</label>
                <input
                  type="email"
                  name="clientEmail"
                  value={formData.clientEmail}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Serveur SMTP</label>
                <input
                  type="text"
                  name="smtpHost"
                  value={formData.smtpHost}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  placeholder="smtp.example.com"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Port</label>
                <input
                  type="number"
                  name="smtpPort"
                  value={formData.smtpPort}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  min="1"
                  max="65535"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Nom d'utilisateur</label>
                <input
                  type="text"
                  name="smtpUser"
                  value={formData.smtpUser}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Mot de passe {editingId && "(laisser vide pour conserver l'actuel)"}
                </label>
                <input
                  type="password"
                  name="smtpPassword"
                  value={formData.smtpPassword}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required={!editingId}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Sécurité</label>
                <select
                  name="smtpSecurity"
                  value={formData.smtpSecurity}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                >
                  <option value="TLS">TLS</option>
                  <option value="SSL">SSL</option>
                  <option value="Aucune">Aucune</option>
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Statut</label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded-md"
                  required
                >
                  <option value="active">Actif</option>
                  <option value="inactive">Inactif</option>
                </select>
              </div>
            </div>
            
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {isSubmitting ? 'Enregistrement...' : editingId ? 'Mettre à jour' : 'Ajouter'}
              </button>
            </div>
          </form>
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      ) : filteredConfigs.length === 0 ? (
        <div className="bg-white p-6 rounded-lg shadow text-center">
          <p className="text-gray-500">Aucune configuration SMTP trouvée.</p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Serveur SMTP</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Port</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sécurité</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredConfigs.map((smtp) => (
                  <tr key={smtp.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">{smtp.clientName}</div>
                          <div className="text-sm text-gray-500">{smtp.clientEmail}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{smtp.smtpHost}</div>
                      <div className="text-sm text-gray-500">{smtp.smtpUser}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {smtp.smtpPort}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {smtp.smtpSecurity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        smtp.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {smtp.status === 'active' ? 'Actif' : 'Inactif'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button 
                        onClick={() => handleEdit(smtp)}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        Modifier
                      </button>
                      <button 
                        onClick={() => handleTestSmtp(smtp.id)}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                        disabled={testingId === smtp.id}
                      >
                        {testingId === smtp.id ? (
                          <span className="inline-flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Test...
                          </span>
                        ) : "Tester"}
                      </button>
                      <button 
                        onClick={() => handleDelete(smtp.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
      <div className="mt-8 bg-blue-50 border-l-4 border-blue-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-blue-700">
              En cas de problème rapporté par un client, utilisez le bouton "Tester" pour vérifier les identifiants SMTP et valider la configuration.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSmtp; 