import { useAuth } from "../contexts/AuthContext";
import { Permission, DEFAULT_ROLES } from "../types/roles";

export function usePermissions() {
  const { currentUser } = useAuth();

  /**
   * Vérifie si l'utilisateur courant a une permission spécifique
   * @param permission La permission à vérifier
   * @returns true si l'utilisateur a la permission, false sinon
   */
  const hasPermission = (permission: Permission): boolean => {
    if (!currentUser) return false;
    
    // Récupérer le rôle de l'utilisateur
    const userRole = currentUser.role;
    if (!userRole) return false;
    
    // Cas spécial: les clients peuvent toujours créer des tickets
    if (permission === "view_tickets" && userRole === "client") {
      return true;
    }
    
    // Vérifier si le rôle existe dans les rôles par défaut
    const role = DEFAULT_ROLES[userRole];
    if (!role) return false;
    
    // Vérifier si le rôle a la permission
    if (role.permissions.includes(permission)) return true;
    
    // Vérifier si l'utilisateur a des permissions personnalisées
    if (currentUser.customPermissions && currentUser.customPermissions.includes(permission)) {
      return true;
    }
    
    return false;
  };

  /**
   * Vérifie si l'utilisateur courant a toutes les permissions spécifiées
   * @param permissions Les permissions à vérifier
   * @returns true si l'utilisateur a toutes les permissions, false sinon
   */
  const hasAllPermissions = (permissions: Permission[]): boolean => {
    return permissions.every(permission => hasPermission(permission));
  };

  /**
   * Vérifie si l'utilisateur courant a au moins une des permissions spécifiées
   * @param permissions Les permissions à vérifier
   * @returns true si l'utilisateur a au moins une des permissions, false sinon
   */
  const hasAnyPermission = (permissions: Permission[]): boolean => {
    return permissions.some(permission => hasPermission(permission));
  };

  /**
   * Vérifie si l'utilisateur courant a le rôle d'administrateur
   * @returns true si l'utilisateur est admin, false sinon
   */
  const isAdmin = (): boolean => {
    if (!currentUser || !currentUser.role) return false;
    return currentUser.role === 'admin';
  };

  /**
   * Fonction de débogage qui retourne les informations de permissions de l'utilisateur
   * @returns Objet contenant les informations de permissions
   */
  const debugPermissions = () => {
    if (!currentUser) {
      return {
        userId: 'non connecté',
        role: 'aucun',
        permissions: [],
        hasViewTickets: false,
        hasManageTickets: false
      };
    }
    
    const userRole = currentUser.role;
    const role = userRole ? DEFAULT_ROLES[userRole] : null;
    
    return {
      userId: currentUser.uid || 'non connecté',
      role: userRole || 'aucun',
      permissions: role ? role.permissions : [],
      hasViewTickets: hasPermission('view_tickets'),
      hasManageTickets: hasPermission('manage_tickets')
    };
  };

  return {
    hasPermission,
    hasAllPermissions,
    hasAnyPermission,
    isAdmin,
    debugPermissions
  };
}

export default usePermissions; 