import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { motion } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface ProfileFormInputs {
  displayName: string;
}

interface EmailFormInputs {
  email: string;
  currentPassword: string;
}

interface PasswordFormInputs {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface DeleteAccountFormInputs {
  password: string;
  confirmText: string;
}

const Profile: React.FC = () => {
  const { currentUser, updateProfile, updateEmail, updatePassword, reauthenticate, deleteAccount } = useAuth();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const { register: registerProfile, handleSubmit: handleSubmitProfile, formState: { errors: errorsProfile } } = useForm<ProfileFormInputs>({
    defaultValues: {
      displayName: currentUser?.displayName || ""
    }
  });

  const { register: registerEmail, handleSubmit: handleSubmitEmail, formState: { errors: errorsEmail } } = useForm<EmailFormInputs>({
    defaultValues: {
      email: currentUser?.email || ""
    }
  });

  const { register: registerPassword, handleSubmit: handleSubmitPassword, watch: watchPassword, formState: { errors: errorsPassword } } = useForm<PasswordFormInputs>();
  const newPassword = watchPassword("newPassword", "");

  const { 
    register: registerDeleteAccount, 
    handleSubmit: handleSubmitDeleteAccount, 
    formState: { errors: errorsDeleteAccount },
    watch: watchDeleteAccount
  } = useForm<DeleteAccountFormInputs>();

  const validatePasswordMatch = (value: string) => {
    if (value !== newPassword) {
      return "Les mots de passe ne correspondent pas";
    }
    return true;
  };

  const onSubmitProfile: SubmitHandler<ProfileFormInputs> = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      await updateProfile({ displayName: data.displayName });
      toast.success("Votre nom d'utilisateur a été mis à jour avec succès");
    } catch (err: any) {
      setError("Erreur lors de la mise à jour du profil");
      toast.error("Erreur lors de la mise à jour du profil");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitEmail: SubmitHandler<EmailFormInputs> = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      
      // Réauthentifier l'utilisateur avant de changer l'email
      try {
        await reauthenticate(currentUser?.email || "", data.currentPassword);
      } catch (authError) {
        throw new Error("Mot de passe incorrect");
      }
      
      await updateEmail(data.email);
      toast.success("Un email de vérification a été envoyé à votre nouvelle adresse. Veuillez vérifier votre boîte de réception.");
    } catch (err: any) {
      if (err.message === "Mot de passe incorrect") {
        setError(err.message);
        toast.error(err.message);
      } else if (err.code === "auth/email-already-in-use") {
        setError("Cette adresse email est déjà utilisée");
        toast.error("Cette adresse email est déjà utilisée");
      } else {
        setError("Erreur lors de la mise à jour de l'email");
        toast.error("Erreur lors de la mise à jour de l'email");
        console.error(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmitPassword: SubmitHandler<PasswordFormInputs> = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);
      
      // Réauthentifier l'utilisateur avant de changer le mot de passe
      try {
        await reauthenticate(currentUser?.email || "", data.currentPassword);
      } catch (authError) {
        throw new Error("Mot de passe actuel incorrect");
      }
      
      await updatePassword(data.newPassword);
      toast.success("Votre mot de passe a été mis à jour avec succès");
    } catch (err: any) {
      if (err.message === "Mot de passe actuel incorrect") {
        setError(err.message);
        toast.error(err.message);
      } else {
        setError("Erreur lors de la mise à jour du mot de passe");
        toast.error("Erreur lors de la mise à jour du mot de passe");
        console.error(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmitDeleteAccount: SubmitHandler<DeleteAccountFormInputs> = async (data) => {
    if (data.confirmText !== "SUPPRIMER MON COMPTE") {
      setError("Veuillez saisir la phrase de confirmation exactement comme indiqué");
      return;
    }
    
    try {
      setError("");
      setLoading(true);
      
      // Réauthentifier l'utilisateur avant de supprimer le compte
      try {
        await reauthenticate(currentUser?.email || "", data.password);
      } catch (authError) {
        throw new Error("Mot de passe incorrect");
      }
      
      await deleteAccount(data.password);
      navigate("/");
      toast.info("Votre compte a été supprimé avec succès");
    } catch (err: any) {
      if (err.message === "Mot de passe incorrect") {
        setError(err.message);
        toast.error(err.message);
      } else {
        setError("Erreur lors de la suppression du compte");
        toast.error("Erreur lors de la suppression du compte");
        console.error(err);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-3xl mx-auto bg-white shadow rounded-lg"
      >
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900">Paramètres du profil</h2>
          <p className="mt-1 text-sm text-gray-600">
            Gérez vos informations personnelles et vos préférences
          </p>
        </div>
        
        <div className="p-6">
          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}
          
          {/* Section Profil */}
          <section className="mb-12">
            <h3 className="text-lg font-medium text-gray-900 mb-4 border-b pb-2">Informations personnelles</h3>
            <form onSubmit={handleSubmitProfile(onSubmitProfile)}>
              <div className="mb-6">
                <label htmlFor="displayName" className="block text-sm font-medium text-gray-700 mb-1">
                  Nom d'utilisateur
                </label>
                <input
                  id="displayName"
                  type="text"
                  {...registerProfile("displayName", { 
                    required: "Le nom d'utilisateur est requis",
                    minLength: { 
                      value: 3, 
                      message: "Le nom d'utilisateur doit comporter au moins 3 caractères" 
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsProfile.displayName && (
                  <p className="mt-1 text-sm text-red-600">{errorsProfile.displayName.message}</p>
                )}
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  {loading ? "Mise à jour..." : "Mettre à jour le profil"}
                </button>
              </div>
            </form>
          </section>
         
          {/* Section Email */}
          <section className="mb-12">
            <h3 className="text-lg font-medium text-gray-900 mb-4 border-b pb-2">Modifier votre adresse email</h3>
            <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Nouvelle adresse email
                </label>
                <input
                  id="email"
                  type="email"
                  {...registerEmail("email", { 
                    required: "L'adresse email est requise",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Format d'email invalide"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsEmail.email && (
                  <p className="mt-1 text-sm text-red-600">{errorsEmail.email.message}</p>
                )}
              </div>
              
              <div className="mb-6">
                <label htmlFor="currentPasswordEmail" className="block text-sm font-medium text-gray-700 mb-1">
                  Mot de passe actuel (pour confirmer le changement)
                </label>
                <input
                  id="currentPasswordEmail"
                  type="password"
                  {...registerEmail("currentPassword", { 
                    required: "Le mot de passe actuel est requis"
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsEmail.currentPassword && (
                  <p className="mt-1 text-sm text-red-600">{errorsEmail.currentPassword.message}</p>
                )}
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  {loading ? "Mise à jour..." : "Mettre à jour l'email"}
                </button>
              </div>
            </form>
          </section>
          
          {/* Section Mot de passe */}
          <section className="mb-12">
            <h3 className="text-lg font-medium text-gray-900 mb-4 border-b pb-2">Modifier votre mot de passe</h3>
            <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
              <div className="mb-4">
                <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700 mb-1">
                  Mot de passe actuel
                </label>
                <input
                  id="currentPassword"
                  type="password"
                  {...registerPassword("currentPassword", { 
                    required: "Le mot de passe actuel est requis"
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsPassword.currentPassword && (
                  <p className="mt-1 text-sm text-red-600">{errorsPassword.currentPassword.message}</p>
                )}
              </div>
              
              <div className="mb-4">
                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                  Nouveau mot de passe
                </label>
                <input
                  id="newPassword"
                  type="password"
                  {...registerPassword("newPassword", { 
                    required: "Le nouveau mot de passe est requis",
                    minLength: {
                      value: 6,
                      message: "Le mot de passe doit comporter au moins 6 caractères"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsPassword.newPassword && (
                  <p className="mt-1 text-sm text-red-600">{errorsPassword.newPassword.message}</p>
                )}
              </div>
              
              <div className="mb-6">
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                  Confirmer le nouveau mot de passe
                </label>
                <input
                  id="confirmPassword"
                  type="password"
                  {...registerPassword("confirmPassword", { 
                    required: "Veuillez confirmer votre mot de passe",
                    validate: validatePasswordMatch
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errorsPassword.confirmPassword && (
                  <p className="mt-1 text-sm text-red-600">{errorsPassword.confirmPassword.message}</p>
                )}
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                  {loading ? "Mise à jour..." : "Mettre à jour le mot de passe"}
                </button>
              </div>
            </form>
          </section>
          
          {/* Section Suppression de compte */}
          <section className="mb-6">
            <h3 className="text-lg font-medium text-red-600 mb-4 border-b border-red-200 pb-2">Zone dangereuse</h3>
            
            {!showDeleteConfirm ? (
              <div>
                <p className="text-sm text-gray-700 mb-4">
                  La suppression de votre compte est définitive et irréversible. Toutes vos données seront perdues.
                </p>
                <button
                  onClick={() => setShowDeleteConfirm(true)}
                  className="w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Supprimer mon compte
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmitDeleteAccount(onSubmitDeleteAccount)}>
                <div className="bg-red-50 p-4 rounded-md mb-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">Attention : Cette action est irréversible</h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p>
                          Supprimer votre compte entraînera la perte immédiate de toutes vos données. Cette action ne peut pas être annulée.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="mb-4">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                    Mot de passe actuel
                  </label>
                  <input
                    id="password"
                    type="password"
                    {...registerDeleteAccount("password", { 
                      required: "Le mot de passe est requis pour confirmer la suppression"
                    })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  {errorsDeleteAccount.password && (
                    <p className="mt-1 text-sm text-red-600">{errorsDeleteAccount.password.message}</p>
                  )}
                </div>
                
                <div className="mb-6">
                  <label htmlFor="confirmText" className="block text-sm font-medium text-gray-700 mb-1">
                    Pour confirmer, tapez "SUPPRIMER MON COMPTE" en majuscules
                  </label>
                  <input
                    id="confirmText"
                    type="text"
                    {...registerDeleteAccount("confirmText", { 
                      required: "Veuillez saisir la phrase de confirmation",
                      validate: (value) => value === "SUPPRIMER MON COMPTE" || "La phrase ne correspond pas exactement"
                    })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  {errorsDeleteAccount.confirmText && (
                    <p className="mt-1 text-sm text-red-600">{errorsDeleteAccount.confirmText.message}</p>
                  )}
                </div>
                
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={() => setShowDeleteConfirm(false)}
                    className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                  >
                    {loading ? "Suppression..." : "Supprimer définitivement"}
                  </button>
                </div>
              </form>
            )}
          </section>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile; 