import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

interface AppLayoutProps {
  title: string;
  children: React.ReactNode;
  hidePageTitle?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({ title, children, hidePageTitle = false }) => {
  return (
    <Container className="py-5">
      <Helmet>
        <title>{title} - Automatic Pancake</title>
      </Helmet>

      {!hidePageTitle && <h1 className="mb-4">{title}</h1>}
      
      {children}
    </Container>
  );
};

export default AppLayout; 