import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import useTickets from '../hooks/useTickets';
import { toast } from 'react-toastify';
import { doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface ContactForm {
  name: string;
  email: string;
  subject: string;
  message: string;
  type: 'support' | 'sales' | 'partnership' | 'other';
}

const Contact: React.FC = () => {
  const { currentUser } = useAuth();
  const { createTicket } = useTickets();
  const [formData, setFormData] = useState<ContactForm>({
    name: currentUser?.displayName || '',
    email: currentUser?.email || '',
    subject: '',
    message: '',
    type: 'support'
  });
  const [loading, setLoading] = useState(false);

  // Fonction pour attribuer le rôle "client" à l'utilisateur s'il n'en a pas déjà un
  const ensureUserHasRole = async () => {
    if (!currentUser) return;
    
    try {
      // Vérifier si le document utilisateur existe déjà
      const userRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      // Si l'utilisateur existe déjà dans Firestore
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Si l'utilisateur n'a pas de rôle, lui attribuer le rôle "client"
        if (!userData.role) {
          await updateDoc(userRef, { 
            role: "client",
            updatedAt: new Date()
          });
          console.log("Rôle 'client' attribué à l'utilisateur existant:", currentUser.uid);
          
          // Forcer le rechargement de la page pour mettre à jour le contexte d'authentification
          window.location.reload();
          return;
        }
      } else {
        // Si le document n'existe pas, le créer avec le rôle "client"
        await setDoc(userRef, { 
          uid: currentUser.uid,
          role: "client",
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          createdAt: new Date(),
          updatedAt: new Date()
        });
        console.log("Nouvel utilisateur avec rôle 'client' créé:", currentUser.uid);
        
        // Forcer le rechargement de la page pour mettre à jour le contexte d'authentification
        window.location.reload();
        return;
      }
    } catch (error) {
      console.error("Erreur lors de l'attribution du rôle client:", error);
    }
  };

  // Vérifier le rôle de l'utilisateur au chargement de la page
  useEffect(() => {
    if (currentUser) {
      ensureUserHasRole();
    }
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (currentUser) {
        // S'assurer que l'utilisateur a un rôle
        await ensureUserHasRole();
        
        // Si l'utilisateur n'a pas de rôle, on arrête ici et on attend le rechargement
        if (!currentUser.role) {
          return;
        }
        
        // Créer un ticket si l'utilisateur est connecté
        const ticketPriority = formData.type === 'support' ? 'medium' : 
                              formData.type === 'sales' ? 'low' : 
                              'medium';
        const ticketCategory = formData.type === 'support' ? 'technical' : 
                              formData.type === 'sales' ? 'billing' : 
                              formData.type === 'partnership' ? 'other' : 'other';
        
        try {
          // Toujours utiliser bypassPermissions=true pour contourner la vérification des permissions
          await createTicket({
            title: formData.subject,
            description: formData.message,
            userId: currentUser.uid,
            priority: ticketPriority,
            category: ticketCategory,
            status: 'open'
          }, true);
          
          toast.success("Votre message a été envoyé et un ticket a été créé. Vous pouvez suivre son statut dans la section Support.");
          
          // Réinitialiser le formulaire en cas de succès
          setFormData({
            name: currentUser?.displayName || '',
            email: currentUser?.email || '',
            subject: '',
            message: '',
            type: 'support'
          });
        } catch (ticketError) {
          console.error("Erreur lors de la création du ticket:", ticketError);
          toast.error("Une erreur est survenue lors de la création du ticket. Veuillez réessayer dans quelques instants.");
        }
      } else {
        // Juste simuler l'envoi du message pour un utilisateur non connecté
        // Ici on pourrait intégrer une API d'envoi d'email par exemple
        console.log('Form submitted:', formData);
        toast.success("Votre message a été envoyé. Nous vous répondrons dès que possible.");
        
        // Réinitialiser le formulaire
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          type: 'support'
        });
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      toast.error("Une erreur est survenue lors de l'envoi du message. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Contactez-nous
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Notre équipe est là pour vous aider. Nous vous répondrons dans les plus brefs délais.
        </p>
        {currentUser && (
          <p className="mt-2 text-sm text-indigo-600">
            En tant qu'utilisateur connecté, votre message créera automatiquement un ticket que vous pourrez suivre dans votre espace personnel.
          </p>
        )}
      </motion.div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
        <motion.div 
          {...fadeIn} 
          transition={{ delay: 0.2 }}
          className="lg:col-span-2"
        >
          <div className="bg-white rounded-xl shadow-sm p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                    Nom complet
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-2">
                  Type de demande
                </label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="support">Support technique</option>
                  <option value="sales">Commercial</option>
                  <option value="partnership">Partenariat</option>
                  <option value="other">Autre</option>
                </select>
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-2">
                  Sujet
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={6}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full px-6 py-3 text-white rounded-md transition ${
                    loading ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                  }`}
                >
                  {loading ? 'Envoi en cours...' : 'Envoyer le message'}
                </button>
              </div>
            </form>
          </div>
        </motion.div>

        <motion.div 
          {...fadeIn} 
          transition={{ delay: 0.4 }}
          className="space-y-8"
        >
          <div className="bg-white rounded-xl shadow-sm p-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Informations de contact
            </h3>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">Email</p>
                  <p className="text-sm text-gray-600">contact@apancake.ru</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">Téléphone</p>
                  <p className="text-sm text-gray-600">+33 1 23 45 67 89</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">Adresse</p>
                  <p className="text-sm text-gray-600">
                    123 Avenue des Champs-Élysées<br />
                    75008 Paris, France
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-8">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">
              Horaires d'ouverture
            </h3>
            <div className="space-y-2">
              <p className="text-sm text-gray-600">
                <span className="font-medium">Support technique :</span><br />
                24/7
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-medium">Service commercial :</span><br />
                Lundi - Vendredi : 9h - 18h
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact; 