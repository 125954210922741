import { User } from "../firebase";

export type UserRole = "admin" | "support" | "writer" | "user" | "client";

export type Permission =
  // Permissions générales
  | "view_dashboard"
  | "manage_profile"
  
  // Permissions d'administration
  | "manage_users"
  | "manage_roles"
  | "view_analytics"
  | "manage_settings"
  
  // Permissions de support
  | "view_tickets"
  | "manage_tickets"
  | "view_customer_data"
  
  // Permissions de contenu
  | "create_post"
  | "edit_post"
  | "delete_post"
  | "publish_post"
  | "manage_categories"
  | "moderate_comments";

export interface Role {
  id: UserRole;
  name: string;
  description: string;
  permissions: Permission[];
}

export interface ExtendedUser extends User {
  role?: UserRole;
  customPermissions?: Permission[];
}

export interface UserPermissions {
  role: UserRole;
  customPermissions?: Permission[];
}

// Définition des rôles par défaut
export const DEFAULT_ROLES: Record<UserRole, Role> = {
  admin: {
    id: "admin",
    name: "Administrateur",
    description: "Accès complet à toutes les fonctionnalités",
    permissions: [
      "view_dashboard",
      "manage_profile",
      "manage_users",
      "manage_roles",
      "view_analytics",
      "manage_settings",
      "view_tickets",
      "manage_tickets",
      "view_customer_data",
      "create_post",
      "edit_post",
      "delete_post",
      "publish_post",
      "manage_categories",
      "moderate_comments"
    ]
  },
  support: {
    id: "support",
    name: "Support",
    description: "Gestion du support client",
    permissions: [
      "view_dashboard",
      "manage_profile",
      "view_tickets",
      "manage_tickets",
      "view_customer_data"
    ]
  },
  writer: {
    id: "writer",
    name: "Rédacteur",
    description: "Gestion du contenu du blog",
    permissions: [
      "view_dashboard",
      "manage_profile",
      "create_post",
      "edit_post"
    ]
  },
  user: {
    id: "user",
    name: "Utilisateur",
    description: "Accès basique",
    permissions: [
      "view_dashboard",
      "manage_profile"
    ]
  },
  client: {
    id: "client",
    name: "Client",
    description: "Utilisateur client avec accès au support",
    permissions: [
      "view_dashboard",
      "manage_profile",
      "view_tickets"
    ]
  }
};

export const ROLES: Role[] = [
  DEFAULT_ROLES.admin,
  DEFAULT_ROLES.support,
  DEFAULT_ROLES.writer,
  DEFAULT_ROLES.user,
  DEFAULT_ROLES.client
]; 