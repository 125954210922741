import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface HelpCategory {
  id: string;
  title: string;
  icon: JSX.Element;
  articles: HelpArticle[];
}

interface HelpArticle {
  id: string;
  title: string;
  excerpt: string;
  category: string;
}

const Help: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const categories: HelpCategory[] = [
    {
      id: 'getting-started',
      title: 'Premiers Pas',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      ),
      articles: [
        {
          id: '1',
          title: 'Comment configurer votre premier serveur SMTP',
          excerpt: 'Guide étape par étape pour ajouter et configurer un serveur SMTP dans votre compte.',
          category: 'getting-started'
        },
        {
          id: '2',
          title: 'Créer votre première campagne email',
          excerpt: 'Apprenez à créer et envoyer votre première campagne email avec notre plateforme.',
          category: 'getting-started'
        }
      ]
    },
    {
      id: 'smtp',
      title: 'Configuration SMTP',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      ),
      articles: [
        {
          id: '3',
          title: 'Configuration avancée des serveurs SMTP',
          excerpt: 'Découvrez toutes les options de configuration pour optimiser vos serveurs SMTP.',
          category: 'smtp'
        },
        {
          id: '4',
          title: 'Résolution des problèmes SMTP courants',
          excerpt: 'Solutions aux problèmes les plus fréquents avec les serveurs SMTP.',
          category: 'smtp'
        }
      ]
    },
    {
      id: 'templates',
      title: 'Templates',
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
      ),
      articles: [
        {
          id: '5',
          title: 'Création de templates personnalisés',
          excerpt: 'Guide pour créer des templates email professionnels et réutilisables.',
          category: 'templates'
        },
        {
          id: '6',
          title: 'Variables dynamiques dans les templates',
          excerpt: 'Utilisation des variables pour personnaliser vos templates.',
          category: 'templates'
        }
      ]
    }
  ];

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const filteredArticles = categories
    .flatMap(category => category.articles)
    .filter(article => {
      const matchesSearch = article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          article.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = !selectedCategory || article.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Centre d'Aide
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Comment pouvons-nous vous aider aujourd'hui ?
        </p>
        <div className="max-w-2xl mx-auto">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher dans l'aide..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            <div className="absolute right-3 top-3 text-gray-400">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.2 }}
        className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
      >
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id === selectedCategory ? null : category.id)}
            className={`p-6 rounded-xl text-left transition ${
              category.id === selectedCategory
                ? 'bg-indigo-50 border-2 border-indigo-200'
                : 'bg-white border border-gray-200 hover:border-indigo-200'
            }`}
          >
            <div className={`w-12 h-12 rounded-full flex items-center justify-center mb-4 ${
              category.id === selectedCategory ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-600'
            }`}>
              {category.icon}
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">{category.title}</h3>
            <p className="text-sm text-gray-600">{category.articles.length} articles</p>
          </button>
        ))}
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.4 }}
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-8">
          {selectedCategory 
            ? `Articles dans ${categories.find(c => c.id === selectedCategory)?.title}`
            : 'Articles Populaires'}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {filteredArticles.map(article => (
            <a
              key={article.id}
              href={`/help/article/${article.id}`}
              className="p-6 bg-white rounded-lg border border-gray-200 hover:border-indigo-200 transition"
            >
              <h3 className="text-lg font-semibold text-gray-900 mb-2">
                {article.title}
              </h3>
              <p className="text-gray-600">{article.excerpt}</p>
            </a>
          ))}
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.6 }}
        className="mt-16 bg-gray-50 rounded-2xl p-8 text-center"
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Besoin d'aide supplémentaire ?
        </h2>
        <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
          Notre équipe de support est disponible 24/7 pour vous aider avec vos questions.
        </p>
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <a 
            href="/contact"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Contacter le Support
          </a>
          <a 
            href="/docs"
            className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Consulter la Documentation
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Help; 