import React from "react";

const Terms: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Conditions d'Utilisation</h1>
      
      <div className="prose prose-lg">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Acceptation des Conditions</h2>
          <p className="text-gray-600 mb-4">
            En accédant et en utilisant ce service, vous acceptez d'être lié par ces conditions d'utilisation.
            Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Description du Service</h2>
          <p className="text-gray-600 mb-4">
            Notre service fournit une plateforme d'envoi d'emails automatisés avec les fonctionnalités suivantes :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Envoi d'emails automatisés</li>
            <li>Gestion des templates</li>
            <li>Suivi des statistiques</li>
            <li>Gestion des listes de diffusion</li>
            <li>Support technique</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Utilisation du Service</h2>
          <p className="text-gray-600 mb-4">
            En utilisant notre service, vous vous engagez à :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Respecter les lois en vigueur</li>
            <li>Ne pas envoyer de spam</li>
            <li>Protéger vos identifiants de connexion</li>
            <li>Respecter les limites d'utilisation</li>
            <li>Maintenir des informations de contact à jour</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Responsabilités</h2>
          <p className="text-gray-600 mb-4">
            Vous êtes responsable de :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>La confidentialité de votre compte</li>
            <li>Le contenu de vos emails</li>
            <li>La conformité avec les lois sur la protection des données</li>
            <li>L'obtention du consentement des destinataires</li>
            <li>La gestion de vos listes de diffusion</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Limitations de Responsabilité</h2>
          <p className="text-gray-600 mb-4">
            Notre service est fourni "tel quel". Nous ne garantissons pas :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>La disponibilité continue du service</li>
            <li>L'absence d'erreurs ou de bugs</li>
            <li>La livraison garantie des emails</li>
            <li>La conservation indéfinie des données</li>
            <li>La compatibilité avec tous les systèmes</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Modifications des Conditions</h2>
          <p className="text-gray-600 mb-4">
            Nous nous réservons le droit de modifier ces conditions à tout moment.
            Les modifications entrent en vigueur dès leur publication.
            L'utilisation continue du service après modification constitue une acceptation des nouvelles conditions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Résiliation</h2>
          <p className="text-gray-600 mb-4">
            Nous nous réservons le droit de suspendre ou de résilier votre accès au service :
            - En cas de violation des conditions d'utilisation
            - Pour protéger la sécurité et l'intégrité du service
            - En cas d'activités frauduleuses ou illégales
          </p>
        </section>
      </div>

      <p className="text-sm text-gray-500">
        Dernière mise à jour : {new Date().toLocaleDateString("fr-FR")}
      </p>
    </div>
  );
};

export default Terms; 