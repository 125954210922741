import React from 'react';
import { motion } from 'framer-motion';

interface JobPosting {
  id: string;
  title: string;
  department: string;
  location: string;
  type: string;
  description: string;
}

const Careers: React.FC = () => {
  const jobPostings: JobPosting[] = [
    {
      id: '1',
      title: 'Développeur Full Stack Senior',
      department: 'Ingénierie',
      location: 'Paris, France',
      type: 'CDI',
      description: 'Nous recherchons un développeur Full Stack expérimenté pour rejoindre notre équipe et participer au développement de notre plateforme d\'envoi d\'emails.'
    },
    {
      id: '2',
      title: 'DevOps Engineer',
      department: 'Infrastructure',
      location: 'Remote - France',
      type: 'CDI',
      description: 'Rejoignez-nous pour gérer et améliorer notre infrastructure cloud, optimiser nos pipelines CI/CD et maintenir nos systèmes de haute disponibilité.'
    },
    {
      id: '3',
      title: 'Product Manager',
      department: 'Produit',
      location: 'Paris, France',
      type: 'CDI',
      description: 'Nous cherchons un Product Manager passionné pour définir la vision produit et travailler en étroite collaboration avec nos équipes de développement.'
    }
  ];

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Rejoignez l'Aventure
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Nous construisons le futur de l'email marketing. Rejoignez une équipe 
          passionnée et contribuez à révolutionner l'industrie.
        </p>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.2 }}
        className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
      >
        <div className="bg-indigo-50 p-8 rounded-xl text-center">
          <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg className="w-8 h-8 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">Innovation</h3>
          <p className="text-gray-600">
            Travaillez sur des projets innovants et relevez des défis techniques passionnants.
          </p>
        </div>

        <div className="bg-green-50 p-8 rounded-xl text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">Impact</h3>
          <p className="text-gray-600">
            Contribuez directement au succès de milliers d'entreprises dans le monde.
          </p>
        </div>

        <div className="bg-blue-50 p-8 rounded-xl text-center">
          <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">Équipe</h3>
          <p className="text-gray-600">
            Rejoignez une équipe talentueuse et bienveillante, passionnée par la tech.
          </p>
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.4 }}
        className="mb-16"
      >
        <h2 className="text-3xl font-semibold text-gray-900 mb-8">Nos Avantages</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="font-semibold text-gray-900 mb-2">Télétravail Flexible</h3>
            <p className="text-gray-600">Travaillez d'où vous voulez, quand vous voulez.</p>
          </div>
          
          <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="font-semibold text-gray-900 mb-2">Formation Continue</h3>
            <p className="text-gray-600">Budget formation et conférences annuel.</p>
          </div>
          
          <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="font-semibold text-gray-900 mb-2">Mutuelle Premium</h3>
            <p className="text-gray-600">Couverture santé complète pour vous et votre famille.</p>
          </div>
          
          <div className="p-6 bg-white rounded-lg shadow">
            <h3 className="font-semibold text-gray-900 mb-2">Stock Options</h3>
            <p className="text-gray-600">Participez à la croissance de l'entreprise.</p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.6 }}
      >
        <h2 className="text-3xl font-semibold text-gray-900 mb-8">Postes Ouverts</h2>
        <div className="space-y-6">
          {jobPostings.map(job => (
            <div 
              key={job.id}
              className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition"
            >
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{job.title}</h3>
                  <div className="flex items-center gap-4 text-sm text-gray-500 mb-4">
                    <span>{job.department}</span>
                    <span>•</span>
                    <span>{job.location}</span>
                    <span>•</span>
                    <span>{job.type}</span>
                  </div>
                  <p className="text-gray-600 mb-4">{job.description}</p>
                </div>
                <button className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition">
                  Postuler
                </button>
              </div>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.8 }}
        className="mt-16 bg-gray-50 rounded-2xl p-8 text-center"
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Vous ne trouvez pas le poste idéal ?
        </h2>
        <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
          Envoyez-nous une candidature spontanée ! Nous sommes toujours à la recherche 
          de talents passionnés pour rejoindre l'aventure.
        </p>
        <a 
          href="mailto:careers@apancake.ru"
          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Candidature Spontanée
        </a>
      </motion.div>
    </div>
  );
};

export default Careers; 