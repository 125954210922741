import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useNotifications } from "../contexts/NotificationContext";
import { 
  Bars3Icon, 
  XMarkIcon, 
  UserCircleIcon, 
  TicketIcon, 
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
  UserIcon,
  BellIcon
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from 'framer-motion';
import useTickets from "../hooks/useTickets";

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [isNotifMenuOpen, setIsNotifMenuOpen] = useState(false);
  const { currentUser, signOut } = useAuth();
  const { unreadMessages, markAsRead } = useNotifications();
  const userMenuRef = useRef<HTMLDivElement>(null);
  const userButtonRef = useRef<HTMLButtonElement>(null);
  const notifMenuRef = useRef<HTMLDivElement>(null);
  
  // Récupérer les tickets pour vérifier s'il y a des notifications
  const { tickets } = useTickets();

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsUserMenuOpen(false);
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  // Fermer le menu si on clique en dehors
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setIsUserMenuOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fermer le menu de notification si on clique en dehors
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (notifMenuRef.current && !notifMenuRef.current.contains(event.target as Node)) {
        setIsNotifMenuOpen(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Vérifier si l'utilisateur a le rôle client, user, admin ou support
  // Si oui, il peut voir le lien Support
  const canAccessSupport = currentUser && (
    currentUser.role === 'client' || 
    currentUser.role === 'user' || 
    currentUser.role === 'admin' || 
    currentUser.role === 'support'
  );

  const showAdminLink = currentUser && currentUser.role === 'admin';

  // Déterminer si l'utilisateur est connecté et a un rôle
  const isLoggedIn = !!currentUser;
  const userRole = currentUser?.role || null;

  // Les éléments de navigation
  interface NavItem {
    to: string;
    label: string;
    alwaysShow: boolean;
    requireAuth?: boolean;
    requiredRoles?: string[];
  }

  const navItems: NavItem[] = [
    { to: '/#features', label: 'Fonctionnalités', alwaysShow: true },
    { to: '/#pricing', label: 'Tarifs', alwaysShow: true },
    { to: '/contact', label: 'Contact', alwaysShow: true },
  ];

  // Fonction pour gérer les liens d'ancrage
  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>, anchor: string) => {
    e.preventDefault();
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      // Si on n'est pas sur la page d'accueil, rediriger vers la page d'accueil avec l'ancre
      window.location.href = `/#${anchor}`;
    }
  };

  // Filtrer les éléments de navigation en fonction de l'état d'authentification et du rôle
  const filteredNavItems = navItems.filter(item => {
    if (item.alwaysShow) return true;
    
    if (item.requireAuth && !isLoggedIn) return false;
    
    if (item.requiredRoles && userRole) {
      return item.requiredRoles.includes(userRole);
    }
    
    return false;
  });

  // Fonction pour marquer toutes les notifications comme lues
  const markAllAsRead = async () => {
    // Pour chaque ticket avec des messages non lus
    for (const ticket of tickets) {
      if ((currentUser?.role === 'client' || currentUser?.role === 'user') && ticket.hasUnreadStaffMessage) {
        await markAsRead(ticket.id);
      }
    }
    setIsNotifMenuOpen(false);
  };

  return (
    <nav className="bg-white shadow-lg sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-2xl font-bold text-indigo-600">
                Automatic Pancake
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {filteredNavItems.map((item) => (
                item.to.startsWith('/#') ? (
                  <a
                    key={item.to}
                    href={item.to}
                    onClick={(e) => handleAnchorClick(e, item.to.substring(2))}
                    className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link
                    key={item.to}
                    to={item.to}
                    className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                  >
                    {item.label}
                  </Link>
                )
              ))}
            </div>
          </div>
          
          {/* Desktop menu */}
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {currentUser ? (
              <div className="flex items-center space-x-4">
                {/* Restauration des boutons d'Administration et Tableau de bord */}
                {showAdminLink && (
                  <Link
                    to="/admin"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                  >
                    Administration
                  </Link>
                )}
                <Link
                  to="/dashboard"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Tableau de bord
                </Link>
                
                {/* Icônes utilisateur et notification - remplacer cette partie */}
                <div className="hidden sm:flex sm:items-center sm:ml-6">
                  {/* Bouton profil utilisateur */}
                  <div className="ml-4 relative flex-shrink-0" ref={userMenuRef}>
                    <button
                      type="button"
                      className="rounded-full bg-white p-1 text-gray-800 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                      ref={userButtonRef}
                    >
                      <span className="sr-only">Ouvrir le menu utilisateur</span>
                      <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Menu déroulant du profil */}
                    <AnimatePresence>
                      {isUserMenuOpen && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.95, y: -10 }}
                          animate={{ opacity: 1, scale: 1, y: 0 }}
                          exit={{ opacity: 0, scale: 0.95, y: -10 }}
                          transition={{ duration: 0.15 }}
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                        >
                          <div className="py-1 border-b border-gray-100">
                            <p className="block px-4 py-2 text-sm text-gray-700 font-medium">
                              {currentUser?.displayName || currentUser?.email}
                            </p>
                          </div>
                          <div className="py-1">
                            <Link
                              to="/profile"
                              className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() => setIsUserMenuOpen(false)}
                            >
                              <UserIcon className="h-5 w-5 mr-2" /> Mon profil
                            </Link>
                            
                            {canAccessSupport && (
                              <Link 
                                to="/support" 
                                className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 relative"
                                onClick={() => setIsUserMenuOpen(false)}
                              >
                                <TicketIcon className="h-5 w-5 mr-2" /> Mes tickets
                              </Link>
                            )}
                            
                            <button
                              onClick={handleSignOut}
                              className="flex w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                            >
                              <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2" /> Déconnexion
                            </button>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Icône notifications avec menu déroulant */}
                  <div className="relative" ref={notifMenuRef}>
                    <button
                      onClick={() => setIsNotifMenuOpen(!isNotifMenuOpen)}
                      className="flex items-center text-gray-600 hover:text-indigo-600 relative"
                    >
                      <BellIcon className="h-6 w-6" />
                      {unreadMessages > 0 && (
                        <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">{unreadMessages}</span>
                      )}
                    </button>
                    
                    {/* Menu déroulant des notifications */}
                    <AnimatePresence mode="wait">
                      {isNotifMenuOpen && (
                        <motion.div
                          key="notif-menu"
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute right-0 mt-2 w-80 bg-white shadow-lg py-2 rounded-md z-10"
                        >
                          <div className="px-4 py-2 border-b border-gray-100">
                            <div className="flex justify-between items-center">
                              <h3 className="text-sm font-semibold text-gray-900">Notifications</h3>
                              {unreadMessages > 0 && (
                                <button
                                  onClick={markAllAsRead}
                                  className="text-xs text-indigo-600 hover:text-indigo-800"
                                >
                                  Marquer tout comme lu
                                </button>
                              )}
                            </div>
                          </div>
                          
                          <div className="max-h-60 overflow-y-auto">
                            {tickets.filter(ticket => 
                              (currentUser?.role === 'client' && ticket.hasUnreadStaffMessage) ||
                              ((currentUser?.role === 'admin' || currentUser?.role === 'support') && ticket.hasUnreadUserMessage)
                            ).length === 0 ? (
                              <div className="px-4 py-2 text-sm text-gray-500">
                                Aucune nouvelle notification
                              </div>
                            ) : (
                              tickets.filter(ticket => 
                                (currentUser?.role === 'client' && ticket.hasUnreadStaffMessage) ||
                                ((currentUser?.role === 'admin' || currentUser?.role === 'support') && ticket.hasUnreadUserMessage)
                              ).map(ticket => (
                                <Link
                                  key={ticket.id}
                                  to={`/tickets/${ticket.id}`}
                                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  onClick={() => setIsNotifMenuOpen(false)}
                                >
                                  <div className="flex items-start">
                                    <div className="flex-shrink-0 pt-0.5">
                                      <TicketIcon className="h-5 w-5 text-indigo-500" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                      <p className="text-sm font-medium text-gray-900">
                                        {ticket.title}
                                      </p>
                                      <p className="mt-1 text-xs text-gray-500">
                                        {new Date(ticket.updatedAt.toDate()).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              ))
                            )}
                          </div>
                          
                          <div className="px-4 py-2 border-t border-gray-100">
                            <Link
                              to="/support"
                              className="block text-xs text-center font-medium text-indigo-600 hover:text-indigo-800"
                              onClick={() => setIsNotifMenuOpen(false)}
                            >
                              Voir tous les tickets
                            </Link>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Connexion
                </Link>
                <Link
                  to="/register"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Inscription
                </Link>
              </div>
            )}
          </div>
          
          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            >
              {isOpen ? (
                <XMarkIcon className="block h-6 w-6" />
              ) : (
                <Bars3Icon className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>
      
      {/* Mobile menu */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {filteredNavItems.map((item) => (
              item.to.startsWith('/#') ? (
                <a
                  key={item.to}
                  href={item.to}
                  onClick={(e) => handleAnchorClick(e, item.to.substring(2))}
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  key={item.to}
                  to={item.to}
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  {item.label}
                </Link>
              )
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            {currentUser ? (
              <div className="space-y-1">
                {/* Restauration des boutons d'administration et tableau de bord dans le menu mobile */}
                {showAdminLink && (
                  <Link
                    to="/admin"
                    className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 bg-red-100"
                  >
                    Administration
                  </Link>
                )}
                <Link
                  to="/dashboard"
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  Tableau de bord
                </Link>
                <Link
                  to="/profile"
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  Profil
                </Link>
                {canAccessSupport && (
                  <Link
                    to="/support"
                    className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                  >
                    <TicketIcon className="inline h-5 w-5 mr-2" /> Mes tickets
                  </Link>
                )}
                <button
                  onClick={handleSignOut}
                  className="block w-full text-left pl-3 pr-4 py-2 text-base font-medium text-red-600 hover:bg-gray-50"
                >
                  <ArrowRightOnRectangleIcon className="inline h-5 w-5 mr-2" /> Déconnexion
                </button>
              </div>
            ) : (
              <div className="space-y-1">
                <Link
                  to="/login"
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  Connexion
                </Link>
                <Link
                  to="/register"
                  className="block pl-3 pr-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  Inscription
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar; 