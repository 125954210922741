import React, { useEffect } from 'react';
import useUsers from '../hooks/useUsers';

// Composant invisible qui s'assure simplement que le hook useUsers est utilisé
// pour synchroniser les utilisateurs avec Firestore
export const UserSynchronizer: React.FC = () => {
  // L'appel à useUsers déclenche le useEffect à l'intérieur du hook
  // qui synchronise l'utilisateur avec Firestore
  const { loading, error } = useUsers();
  
  // On peut logger les erreurs éventuelles pour le debugging
  useEffect(() => {
    if (error) {
      console.error('Erreur dans UserSynchronizer:', error);
    }
  }, [error]);
  
  // Ce composant ne rend rien visuellement
  return null;
}; 