import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../hooks/usePermissions";
import { getFirestore } from "../../firebase";
import { collection, getDocs, query, where, orderBy, limit, Timestamp } from "firebase/firestore";
import { useActivityLogger, ActivityItem } from "../../hooks/useActivityLogger";
import { useAuth } from "../../contexts/AuthContext";

interface StatData {
  userCount: number;
  ticketsCount: { total: number, open: number, resolved: number };
  smtpServers: number;
  revenue: number;
  errors: {
    users: boolean;
    tickets: boolean;
    smtp: boolean;
    revenue: boolean;
    activity: boolean;
  };
}

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();
  const { getRecentActivities } = useActivityLogger();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState<StatData>({
    userCount: 0,
    ticketsCount: { total: 0, open: 0, resolved: 0 },
    smtpServers: 0,
    revenue: 0,
    errors: {
      users: false,
      tickets: false,
      smtp: false,
      revenue: false,
      activity: false
    }
  });
  const [recentActivity, setRecentActivity] = useState<ActivityItem[]>([]);
  const dataLoaded = useRef(false);

  const loadUserStats = async (db: any) => {
    try {
      const usersRef = collection(db, "users");
      const usersSnap = await getDocs(usersRef);
      setStats(prev => ({ ...prev, userCount: usersSnap.size }));
    } catch (error) {
      console.error("Erreur lors du chargement des statistiques utilisateurs:", error);
      setStats(prev => ({ 
        ...prev, 
        errors: { ...prev.errors, users: true } 
      }));
    }
  };

  const loadTicketStats = async (db: any) => {
    try {
      // Compter tous les tickets
      const ticketsRef = collection(db, "tickets");
      const allTicketsSnap = await getDocs(ticketsRef);
      
      // Compter les tickets ouverts
      const openTicketsQuery = query(ticketsRef, where("status", "==", "open"));
      const openTicketsSnap = await getDocs(openTicketsQuery);
      
      // Calculer les tickets résolus
      const resolvedCount = allTicketsSnap.size - openTicketsSnap.size;
      
      setStats(prev => ({
        ...prev,
        ticketsCount: {
          total: allTicketsSnap.size,
          open: openTicketsSnap.size,
          resolved: resolvedCount
        }
      }));
    } catch (error) {
      console.error("Erreur lors du chargement des tickets:", error);
      setStats(prev => ({ 
        ...prev, 
        errors: { ...prev.errors, tickets: true } 
      }));
    }
  };

  const loadSmtpServers = async (db: any) => {
    try {
      // Récupérer les serveurs SMTP si la collection existe
      try {
        const smtpRef = collection(db, "smtp_servers");
        const smtpSnap = await getDocs(smtpRef);
        setStats(prev => ({ ...prev, smtpServers: smtpSnap.size }));
      } catch (error) {
        // Si la collection n'existe pas, supposer 0 serveurs SMTP
        console.warn("Collection SMTP non disponible, utilisation de valeurs par défaut");
        setStats(prev => ({ ...prev, smtpServers: 0 }));
      }
    } catch (error) {
      console.error("Erreur lors du chargement des serveurs SMTP:", error);
      setStats(prev => ({ 
        ...prev, 
        errors: { ...prev.errors, smtp: true } 
      }));
    }
  };

  const loadRevenueData = async (db: any) => {
    try {
      const now = new Date();
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      
      // Récupérer les données de revenus
      const paymentsRef = collection(db, "payments");
      
      // Créer une requête pour récupérer les paiements du mois en cours
      const currentMonthQuery = query(
        paymentsRef,
        where("date", ">=", firstDayOfMonth),
        orderBy("date", "desc") 
      );
      
      try {
        const paymentsSnap = await getDocs(currentMonthQuery);
        
        let totalRevenue = 0;
        let completedPayments = 0;
        
        // Compter uniquement les paiements complétés
        paymentsSnap.forEach(doc => {
          const payment = doc.data();
          if (payment.status === "completed") {
            totalRevenue += payment.amount || 0;
            completedPayments++;
          }
        });
        
        // Log une seule fois avec processus pour éviter la duplication
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Revenus chargés: ${totalRevenue} EUR (${completedPayments} paiements complétés)`);
        }
        
        setStats(prev => ({ ...prev, revenue: totalRevenue }));
      } catch (queryError) {
        console.error("Erreur spécifique lors de la requête payments:", queryError);
        
        // Si la requête plus complexe échoue, essayer une requête plus simple
        try {
          const simpleQuery = query(paymentsRef);
          const allPaymentsSnap = await getDocs(simpleQuery);
          
          let totalRevenue = 0;
          allPaymentsSnap.forEach(doc => {
            const payment = doc.data();
            if (payment.status === "completed") {
              // Vérifier si la date est dans le mois en cours
              const paymentDate = payment.date?.toDate?.() || new Date();
              if (paymentDate >= firstDayOfMonth) {
                totalRevenue += payment.amount || 0;
              }
            }
          });
          
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Revenus chargés (requête simple): ${totalRevenue} EUR`);
          }
          
          setStats(prev => ({ ...prev, revenue: totalRevenue }));
        } catch (simpleQueryError) {
          console.warn("Même la requête simple a échoué:", simpleQueryError);
          throw simpleQueryError;
        }
      }
    } catch (error) {
      console.error("Erreur lors du chargement des revenus:", error);
      setStats(prev => ({ ...prev, revenue: 0, errors: { ...prev.errors, revenue: true } }));
    }
  };

  const loadRecentActivity = async () => {
    try {
      const activities = await getRecentActivities(5);
      setRecentActivity(activities);
    } catch (error) {
      console.error("Erreur lors du chargement des activités récentes:", error);
      setStats(prev => ({ 
        ...prev, 
        errors: { ...prev.errors, activity: true } 
      }));
    }
  };

  const loadAdminData = useCallback(async () => {
    // Éviter de charger plusieurs fois les données
    if (dataLoaded.current) return;
    
    if (!currentUser || !isAdmin()) {
      console.warn("L'utilisateur n'a pas les permissions nécessaires");
      return;
    }
    
    try {
      setIsLoading(true);
      const db = await getFirestore();
      
      // Charger chaque section indépendamment
      const results = await Promise.allSettled([
        loadUserStats(db),
        loadTicketStats(db),
        loadSmtpServers(db),
        loadRevenueData(db),
        loadRecentActivity()
      ]);
      
      // Consigner les résultats pour débogage uniquement en développement
      if (process.env.NODE_ENV !== 'production') {
        results.forEach((result, index) => {
          const tasks = ["users", "tickets", "smtp", "revenue", "activity"];
          if (result.status === "rejected") {
            console.error(`Échec du chargement de ${tasks[index]}:`, result.reason);
          }
        });
      }
      
      // Marquer les données comme chargées
      dataLoaded.current = true;
      
    } catch (error) {
      console.error("Erreur lors du chargement des données d'administration:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, isAdmin, getRecentActivities]);

  useEffect(() => {
    // Vérifier si l'utilisateur est un administrateur
    if (!isAdmin()) {
      navigate("/dashboard");
      return;
    }
    
    // Ne charger les données qu'une fois
    if (!dataLoaded.current) {
      loadAdminData();
    }
  }, [isAdmin, navigate, loadAdminData]);

  const adminModules = [
    {
      id: "users",
      title: "Gestion des utilisateurs",
      description: "Gérer les comptes utilisateurs et les permissions",
      path: "/admin/users",
      icon: (
        <svg className="w-8 h-8 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      )
    },
    {
      id: "roles",
      title: "Rôles et permissions",
      description: "Définir les rôles et les droits d'accès",
      path: "/admin/roles",
      icon: (
        <svg className="w-8 h-8 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
        </svg>
      )
    },
    {
      id: "tickets",
      title: "Tickets de support",
      description: "Voir et gérer tous les tickets de support",
      path: "/support",
      icon: (
        <svg className="w-8 h-8 text-yellow-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
        </svg>
      )
    },
    {
      id: "settings",
      title: "Paramètres",
      description: "Configuration générale de l'application",
      path: "/admin/settings",
      icon: (
        <svg className="w-8 h-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      )
    },
    {
      id: "analytics",
      title: "Analytiques",
      description: "Statistiques et rapports détaillés",
      path: "/admin/analytics",
      icon: (
        <svg className="w-8 h-8 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    {
      id: "smtp",
      title: "Configuration SMTP",
      description: "Gérer les serveurs d'envoi d'emails",
      path: "/admin/smtp",
      icon: (
        <svg className="w-8 h-8 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      )
    }
  ];

  // Formatter les montants avec le symbole €
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  // Formatter les dates
  const formatDate = (timestamp: Timestamp): string => {
    if (!timestamp) return "Date inconnue";
    
    try {
      const date = timestamp.toDate();
      const now = new Date();
      const diffMs = now.getTime() - date.getTime();
      const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
      
      if (diffHrs < 1) {
        return "Il y a quelques minutes";
      } else if (diffHrs === 1) {
        return "Il y a 1 heure";
      } else if (diffHrs < 24) {
        return `Il y a ${diffHrs} heures`;
      } else {
        return date.toLocaleDateString('fr-FR');
      }
    } catch (error) {
      console.error("Erreur lors du formatage de la date:", error);
      return "Date invalide";
    }
  };

  // Obtenir l'icône appropriée pour chaque type d'activité
  const getActivityIcon = (type: string) => {
    switch (type) {
      case "user_registered":
        return (
          <div className="w-8 h-8 rounded-full bg-indigo-100 flex items-center justify-center">
            <svg className="w-4 h-4 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </div>
        );
      case "smtp_error":
        return (
          <div className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center">
            <svg className="w-4 h-4 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
        );
      case "update_success":
        return (
          <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
            <svg className="w-4 h-4 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        );
      case "new_ticket":
      case "ticket_resolved":
        return (
          <div className="w-8 h-8 rounded-full bg-yellow-100 flex items-center justify-center">
            <svg className="w-4 h-4 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </div>
        );
      default:
        return (
          <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
            <svg className="w-4 h-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        );
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold text-gray-900">Panneau d'administration</h1>
        <p className="mt-2 text-gray-600">
          Gérez tous les aspects de votre application à partir de ce panneau.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Utilisateurs</h3>
          <p className="text-3xl font-bold text-indigo-600">{stats.userCount}</p>
          <p className="text-sm text-gray-500 mt-1 text-center">+12% ce mois-ci</p>
        </div>
        <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Tickets de support</h3>
          <p className="text-3xl font-bold text-yellow-600">{stats.ticketsCount.total}</p>
          <p className="text-sm text-gray-500 mt-1 text-center">
            {stats.ticketsCount.open} ouverts, {stats.ticketsCount.resolved} résolus
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Serveurs SMTP</h3>
          <p className="text-3xl font-bold text-green-600">{stats.smtpServers}</p>
          <p className="text-sm text-gray-500 mt-1 text-center">Tous opérationnels</p>
        </div>
        <div className="bg-white rounded-lg shadow p-6 flex flex-col items-center">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Revenus mensuels</h3>
          <p className="text-3xl font-bold text-blue-600">{formatCurrency(stats.revenue)}</p>
          <p className="text-sm text-gray-500 mt-1 text-center">+24% par rapport au mois dernier</p>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg mb-8">
        <div className="p-6 border-b border-gray-200 text-center">
          <h2 className="text-xl font-bold text-gray-900">Modules d'administration</h2>
          <p className="mt-1 text-sm text-gray-500">
            Accédez rapidement à toutes les sections d'administration pour gérer votre application.
          </p>
        </div>
        <div className="p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {adminModules.map((module) => (
            <div
              key={module.id}
              onClick={() => navigate(module.path)}
              className="border rounded-lg p-6 hover:border-indigo-500 hover:shadow-md cursor-pointer transition-all flex items-center justify-center"
            >
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {module.icon}
                </div>
                <div className="ml-4">
                  <h3 className="font-medium text-gray-900">{module.title}</h3>
                  <p className="text-sm text-gray-500 mt-1">{module.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="p-6 border-b border-gray-200 text-center">
          <h2 className="text-xl font-bold text-gray-900">Activité récente</h2>
        </div>
        <div className="p-6">
          {recentActivity.length > 0 ? (
            <ul className="space-y-4">
              {recentActivity.map((activity) => (
                <li key={activity.id} className="flex items-start">
                  <div className="flex-shrink-0">
                    {getActivityIcon(activity.type)}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{activity.message}</p>
                    <p className="text-xs text-gray-500">{formatDate(activity.timestamp)}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center py-4">
              <p className="text-gray-500">Aucune activité récente à afficher</p>
            </div>
          )}
          <div className="mt-6 text-center">
            <button 
              onClick={() => navigate("/admin/activity")}
              className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 mx-auto"
            >
              Voir toutes les activités
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 