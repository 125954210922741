import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface FAQItem {
  question: string;
  answer: React.ReactNode;
  category: string;
}

const FAQ: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>('general');

  const faqItems: FAQItem[] = [
    // Catégorie Générale
    {
      question: "Qu'est-ce que Automatic Pancake ?",
      answer: (
        <p>
          Automatic Pancake est une plateforme d'email marketing moderne qui vous permet de créer, envoyer et analyser vos campagnes email. Notre technologie avancée garantit une délivrabilité optimale et une expérience utilisateur intuitive.
        </p>
      ),
      category: 'general'
    },
    {
      question: "Comment fonctionne la tarification ?",
      answer: (
        <p>
          Nous proposons plusieurs formules adaptées à différents besoins. Vous pouvez commencer gratuitement avec des fonctionnalités limitées, puis passer à un forfait payant à mesure que vos besoins évoluent. Consultez notre <Link to="/pricing" className="text-indigo-600 hover:text-indigo-800">page de tarification</Link> pour plus de détails.
        </p>
      ),
      category: 'general'
    },
    {
      question: "Puis-je essayer Automatic Pancake avant de m'engager ?",
      answer: (
        <p>
          Oui ! Nous proposons un forfait gratuit qui vous permet de tester nos fonctionnalités de base. De plus, nos forfaits payants incluent une période d'essai de 14 jours sans engagement.
        </p>
      ),
      category: 'general'
    },
    {
      question: "Automatic Pancake convient-il aux petites entreprises ?",
      answer: (
        <p>
          Absolument. Notre plateforme s'adapte à toutes les tailles d'entreprises, des entrepreneurs individuels aux grandes organisations. Nos forfaits sont conçus pour évoluer avec votre activité.
        </p>
      ),
      category: 'general'
    },

    // Catégorie Technique
    {
      question: "Comment configurer mon serveur SMTP avec Automatic Pancake ?",
      answer: (
        <p>
          La configuration est simple : dans votre tableau de bord, accédez à "Paramètres" &gt; "Serveurs SMTP" &gt; "Ajouter un serveur". Vous devrez fournir les informations de connexion SMTP (hôte, port, identifiants). Pour des instructions détaillées, consultez notre <Link to="/documentation" className="text-indigo-600 hover:text-indigo-800">documentation</Link>.
        </p>
      ),
      category: 'technical'
    },
    {
      question: "Quels sont les formats d'emails supportés ?",
      answer: (
        <p>
          Automatic Pancake prend en charge les emails au format HTML et texte brut. Notre éditeur visuel vous permet de créer facilement des emails HTML attrayants sans connaissances techniques, et nous générons automatiquement une version texte pour assurer la compatibilité avec tous les clients email.
        </p>
      ),
      category: 'technical'
    },
    {
      question: "Comment puis-je importer mes contacts existants ?",
      answer: (
        <p>
          Vous pouvez importer vos contacts via un fichier CSV ou Excel. Nous prenons également en charge l'intégration directe avec de nombreux CRM et outils populaires. Accédez à "Contacts" &gt; "Importer" dans votre tableau de bord pour commencer.
        </p>
      ),
      category: 'technical'
    },
    {
      question: "Est-il possible d'intégrer Automatic Pancake à mon site web ou application ?",
      answer: (
        <p>
          Oui, nous proposons une API RESTful complète qui vous permet d'intégrer toutes les fonctionnalités d'Automatic Pancake à vos applications existantes. Pour plus d'informations, consultez notre <Link to="/api" className="text-indigo-600 hover:text-indigo-800">documentation API</Link>.
        </p>
      ),
      category: 'technical'
    },

    // Catégorie Délivrabilité
    {
      question: "Comment Automatic Pancake assure-t-il une bonne délivrabilité des emails ?",
      answer: (
        <p>
          Nous utilisons plusieurs technologies pour maximiser la délivrabilité : authentification SPF/DKIM/DMARC, rotation intelligente des serveurs SMTP, analyse de réputation des domaines et serveurs, et optimisation automatique du contenu pour éviter les filtres anti-spam.
        </p>
      ),
      category: 'deliverability'
    },
    {
      question: "Que faire si mes emails arrivent dans les spams ?",
      answer: (
        <div>
          <p className="mb-2">
            Si vos emails arrivent dans les spams, plusieurs facteurs peuvent être en cause. Voici quelques éléments à vérifier :
          </p>
          <ul className="list-disc list-inside">
            <li>Vérifiez que votre domaine est correctement configuré avec SPF, DKIM et DMARC</li>
            <li>Assurez-vous que le contenu de vos emails ne contient pas de termes sensibles pour les filtres anti-spam</li>
            <li>Vérifiez la qualité de votre liste de contacts et le taux d'engagement</li>
            <li>Contactez notre support pour une analyse approfondie</li>
          </ul>
        </div>
      ),
      category: 'deliverability'
    },
    {
      question: "Comment vérifier si mes emails sont bien délivrés ?",
      answer: (
        <p>
          Automatic Pancake fournit des rapports détaillés sur la délivrabilité de vos campagnes, incluant les taux d'ouverture, de clic, et les rebonds. Vous pouvez également configurer des tests de délivrabilité avant l'envoi d'une campagne importante.
        </p>
      ),
      category: 'deliverability'
    },

    // Catégorie Support
    {
      question: "Comment contacter le support ?",
      answer: (
        <p>
          Vous pouvez contacter notre équipe de support via le chat intégré dans votre tableau de bord, par email à support@apancake.ru, ou en créant un ticket dans la section "Support" de votre compte. Les forfaits Pro et Entreprise bénéficient d'un accès prioritaire au support.
        </p>
      ),
      category: 'support'
    },
    {
      question: "Quel est le délai de réponse du support ?",
      answer: (
        <p>
          Pour les comptes gratuits, nous nous efforçons de répondre dans les 48 heures ouvrées. Les comptes Pro bénéficient d'un support prioritaire avec un délai de réponse de 24 heures. Les comptes Entreprise ont accès à un support dédié avec un délai de réponse de 4 heures et un support téléphonique.
        </p>
      ),
      category: 'support'
    },
    {
      question: "Proposez-vous des formations ou des webinaires ?",
      answer: (
        <p>
          Oui, nous organisons régulièrement des webinaires gratuits pour vous aider à tirer le meilleur parti de notre plateforme. Les clients Entreprise bénéficient également de sessions de formation personnalisées. Consultez notre calendrier d'événements dans la section "Ressources" de notre site.
        </p>
      ),
      category: 'support'
    },
  ];

  const categories = [
    { id: 'general', label: 'Général' },
    { id: 'technical', label: 'Technique' },
    { id: 'deliverability', label: 'Délivrabilité' },
    { id: 'support', label: 'Support' },
  ];

  const filteredFAQs = faqItems.filter(item => item.category === activeCategory);

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Questions fréquentes</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Trouvez rapidement des réponses aux questions les plus courantes sur Automatic Pancake.
          </p>
        </div>

        {/* Catégories */}
        <div className="mb-12">
          <div className="sm:hidden">
            <label htmlFor="category-select" className="sr-only">
              Sélectionnez une catégorie
            </label>
            <select
              id="category-select"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={activeCategory}
              onChange={(e) => setActiveCategory(e.target.value)}
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.label}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {categories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => setActiveCategory(category.id)}
                    className={`
                      whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                      ${activeCategory === category.id
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                    `}
                  >
                    {category.label}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Liste FAQ */}
        <div className="space-y-6">
          {filteredFAQs.map((faq, index) => (
            <div key={index} className="bg-white shadow rounded-lg overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center font-medium cursor-pointer list-none p-6">
                  <span className="text-lg font-semibold text-gray-900">{faq.question}</span>
                  <span className="transition group-open:rotate-180">
                    <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 pt-0 text-gray-700">
                  {faq.answer}
                </div>
              </details>
            </div>
          ))}
        </div>

        {/* Pas trouvé votre réponse ? */}
        <div className="mt-16 bg-indigo-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Vous n'avez pas trouvé votre réponse ?</h2>
          <p className="text-gray-600 mb-8 max-w-3xl mx-auto">
            Notre équipe de support est disponible pour répondre à toutes vos questions et vous aider à tirer le meilleur parti d'Automatic Pancake.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
            <Link to="/contact" className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              Contacter le support
            </Link>
            <Link to="/documentation" className="inline-flex justify-center items-center px-6 py-3 border border-gray-300 bg-white text-base font-medium rounded-md text-gray-700 hover:bg-gray-50">
              Consulter la documentation
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ; 