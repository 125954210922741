// Importations uniquement des types pour éviter toute initialisation
import type { FirebaseApp } from "firebase/app";
import type { Auth, User } from "firebase/auth";
import type { Firestore } from "firebase/firestore";

// Variables pour stockage des instances
let app: FirebaseApp | null = null;
let auth: Auth | null = null;
let db: Firestore | null = null;
let isInitialized = false;

/**
 * Obtenir la configuration Firebase - seulement quand nécessaire
 */
const getFirebaseConfig = () => {
  // Vérifier si les variables d'environnement essentielles sont définies
  const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  
  // Log pour debug - à commenter en production
  if (process.env.NODE_ENV !== 'production') {
    console.log('ENV Variables check:', {
      apiKey: apiKey ? 'Défini' : 'Non défini',
      authDomain: authDomain ? 'Défini' : 'Non défini',
      projectId: projectId ? 'Défini' : 'Non défini',
      nodeEnv: process.env.NODE_ENV
    });
  }
  
  if (!apiKey || !authDomain || !projectId) {
    console.warn("Variables d'environnement Firebase manquantes. Sur Netlify, assurez-vous de les avoir configurées correctement.");
  }
  
  return {
    apiKey: apiKey || 'dummy-api-key',
    authDomain: authDomain || 'dummy-domain.firebaseapp.com',
    projectId: projectId || 'dummy-project-id',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'dummy-bucket.appspot.com',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '000000000000',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:000000000000:web:0000000000000000000000',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
};

/**
 * Initialiser Firebase de façon sûre - n'importe quelle fonction a besoin de Firebase doit appeler ceci d'abord
 */
const initializeFirebase = async (): Promise<void> => {
  if (isInitialized) return;
  
  try {
    // Importation dynamique de Firebase - seulement quand nécessaire
    const { initializeApp, getApp } = await import("firebase/app");
    
    try {
      // Essayer d'obtenir une app existante
      app = getApp();
      console.log("Firebase: Instance existante récupérée");
    } catch (e) {
      // Initialiser si aucune app existante
      const config = getFirebaseConfig();
      try {
        app = initializeApp(config);
        console.log("Firebase: Nouvelle instance initialisée");
      } catch (initError) {
        console.error("Firebase: Erreur d'initialisation avec la configuration fournie:", initError);
        console.log("Firebase: Tentative avec configuration minimale...");
        // En cas d'échec, essayer avec une configuration minimale pour le build
        app = initializeApp({
          apiKey: 'dummy-key-for-build',
          authDomain: 'dummy-domain.firebaseapp.com',
          projectId: 'dummy-project-id',
        });
        console.warn("Firebase: Initialisé avec une configuration minimale. Les fonctionnalités Firebase ne fonctionneront pas correctement.");
      }
    }
    
    isInitialized = true;
  } catch (error) {
    console.error("Firebase: Erreur d'initialisation:", error);
    console.warn("Firebase: L'application fonctionnera en mode dégradé.");
    isInitialized = true; // Marquer comme initialisé pour éviter des tentatives répétées
  }
};

/**
 * Obtenir l'authentification Firebase - initialise Firebase si nécessaire
 */
export const getAuth = async (): Promise<Auth> => {
  if (!isInitialized) await initializeFirebase();
  
  if (!auth) {
    const { getAuth: _getAuth } = await import("firebase/auth");
    auth = _getAuth(app as FirebaseApp);
  }
  
  return auth;
};

/**
 * Obtenir Firestore - initialise Firebase si nécessaire
 */
export const getFirestore = async (): Promise<Firestore> => {
  if (!isInitialized) await initializeFirebase();
  
  if (!db) {
    const { getFirestore: _getFirestore } = await import("firebase/firestore");
    db = _getFirestore(app as FirebaseApp);
  }
  
  return db;
};

/**
 * Fonctions d'authentification exportées avec initialisation lazy
 */
export const createUserWithEmailAndPassword = async (email: string, password: string, displayName?: string) => {
  const { createUserWithEmailAndPassword: _createUserWithEmailAndPassword, updateProfile } = await import("firebase/auth");
  const authInstance = await getAuth();
  const userCredential = await _createUserWithEmailAndPassword(authInstance, email, password);
  
  // Si un displayName est fourni, le mettre à jour dans le profil de l'utilisateur
  if (displayName && userCredential.user) {
    await updateProfile(userCredential.user, { displayName });
  }
  
  return userCredential;
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  const { signInWithEmailAndPassword: _signInWithEmailAndPassword } = await import("firebase/auth");
  const authInstance = await getAuth();
  return _signInWithEmailAndPassword(authInstance, email, password);
};

export const signOut = async () => {
  const { signOut: _signOut } = await import("firebase/auth");
  const authInstance = await getAuth();
  return _signOut(authInstance);
};

export const onAuthStateChanged = (callback: (user: User | null) => void) => {
  (async () => {
    const { onAuthStateChanged: _onAuthStateChanged } = await import("firebase/auth");
    const authInstance = await getAuth();
    return _onAuthStateChanged(authInstance, callback);
  })();
};

/**
 * Envoyer un email de vérification à l'utilisateur courant
 */
export const sendEmailVerification = async (user: User) => {
  const { sendEmailVerification: _sendEmailVerification } = await import("firebase/auth");
  return _sendEmailVerification(user);
};

/**
 * Envoyer un email de réinitialisation de mot de passe
 */
export const sendPasswordResetEmail = async (email: string) => {
  const { sendPasswordResetEmail: _sendPasswordResetEmail } = await import("firebase/auth");
  const authInstance = await getAuth();
  return _sendPasswordResetEmail(authInstance, email);
};

/**
 * Vérifier si l'email de l'utilisateur est vérifié
 */
export const isEmailVerified = (user: User | null): boolean => {
  return user?.emailVerified ?? false;
};

/**
 * Mettre à jour le profil utilisateur (displayName, photoURL)
 */
export const updateUserProfile = async (user: User, profile: { displayName?: string, photoURL?: string }) => {
  const { updateProfile: _updateProfile } = await import("firebase/auth");
  return _updateProfile(user, profile);
};

/**
 * Mettre à jour l'adresse email de l'utilisateur
 */
export const updateUserEmail = async (user: User, newEmail: string) => {
  const { updateEmail: _updateEmail } = await import("firebase/auth");
  return _updateEmail(user, newEmail);
};

/**
 * Mettre à jour le mot de passe de l'utilisateur
 */
export const updateUserPassword = async (user: User, newPassword: string) => {
  const { updatePassword: _updatePassword } = await import("firebase/auth");
  return _updatePassword(user, newPassword);
};

/**
 * Réauthentifier l'utilisateur (nécessaire pour certaines opérations sensibles)
 */
export const reauthenticateWithCredential = async (user: User, email: string, password: string) => {
  const { reauthenticateWithCredential: _reauthenticateWithCredential, EmailAuthProvider } = await import("firebase/auth");
  const credential = EmailAuthProvider.credential(email, password);
  return _reauthenticateWithCredential(user, credential);
};

/**
 * Supprimer un compte utilisateur et ses données associées dans Firestore
 */
export const deleteUserAccount = async (user: User) => {
  try {
    // 1. Supprimer les données utilisateur dans Firestore
    const { deleteDoc, doc, setDoc } = await import("firebase/firestore");
    const firestore = await getFirestore();
    
    try {
      // Avec les règles de sécurité normales, l'utilisateur devrait pouvoir supprimer son propre document
      await deleteDoc(doc(firestore, "users", user.uid));
    } catch (error) {
      console.error("Erreur lors de la suppression du document utilisateur:", error);
      // Nous continuons l'exécution même en cas d'erreur pour effectuer les autres opérations
    }
    
    try {
      // Marquer le compte comme supprimé au lieu de le supprimer réellement
      // Cela contourne les problèmes de permissions
      await setDoc(doc(firestore, "users", user.uid), { 
        markedForDeletion: true, 
        deletedAt: new Date() 
      }, { merge: true });
    } catch (error) {
      console.error("Erreur lors du marquage du compte pour suppression:", error);
    }
    
    try {
      // Supprimer les tickets associés à l'utilisateur
      const { collection, query, where, getDocs } = await import("firebase/firestore");
      const ticketsQuery = query(collection(firestore, "tickets"), where("userId", "==", user.uid));
      const ticketsSnapshot = await getDocs(ticketsQuery);
      
      // Supprimer chaque ticket trouvé
      const deletionPromises = ticketsSnapshot.docs.map(async (ticketDoc) => {
        try {
          await deleteDoc(doc(firestore, "tickets", ticketDoc.id));
        } catch (error) {
          console.error(`Erreur lors de la suppression du ticket ${ticketDoc.id}:`, error);
        }
      });
      
      await Promise.all(deletionPromises);
    } catch (error) {
      console.error("Erreur lors de la suppression des tickets:", error);
    }
    
    // 2. Supprimer le compte utilisateur Firebase
    await user.delete();
    
    return true;
  } catch (error) {
    console.error("Erreur lors de la suppression du compte:", error);
    throw error;
  }
};

// Exporter uniquement les types
export type { User, Auth, Firestore, FirebaseApp }; 