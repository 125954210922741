import React from "react";
import { motion } from "framer-motion";

interface PressRelease {
  id: string;
  title: string;
  date: string;
  content: string;
  pdfUrl: string;
}

interface MediaCoverage {
  id: string;
  title: string;
  source: string;
  date: string;
  content: string;
  url: string;
}

const Press: React.FC = () => {
  const pressReleases: PressRelease[] = [
    {
      id: "1",
      title: "Automatic Pancake lève 5 millions d'euros pour révolutionner l'email marketing",
      date: "15 Mars 2024",
      content: "Automatic Pancake, la startup française spécialisée dans l'envoi d'emails via SMTP personnalisé, annonce une levée de fonds de 5 millions d'euros. Ces fonds permettront d'accélérer le développement international et renforcer ses équipes.",
      pdfUrl: "/press/communique-levee-fonds-2024.pdf"
    },
    {
      id: "2",
      title: "Automatic Pancake lance sa nouvelle plateforme d'envoi d'emails",
      date: "1 Février 2024",
      content: "Automatic Pancake dévoile sa nouvelle plateforme d'envoi d'emails, offrant une expérience utilisateur améliorée et de nouvelles fonctionnalités pour l'envoi d'emails en masse via les serveurs SMTP personnalisés.",
      pdfUrl: "/press/communique-nouvelle-plateforme.pdf"
    }
  ];

  const mediaCoverage: MediaCoverage[] = [
    {
      id: "1",
      title: "Automatic Pancake : la pépite française qui révolutionne l'email marketing",
      source: "TechCrunch",
      date: "16 Mars 2024",
      content: "Un article détaillé sur la success story d'Automatic Pancake et sa solution innovante permettant aux entreprises d'utiliser leurs propres serveurs SMTP.",
      url: "https://techcrunch.com/"
    }
  ];

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Espace Presse
        </h1>
        <p className="text-xl text-gray-600">
          Retrouvez nos communiqués de presse et notre couverture médiatique
        </p>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.2 }}
        className="mb-16"
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-8">
          Communiqués de Presse
        </h2>
        <div className="grid gap-6">
          {pressReleases.map(release => (
            <div 
              key={release.id}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {release.title}
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                {release.date}
              </p>
              <p className="text-gray-600 mb-6">
                {release.content}
              </p>
              <a
                href={release.pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-indigo-600 hover:text-indigo-500"
              >
                <span>Télécharger le PDF</span>
                <svg 
                  className="ml-2 h-5 w-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" 
                  />
                </svg>
              </a>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.4 }}
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-8">
          Dans les Médias
        </h2>
        <div className="grid gap-6">
          {mediaCoverage.map(coverage => (
            <div 
              key={coverage.id}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {coverage.title}
              </h3>
              <div className="flex items-center text-sm text-gray-500 mb-4">
                <span className="font-medium">{coverage.source}</span>
                <span className="mx-2">•</span>
                <span>{coverage.date}</span>
              </div>
              <p className="text-gray-600 mb-6">
                {coverage.content}
              </p>
              <a
                href={coverage.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-indigo-600 hover:text-indigo-500"
              >
                <span>Lire l'article</span>
                <svg 
                  className="ml-2 h-5 w-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                  />
                </svg>
              </a>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Press; 