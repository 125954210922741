import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, setDoc, updateDoc, getDoc, Timestamp } from 'firebase/firestore';
import { getFirestore } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

interface UserData {
  uid: string;
  email: string;
  displayName?: string;
  role?: string;
  createdAt: Date | Timestamp;
  updatedAt: Date | Timestamp;
}

interface FirestoreUserData extends Omit<UserData, 'createdAt' | 'updatedAt'> {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export default function useUsers() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // S'assurer que l'utilisateur authentifié est bien enregistré dans Firestore
  useEffect(() => {
    async function syncUserWithFirestore() {
      if (!currentUser) return;

      try {
        const db = await getFirestore();
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);

        // Si l'utilisateur n'existe pas encore dans Firestore, le créer
        if (!userSnap.exists()) {
          await setDoc(userRef, {
            uid: currentUser.uid,
            email: currentUser.email,
            displayName: currentUser.displayName || '',
            role: 'client', // Attribuer automatiquement le rôle client par défaut
            createdAt: new Date(),
            updatedAt: new Date(),
          });
          console.log(`Utilisateur ${currentUser.uid} synchronisé avec Firestore`);
        } else if (!userSnap.data().role) {
          // Si l'utilisateur existe mais n'a pas de rôle, lui attribuer le rôle client
          await updateDoc(userRef, {
            role: 'client',
            updatedAt: new Date()
          });
          console.log(`Rôle 'client' attribué à l'utilisateur ${currentUser.uid}`);
        }
      } catch (err) {
        console.error("Erreur lors de la synchronisation de l'utilisateur avec Firestore:", err);
        setError("Erreur lors de la synchronisation du profil");
      } finally {
        setLoading(false);
      }
    }

    syncUserWithFirestore();
  }, [currentUser]);

  // Fonction pour obtenir tous les utilisateurs (admin uniquement)
  const getAllUsers = async (): Promise<UserData[]> => {
    if (!currentUser || currentUser.role !== 'admin') {
      throw new Error("Permissions insuffisantes");
    }

    try {
      setLoading(true);
      const db = await getFirestore();
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      
      const users: UserData[] = [];
      usersSnapshot.forEach((docSnap) => {
        const data = docSnap.data() as FirestoreUserData;
        users.push({
          ...data,
          createdAt: data.createdAt instanceof Timestamp ? new Date(data.createdAt.seconds * 1000) : data.createdAt,
          updatedAt: data.updatedAt instanceof Timestamp ? new Date(data.updatedAt.seconds * 1000) : data.updatedAt
        });
      });
      
      return users;
    } catch (err) {
      console.error("Erreur lors de la récupération des utilisateurs:", err);
      setError("Erreur lors de la récupération des utilisateurs");
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour mettre à jour le rôle d'un utilisateur (admin uniquement)
  const updateUserRole = async (userId: string, newRole: string): Promise<boolean> => {
    if (!currentUser || currentUser.role !== 'admin') {
      throw new Error("Permissions insuffisantes");
    }

    try {
      setLoading(true);
      const db = await getFirestore();
      const userRef = doc(db, 'users', userId);
      
      await updateDoc(userRef, {
        role: newRole,
        updatedAt: new Date()
      });
      
      return true;
    } catch (err) {
      console.error(`Erreur lors de la mise à jour du rôle pour l'utilisateur ${userId}:`, err);
      setError("Erreur lors de la mise à jour du rôle");
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    getAllUsers,
    updateUserRole
  };
} 