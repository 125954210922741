import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, updateDoc, deleteDoc, where, Timestamp, addDoc, serverTimestamp } from 'firebase/firestore';
import { getFirestore } from '../../firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Types
interface User {
  id: string;
  displayName: string;
  email: string;
  role: string;
  createdAt: Timestamp;
  lastLogin?: Timestamp;
  disabled?: boolean;
}

const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showConfirmDelete, setShowConfirmDelete] = useState<string | null>(null);
  const [pendingRoleUpdate, setPendingRoleUpdate] = useState<{id: string, role: string} | null>(null);
  
  // Chargement des données
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const db = await getFirestore();
      const usersQuery = query(
        collection(db, "users"),
        orderBy("displayName", "asc")
      );
      
      const querySnapshot = await getDocs(usersQuery);
      const usersData: User[] = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        if (data) {
          const user: User = {
            id: doc.id,
            displayName: data.displayName || 'Utilisateur sans nom',
            email: data.email || 'Sans email',
            role: data.role || 'user',
            createdAt: data.createdAt || Timestamp.now(),
            lastLogin: data.lastLogin,
            disabled: data.disabled
          };
          
          usersData.push(user);
        }
      });
      
      setUsers(usersData);
    } catch (err) {
      console.error("Erreur lors de la récupération des utilisateurs:", err);
      setError("Impossible de charger les utilisateurs. Veuillez réessayer plus tard.");
    } finally {
      setIsLoading(false);
    }
  };

  // Formatage de la date
  const formatDate = (timestamp: Timestamp): string => {
    if (!timestamp || !timestamp.toDate) {
      return "Date inconnue";
    }
    
    const date = timestamp.toDate();
    return format(date, "dd/MM/yyyy", { locale: fr });
  };

  // Mettre à jour le rôle d'un utilisateur
  const updateUserRole = async (userId: string, newRole: string) => {
    try {
      const db = await getFirestore();
      const userRef = doc(db, "users", userId);
      
      await updateDoc(userRef, {
        role: newRole,
        updatedAt: Timestamp.now()
      });
      
      // Journaliser l'action
      try {
        const activityRef = collection(db, "activity_logs");
        await addDoc(activityRef, {
          type: 'role_updated',
          message: `Rôle de l'utilisateur mis à jour: ${newRole}`,
          timestamp: serverTimestamp(),
          data: { userId, newRole, updatedBy: 'admin' }
        });
      } catch (logError) {
        console.warn("Erreur lors de la journalisation:", logError);
      }
      
      // Mettre à jour l'état local
      setUsers(prev => prev.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      setPendingRoleUpdate(null);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du rôle:", err);
      setError("Impossible de mettre à jour le rôle. Veuillez réessayer.");
    }
  };

  // Désactiver/Activer un utilisateur
  const toggleUserStatus = async (userId: string, currentStatus: boolean = false) => {
    try {
      const db = await getFirestore();
      const userRef = doc(db, "users", userId);
      
      await updateDoc(userRef, {
        disabled: !currentStatus,
        updatedAt: Timestamp.now()
      });
      
      // Journaliser l'action
      try {
        const activityRef = collection(db, "activity_logs");
        await addDoc(activityRef, {
          type: !currentStatus ? 'user_disabled' : 'user_enabled',
          message: !currentStatus ? `Utilisateur désactivé` : `Utilisateur activé`,
          timestamp: serverTimestamp(),
          data: { userId, updatedBy: 'admin' }
        });
      } catch (logError) {
        console.warn("Erreur lors de la journalisation:", logError);
      }
      
      // Mettre à jour l'état local
      setUsers(prev => prev.map(user => 
        user.id === userId ? { ...user, disabled: !currentStatus } : user
      ));
      
    } catch (err) {
      console.error("Erreur lors de la modification du statut:", err);
      setError("Impossible de modifier le statut. Veuillez réessayer.");
    }
  };

  // Supprimer un utilisateur
  const deleteUser = async (userId: string) => {
    try {
      const db = await getFirestore();
      const userRef = doc(db, "users", userId);
      
      // Option 1: Suppression réelle
      await deleteDoc(userRef);
      
      // Option 2: Marquer comme supprimé (alternative plus sûre)
      // await updateDoc(userRef, {
      //   deleted: true,
      //   deletedAt: Timestamp.now()
      // });
      
      // Journaliser l'action
      try {
        const activityRef = collection(db, "activity_logs");
        await addDoc(activityRef, {
          type: 'user_deleted',
          message: 'Utilisateur supprimé',
          timestamp: serverTimestamp(),
          data: { userId, deletedBy: 'admin' }
        });
      } catch (logError) {
        console.warn("Erreur lors de la journalisation:", logError);
      }
      
      // Mettre à jour l'état local
      setUsers(prev => prev.filter(user => user.id !== userId));
      setShowConfirmDelete(null);
      
    } catch (err) {
      console.error("Erreur lors de la suppression:", err);
      setError("Impossible de supprimer l'utilisateur. Veuillez réessayer.");
    }
  };

  // Recherche
  const filteredUsers = users.filter(user => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      (user.displayName && user.displayName.toLowerCase().includes(searchLower)) ||
      (user.email && user.email.toLowerCase().includes(searchLower)) ||
      user.role.toLowerCase().includes(searchLower)
    );
  });

  // Rendu des rôles avec code couleur
  const renderRoleBadge = (role: string) => {
    let colorClass = '';
    
    switch (role) {
      case 'admin':
        colorClass = 'bg-purple-100 text-purple-800';
        break;
      case 'moderator':
        colorClass = 'bg-green-100 text-green-800';
        break;
      default:
        colorClass = 'bg-blue-100 text-blue-800';
    }
    
    return (
      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${colorClass}`}>
        {role}
      </span>
    );
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Gestion des Utilisateurs</h1>
      <p className="mb-4">Cette page vous permet de gérer les utilisateurs de votre application.</p>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher un utilisateur..."
            className="pl-10 pr-4 py-2 border rounded-lg w-full sm:w-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
        
        <button 
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
          onClick={() => alert('Fonctionnalité non implémentée')}
        >
          Ajouter un utilisateur
        </button>
      </div>
      
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      ) : filteredUsers.length === 0 ? (
        <div className="bg-white rounded-lg shadow p-6 text-center">
          <p className="text-gray-500">Aucun utilisateur trouvé.</p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nom</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rôle</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date d'inscription</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredUsers.map((user) => (
                  <tr key={user.id} className={user.disabled ? 'bg-gray-50' : 'hover:bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`text-sm font-medium ${user.disabled ? 'text-gray-400' : 'text-gray-900'}`}>
                        {user.displayName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`text-sm ${user.disabled ? 'text-gray-400' : 'text-gray-500'}`}>
                        {user.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {pendingRoleUpdate && pendingRoleUpdate.id === user.id ? (
                        <div className="flex items-center space-x-2">
                          <select 
                            value={pendingRoleUpdate.role} 
                            onChange={(e) => setPendingRoleUpdate({ id: user.id, role: e.target.value })}
                            className="text-sm border rounded px-2 py-1"
                          >
                            <option value="user">user</option>
                            <option value="moderator">moderator</option>
                            <option value="admin">admin</option>
                          </select>
                          <button
                            onClick={() => updateUserRole(user.id, pendingRoleUpdate.role)}
                            className="text-green-600 hover:text-green-900 text-sm"
                          >
                            ✓
                          </button>
                          <button
                            onClick={() => setPendingRoleUpdate(null)}
                            className="text-red-600 hover:text-red-900 text-sm"
                          >
                            ✕
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          {renderRoleBadge(user.role)}
                          <button 
                            onClick={() => setPendingRoleUpdate({ id: user.id, role: user.role })}
                            className="text-gray-500 hover:text-gray-700 text-xs"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(user.createdAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span 
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${user.disabled ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}
                      >
                        {user.disabled ? 'Désactivé' : 'Actif'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button 
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                        onClick={() => toggleUserStatus(user.id, user.disabled)}
                      >
                        {user.disabled ? 'Activer' : 'Désactiver'}
                      </button>
                      
                      {showConfirmDelete === user.id ? (
                        <div className="inline-flex items-center">
                          <span className="text-sm text-red-600 mr-2">Confirmer ?</span>
                          <button 
                            className="text-green-600 hover:text-green-900 mr-2"
                            onClick={() => deleteUser(user.id)}
                          >
                            Oui
                          </button>
                          <button 
                            className="text-gray-600 hover:text-gray-900"
                            onClick={() => setShowConfirmDelete(null)}
                          >
                            Non
                          </button>
                        </div>
                      ) : (
                        <button 
                          className="text-red-600 hover:text-red-900"
                          onClick={() => setShowConfirmDelete(user.id)}
                        >
                          Supprimer
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
      {!isLoading && filteredUsers.length > 0 && (
        <div className="mt-4 text-sm text-gray-500 text-right">
          Affichage de {filteredUsers.length} utilisateur(s)
        </div>
      )}
    </div>
  );
};

export default AdminUsers; 