import React from 'react';
import { Link } from 'react-router-dom';

const API: React.FC = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">API Automatic Pancake</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Intégrez les fonctionnalités d'Automatic Pancake directement dans vos applications avec notre API RESTful.
          </p>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="border-b border-gray-200 px-6 py-4">
            <h2 className="text-2xl font-bold text-gray-900">Vue d'ensemble</h2>
          </div>
          <div className="px-6 py-4">
            <p className="text-gray-700 mb-6">
              Notre API vous permet d'automatiser vos processus d'email marketing en intégrant Automatic Pancake à vos systèmes existants. Vous pouvez créer et gérer des campagnes, des templates, des listes de contacts et bien plus encore.
            </p>
            
            <div className="bg-indigo-50 p-4 rounded-md mb-8">
              <h3 className="text-indigo-800 text-lg font-medium mb-2">Clé d'API requise</h3>
              <p className="text-indigo-700">
                Pour utiliser notre API, vous aurez besoin d'une clé API. Vous pouvez générer une clé dans les paramètres de votre compte.
                Les clés API sont uniquement disponibles pour les forfaits Pro et Entreprise.
              </p>
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4">Authentification</h3>
            <p className="text-gray-700 mb-4">
              Toutes les requêtes API doivent inclure votre clé API dans l'en-tête HTTP "X-API-Key".
            </p>
            <div className="bg-gray-800 text-white p-4 rounded-md font-mono text-sm mb-8 overflow-x-auto">
              <pre>
{`GET /api/v1/campaigns
Host: api.apancake.ru
X-API-Key: votre_clé_api_ici`}
              </pre>
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4">Format de réponse</h3>
            <p className="text-gray-700 mb-4">
              Toutes les réponses sont renvoyées au format JSON. Voici un exemple de réponse d'une requête réussie :
            </p>
            <div className="bg-gray-800 text-white p-4 rounded-md font-mono text-sm mb-8 overflow-x-auto">
              <pre>
{`{
  "success": true,
  "data": {
    "id": "camp_123456",
    "name": "Campagne Printemps",
    "status": "draft",
    "created_at": "2023-03-15T14:30:00Z"
  }
}`}
              </pre>
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4">Gestion des erreurs</h3>
            <p className="text-gray-700 mb-4">
              En cas d'erreur, l'API renvoie un code HTTP approprié avec un message d'erreur :
            </p>
            <div className="bg-gray-800 text-white p-4 rounded-md font-mono text-sm mb-8 overflow-x-auto">
              <pre>
{`{
  "success": false,
  "error": {
    "code": "invalid_request",
    "message": "Le paramètre 'email' est requis"
  }
}`}
              </pre>
            </div>
          </div>
        </div>

        <div className="mt-12 bg-white shadow rounded-lg overflow-hidden">
          <div className="border-b border-gray-200 px-6 py-4">
            <h2 className="text-2xl font-bold text-gray-900">Points d'accès principaux</h2>
          </div>
          <div className="px-6 py-4">
            <div className="divide-y divide-gray-200">
              <div className="py-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Campagnes</h3>
                <p className="text-gray-700 mb-4">
                  Gérez vos campagnes d'emailing.
                </p>
                <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                  <li>GET /api/v1/campaigns - Liste de toutes les campagnes</li>
                  <li>POST /api/v1/campaigns - Créer une nouvelle campagne</li>
                  <li>GET /api/v1/campaigns/:id - Obtenir les détails d'une campagne</li>
                  <li>PUT /api/v1/campaigns/:id - Mettre à jour une campagne</li>
                  <li>DELETE /api/v1/campaigns/:id - Supprimer une campagne</li>
                </ul>
                <div className="bg-gray-100 p-4 rounded-md text-sm">
                  <p className="font-medium text-gray-900 mb-2">Exemple de requête :</p>
                  <div className="bg-gray-800 text-white p-3 rounded-md font-mono overflow-x-auto">
                    <pre>
{`POST /api/v1/campaigns
{
  "name": "Promotion Été 2023",
  "subject": "Découvrez nos offres spéciales été !",
  "template_id": "tpl_789012",
  "list_id": "lst_456789"
}`}
                    </pre>
                  </div>
                </div>
              </div>

              <div className="py-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Contacts</h3>
                <p className="text-gray-700 mb-4">
                  Gérez vos listes de contacts et leurs informations.
                </p>
                <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                  <li>GET /api/v1/contacts - Liste de tous les contacts</li>
                  <li>POST /api/v1/contacts - Ajouter un nouveau contact</li>
                  <li>GET /api/v1/contacts/:id - Obtenir les détails d'un contact</li>
                  <li>PUT /api/v1/contacts/:id - Mettre à jour un contact</li>
                  <li>DELETE /api/v1/contacts/:id - Supprimer un contact</li>
                </ul>
              </div>

              <div className="py-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Templates</h3>
                <p className="text-gray-700 mb-4">
                  Gérez vos templates d'email.
                </p>
                <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
                  <li>GET /api/v1/templates - Liste de tous les templates</li>
                  <li>POST /api/v1/templates - Créer un nouveau template</li>
                  <li>GET /api/v1/templates/:id - Obtenir les détails d'un template</li>
                  <li>PUT /api/v1/templates/:id - Mettre à jour un template</li>
                  <li>DELETE /api/v1/templates/:id - Supprimer un template</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Documentation complète</h2>
          <p className="text-gray-600 mb-8 max-w-3xl mx-auto">
            Pour une documentation complète de l'API, y compris tous les points d'accès disponibles, les paramètres et les exemples, consultez notre documentation technique détaillée.
          </p>
          <Link to="/documentation" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
            Documentation technique
          </Link>
        </div>

        <div className="mt-16 bg-indigo-50 rounded-lg p-8">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Besoin d'aide pour l'intégration ?</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Notre équipe de support est là pour vous aider à intégrer Automatic Pancake dans votre écosystème.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6">
            <Link to="/contact" className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
              Contacter l'équipe technique
            </Link>
            <Link to="/documentation" className="inline-flex justify-center items-center px-6 py-3 border border-gray-300 bg-white text-base font-medium rounded-md text-gray-700 hover:bg-gray-50">
              Consulter la documentation
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default API; 