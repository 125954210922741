import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';

interface Formation {
  id: string;
  title: string;
  description: string;
  price: number;
  category: string;
  imageUrl: string;
  durationHours: number;
  level: 'beginner' | 'intermediate' | 'advanced';
  purchased?: boolean;
  purchaseDate?: string;
  progress?: number;
}

const FormationsHub: React.FC = () => {
  const { currentUser } = useAuth();
  const [availableFormations, setAvailableFormations] = useState<Formation[]>([]);
  const [purchasedFormations, setPurchasedFormations] = useState<Formation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeCategory, setActiveCategory] = useState<string>('all');

  // Catégories disponibles
  const categories = [
    { id: 'all', name: 'Toutes' },
    { id: 'email-marketing', name: 'Email Marketing' },
    { id: 'smtp-config', name: 'Configuration SMTP' },
    { id: 'deliverability', name: 'Délivrabilité' },
  ];

  useEffect(() => {
    // Simuler le chargement des formations depuis la base de données
    const fetchFormations = async () => {
      try {
        // Dans un environnement réel, vous récupéreriez ces données depuis Firestore
        const mockAvailableFormations: Formation[] = [
          {
            id: '1',
            title: 'Email Marketing Avancé',
            description: 'Apprenez les techniques avancées pour optimiser vos campagnes d\'emails et augmenter votre taux de conversion.',
            price: 89,
            category: 'email-marketing',
            imageUrl: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
            durationHours: 4.5,
            level: 'intermediate'
          },
          {
            id: '2',
            title: 'SMTP Optimization',
            description: 'Configurez et optimisez vos serveurs SMTP pour de meilleurs taux de délivrabilité et une meilleure performance.',
            price: 119,
            category: 'smtp-config',
            imageUrl: 'https://images.unsplash.com/photo-1573164713619-24c711fe7878?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80',
            durationHours: 6,
            level: 'advanced'
          },
          {
            id: '3',
            title: 'Template Design',
            description: 'Créez des templates d\'emails modernes et efficaces qui convertissent et engagent vos utilisateurs.',
            price: 69,
            category: 'email-marketing',
            imageUrl: 'https://images.unsplash.com/photo-1626785774573-4b799315345d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80',
            durationHours: 3,
            level: 'beginner'
          },
          {
            id: '4',
            title: 'Délivrabilité des emails',
            description: 'Maximisez votre taux de délivrabilité et évitez les filtres anti-spam grâce à nos techniques éprouvées.',
            price: 129,
            category: 'deliverability',
            imageUrl: 'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
            durationHours: 5,
            level: 'intermediate'
          },
          {
            id: '5',
            title: 'Email Automation',
            description: 'Automatisez vos campagnes d\'emails pour gagner du temps et améliorer l\'engagement de vos utilisateurs.',
            price: 99,
            category: 'email-marketing',
            imageUrl: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80',
            durationHours: 4,
            level: 'intermediate'
          },
          {
            id: '6',
            title: 'DKIM & SPF Configuration',
            description: 'Apprenez à configurer correctement DKIM et SPF pour améliorer la sécurité et la délivrabilité de vos emails.',
            price: 149,
            category: 'smtp-config',
            imageUrl: 'https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
            durationHours: 7,
            level: 'advanced'
          }
        ];

        const mockPurchasedFormations: Formation[] = [
          {
            id: '7',
            title: 'Email Marketing pour débutants',
            description: 'Les fondamentaux du marketing par email pour les débutants.',
            price: 49,
            category: 'email-marketing',
            imageUrl: 'https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1464&q=80',
            durationHours: 2.5,
            level: 'beginner',
            purchased: true,
            purchaseDate: '2024-02-15',
            progress: 75
          }
        ];

        setAvailableFormations(mockAvailableFormations);
        setPurchasedFormations(mockPurchasedFormations);
      } catch (error) {
        console.error('Error fetching formations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormations();
  }, []);

  const handlePurchase = (formationId: string) => {
    // Dans un environnement réel, vous intégreriez ici votre logique de paiement
    // Puis vous mettriez à jour la base de données pour marquer cette formation comme achetée par l'utilisateur
    
    const formation = availableFormations.find(f => f.id === formationId);
    if (formation) {
      const purchasedFormation: Formation = {
        ...formation,
        purchased: true,
        purchaseDate: new Date().toISOString().split('T')[0],
        progress: 0
      };
      
      setPurchasedFormations(prev => [...prev, purchasedFormation]);
      setAvailableFormations(prev => prev.filter(f => f.id !== formationId));
      
      alert(`Formation "${formation.title}" achetée avec succès ! Vous pouvez maintenant y accéder dans "Mes formations".`);
    }
  };

  const filteredFormations = activeCategory === 'all' 
    ? availableFormations 
    : availableFormations.filter(f => f.category === activeCategory);

  const getLevelLabel = (level: Formation['level']) => {
    switch (level) {
      case 'beginner': return 'Débutant';
      case 'intermediate': return 'Intermédiaire';
      case 'advanced': return 'Avancé';
      default: return level;
    }
  };

  const getLevelColor = (level: Formation['level']) => {
    switch (level) {
      case 'beginner': return 'bg-green-100 text-green-800';
      case 'intermediate': return 'bg-yellow-100 text-yellow-800';
      case 'advanced': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Formations disponibles</h2>
        </div>
        <div className="p-6">
          <div className="mb-6">
            <div className="flex flex-wrap gap-2">
              {categories.map(category => (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`px-4 py-2 rounded-full text-sm font-medium ${
                    activeCategory === category.id
                      ? 'bg-indigo-100 text-indigo-800'
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
          
          {filteredFormations.length === 0 ? (
            <p className="text-gray-500 text-center py-8">
              Aucune formation disponible dans cette catégorie pour le moment.
            </p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredFormations.map(formation => (
                <div key={formation.id} className="border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                  <div 
                    className="h-40 bg-cover bg-center" 
                    style={{ backgroundImage: `url(${formation.imageUrl})` }}
                  ></div>
                  <div className="p-4">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="font-medium text-lg">{formation.title}</h3>
                      <span className={`ml-2 px-2 py-1 rounded-full text-xs ${getLevelColor(formation.level)}`}>
                        {getLevelLabel(formation.level)}
                      </span>
                    </div>
                    <p className="text-gray-600 text-sm mb-4 line-clamp-2">{formation.description}</p>
                    <div className="flex items-center text-sm text-gray-500 mb-4">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {formation.durationHours} heures
                    </div>
                    <div className="flex justify-between items-center">
                      <span className="font-bold text-indigo-600">{formation.price} €</span>
                      <button 
                        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition"
                        onClick={() => handlePurchase(formation.id)}
                      >
                        Acheter
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Mes formations</h2>
        </div>
        <div className="p-6">
          {purchasedFormations.length === 0 ? (
            <p className="text-gray-500 italic text-center py-8">
              Vous n'avez pas encore acheté de formation.
            </p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {purchasedFormations.map(formation => (
                <div key={formation.id} className="border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                  <div 
                    className="h-40 bg-cover bg-center" 
                    style={{ backgroundImage: `url(${formation.imageUrl})` }}
                  ></div>
                  <div className="p-4">
                    <h3 className="font-medium text-lg mb-2">{formation.title}</h3>
                    <p className="text-sm text-gray-500 mb-3">
                      Acheté le: {formation.purchaseDate}
                    </p>
                    <div className="mb-4">
                      <div className="flex justify-between text-sm mb-1">
                        <span>Progression</span>
                        <span>{formation.progress}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                          className="bg-indigo-600 h-2.5 rounded-full" 
                          style={{ width: `${formation.progress}%` }}
                        ></div>
                      </div>
                    </div>
                    <button className="w-full px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition">
                      Continuer la formation
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormationsHub; 