import React from "react";

const Privacy: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Politique de Confidentialité</h1>
      
      <div className="prose prose-lg">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Introduction</h2>
          <p className="text-gray-600 mb-4">
            Cette politique de confidentialité décrit comment nous collectons et 
            protégeons vos informations lorsque vous utilisez notre service d'envoi d'emails.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Informations Collectées</h2>
          <p className="text-gray-600 mb-4">
            Nous collectons les informations suivantes :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Informations de compte (email, nom d'utilisateur)</li>
            <li>Configuration des serveurs SMTP</li>
            <li>Statistiques d'envoi d'emails</li>
            <li>Templates d'emails</li>
            <li>Logs de connexion et d'utilisation</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Utilisation des Données</h2>
          <p className="text-gray-600 mb-4">
            Nous utilisons ces informations pour :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Fournir et maintenir le service</li>
            <li>Améliorer la sécurité</li>
            <li>Générer des statistiques d'utilisation</li>
            <li>Communiquer avec vous</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Protection des Données</h2>
          <p className="text-gray-600 mb-4">
            Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Chiffrement des données sensibles</li>
            <li>Accès restreint aux données</li>
            <li>Surveillance continue de la sécurité</li>
            <li>Sauvegardes régulières</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Vos Droits</h2>
          <p className="text-gray-600 mb-4">
            Vous disposez des droits suivants concernant vos données :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Droit d'accès à vos données</li>
            <li>Droit de rectification</li>
            <li>Droit à l'effacement</li>
            <li>Droit à la portabilité</li>
            <li>Droit d'opposition au traitement</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Cookies</h2>
          <p className="text-gray-600 mb-4">
            Nous utilisons des cookies pour améliorer votre expérience. Vous pouvez 
            configurer votre navigateur pour refuser les cookies, mais certaines 
            fonctionnalités pourraient ne pas fonctionner correctement.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Modifications</h2>
          <p className="text-gray-600 mb-4">
            Nous nous réservons le droit de modifier cette politique à tout moment. 
            Les modifications entrent en vigueur dès leur publication.
          </p>
        </section>

        <section>
          <p className="text-sm text-gray-500">
            Dernière mise à jour : {new Date().toLocaleDateString("fr-FR")}
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy; 