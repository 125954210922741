import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import type { DocumentData } from 'firebase/firestore';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { motion } from 'framer-motion';
import {
  EnvelopeIcon,
  ServerIcon,
  DocumentDuplicateIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  ArrowPathIcon,
  AcademicCapIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline';
import SenderMail from '../components/client/SenderMail';
import FormationsHub from '../components/client/FormationsHub';
import EmailQualityChecker from '../components/client/EmailQualityChecker';

interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  lastUsed: string;
  status: 'active' | 'draft';
}

interface SMTPConfig {
  id: string;
  name: string;
  host: string;
  status: 'connected' | 'error' | 'pending';
  lastCheck: string;
}

interface DashboardStats {
  projects: number;
  tasks: number;
  completed: number;
  inProgress: number;
}

interface EmailStats {
  emailsSent: number;
  deliveryRate: number;
  openRate: number;
  clickRate: number;
}

const Dashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userData, setUserData] = useState<DocumentData | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stats, setStats] = useState<DashboardStats>({
    projects: 0,
    tasks: 0,
    completed: 0,
    inProgress: 0
  });
  
  // Lire le paramètre tab de l'URL
  const queryParams = new URLSearchParams(window.location.search);
  const tabParam = queryParams.get('tab');
  
  const [activeTab, setActiveTab] = useState<
    'overview' | 
    'sender' | 
    'templates' | 
    'smtp' | 
    'formations' | 
    'email-quality' | 
    'profile' | 
    'billing' |
    'coming-soon-1' |
    'coming-soon-2'
  >(tabParam as any || 'overview');

  const templates: EmailTemplate[] = [
    {
      id: '1',
      name: 'Newsletter mensuelle',
      subject: 'Découvrez nos dernières nouveautés',
      lastUsed: '2024-03-10',
      status: 'active'
    },
    {
      id: '2',
      name: 'Confirmation de commande',
      subject: 'Merci pour votre commande !',
      lastUsed: '2024-03-15',
      status: 'active'
    },
    {
      id: '3',
      name: 'Rappel de rendez-vous',
      subject: 'Votre rendez-vous approche',
      lastUsed: '2024-03-12',
      status: 'draft'
    }
  ];

  const smtpConfigs: SMTPConfig[] = [
    {
      id: '1',
      name: 'Gmail Principal',
      host: 'smtp.gmail.com',
      status: 'connected',
      lastCheck: '2024-03-15 14:30'
    },
    {
      id: '2',
      name: 'Serveur SMTP Entreprise',
      host: 'smtp.enterprise.com',
      status: 'error',
      lastCheck: '2024-03-15 12:15'
    }
  ];

  const emailStats: EmailStats = {
    emailsSent: 12500,
    deliveryRate: 98.5,
    openRate: 45.2,
    clickRate: 15.8
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) return;
      
      try {
        // Simulation de données pour démonstration
        // Normalement, vous récupéreriez les données de l'utilisateur depuis Firestore
        setUserData({
          name: currentUser.displayName || currentUser.email?.split('@')[0] || 'Utilisateur',
          email: currentUser.email,
          role: 'client',
          lastLogin: new Date().toLocaleDateString('fr-FR')
        });

        setStats({
          projects: 3,
          tasks: 12,
          completed: 5,
          inProgress: 7
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données utilisateur:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const renderOverview = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center">
          <EnvelopeIcon className="h-10 w-10 text-indigo-500" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-900">Emails envoyés</h3>
            <p className="text-2xl font-bold text-indigo-600">{emailStats.emailsSent.toLocaleString()}</p>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center">
          <ChartBarIcon className="h-10 w-10 text-green-500" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-900">Taux de livraison</h3>
            <p className="text-2xl font-bold text-green-600">{emailStats.deliveryRate}%</p>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center">
          <AcademicCapIcon className="h-10 w-10 text-amber-500" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-900">Formations</h3>
            <p className="text-2xl font-bold text-amber-600">3</p>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center">
          <CreditCardIcon className="h-10 w-10 text-purple-500" />
          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-900">Solde compte</h3>
            <p className="text-2xl font-bold text-purple-600">250 €</p>
          </div>
        </div>
      </div>
    </div>
  );

  const renderTemplates = () => (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Templates d'email</h2>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition">
            Nouveau template
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        {templates.map(template => (
          <div key={template.id} className="p-6 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">{template.name}</h3>
              <p className="text-sm text-gray-500">Sujet: {template.subject}</p>
              <p className="text-xs text-gray-400">Dernière utilisation: {template.lastUsed}</p>
            </div>
            <div className="flex items-center space-x-4">
              <span className={`px-3 py-1 rounded-full text-sm ${
                template.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
              }`}>
                {template.status === 'active' ? 'Actif' : 'Brouillon'}
              </span>
              <button className="text-gray-400 hover:text-gray-500">
                <Cog6ToothIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSMTP = () => (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Configuration SMTP</h2>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition">
            Ajouter un serveur
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-200">
        {smtpConfigs.map(config => (
          <div key={config.id} className="p-6 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">{config.name}</h3>
              <p className="text-sm text-gray-500">Host: {config.host}</p>
              <p className="text-xs text-gray-400">Dernière vérification: {config.lastCheck}</p>
            </div>
            <div className="flex items-center space-x-4">
              <span className={`px-3 py-1 rounded-full text-sm ${
                config.status === 'connected' ? 'bg-green-100 text-green-800' :
                config.status === 'error' ? 'bg-red-100 text-red-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {config.status === 'connected' ? 'Connecté' :
                 config.status === 'error' ? 'Erreur' : 'En attente'}
              </span>
              <button className="text-gray-400 hover:text-gray-500">
                <ArrowPathIcon className="h-5 w-5" />
              </button>
              <button className="text-gray-400 hover:text-gray-500">
                <Cog6ToothIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSender = () => (
    <SenderMail />
  );

  const renderFormations = () => (
    <FormationsHub />
  );

  const renderEmailQuality = () => (
    <EmailQualityChecker />
  );

  const renderProfile = () => (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-900">Mon profil</h2>
      </div>
      <div className="p-6">
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Informations personnelles</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Nom</label>
              <input type="text" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" defaultValue={currentUser?.displayName || ''} />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
              <input type="email" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" defaultValue={currentUser?.email || ''} readOnly />
            </div>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sécurité</h3>
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Changer le mot de passe</label>
              <input type="password" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Nouveau mot de passe" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Confirmer le mot de passe</label>
              <input type="password" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Confirmer le nouveau mot de passe" />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition">
            Sauvegarder les modifications
          </button>
        </div>
      </div>
    </div>
  );

  const renderBilling = () => (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Facturation et paiement</h2>
          <span className="px-4 py-2 bg-green-100 text-green-800 rounded-full text-sm">
            Solde: 250 €
          </span>
        </div>
      </div>
      <div className="p-6">
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Recharger mon compte</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="border rounded-lg p-6 text-center hover:border-indigo-500 cursor-pointer">
              <h4 className="font-medium text-xl mb-2">50 €</h4>
              <p className="text-gray-500 text-sm">Forfait de base</p>
            </div>
            <div className="border rounded-lg p-6 text-center hover:border-indigo-500 cursor-pointer bg-indigo-50">
              <h4 className="font-medium text-xl mb-2">100 €</h4>
              <p className="text-gray-500 text-sm">Forfait standard</p>
              <p className="text-xs text-indigo-600 mt-2">Populaire</p>
            </div>
            <div className="border rounded-lg p-6 text-center hover:border-indigo-500 cursor-pointer">
              <h4 className="font-medium text-xl mb-2">200 €</h4>
              <p className="text-gray-500 text-sm">Forfait premium</p>
            </div>
          </div>
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Montant personnalisé</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">€</span>
              </div>
              <input type="text" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition">
            Procéder au paiement
          </button>
        </div>
      </div>
    </div>
  );

  const renderComingSoon = (featureNumber: number) => (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-900">Fonctionnalité à venir</h2>
      </div>
      <div className="p-6 text-center py-24">
        <h3 className="text-2xl font-bold text-gray-700 mb-4">Fonctionnalité en développement</h3>
        <p className="text-gray-500 mb-6">Cette fonctionnalité sera bientôt disponible. Restez à l'écoute !</p>
        <div className="inline-block p-2 rounded-full bg-yellow-100">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">
          Tableau de bord
        </h1>
        <div className="flex items-center space-x-4">
          <span className="text-gray-500">Bienvenue, {currentUser?.displayName || currentUser?.email}</span>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition">
            Nouvel envoi
          </button>
        </div>
      </div>

      <div className="p-6">
        <div className="mb-6">
          <div className="border-b border-gray-200 overflow-x-auto">
            <nav className="-mb-px flex">
              <button
                onClick={() => setActiveTab('overview')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'overview'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Vue d'ensemble
              </button>
              <button
                onClick={() => setActiveTab('sender')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'sender'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Sender Mail
              </button>
              <button
                onClick={() => setActiveTab('templates')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'templates'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Templates
              </button>
              <button
                onClick={() => setActiveTab('smtp')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'smtp'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                SMTP
              </button>
              <button
                onClick={() => setActiveTab('formations')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'formations'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Formations
              </button>
              <button
                onClick={() => setActiveTab('email-quality')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'email-quality'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Qualité Email
              </button>
              <button
                onClick={() => setActiveTab('coming-soon-1')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'coming-soon-1'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Bientôt 1
              </button>
              <button
                onClick={() => setActiveTab('coming-soon-2')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'coming-soon-2'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Bientôt 2
              </button>
              <button
                onClick={() => setActiveTab('profile')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'profile'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } mr-6`}
              >
                Profil
              </button>
              <button
                onClick={() => setActiveTab('billing')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'billing'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Facturation
              </button>
            </nav>
          </div>
        </div>

        <div className="mt-6">
          {activeTab === 'overview' && renderOverview()}
          {activeTab === 'templates' && renderTemplates()}
          {activeTab === 'smtp' && renderSMTP()}
          {activeTab === 'sender' && renderSender()}
          {activeTab === 'formations' && renderFormations()}
          {activeTab === 'email-quality' && renderEmailQuality()}
          {activeTab === 'profile' && renderProfile()}
          {activeTab === 'billing' && renderBilling()}
          {activeTab === 'coming-soon-1' && renderComingSoon(1)}
          {activeTab === 'coming-soon-2' && renderComingSoon(2)}
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 