import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, limit, where, Timestamp } from 'firebase/firestore';
import { getFirestore } from '../../firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Types
interface ActivityLog {
  id: string;
  type: string;
  message: string;
  timestamp: Timestamp;
  data?: any;
  user?: string;
}

// Filtres disponibles
type FilterPeriod = 'all' | 'today' | 'week' | 'month';
type FilterType = 'all' | 'user' | 'role' | 'settings' | 'ticket' | 'payment';

const AdminActivity: React.FC = () => {
  const [activities, setActivities] = useState<ActivityLog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterPeriod, setFilterPeriod] = useState<FilterPeriod>('all');
  const [filterType, setFilterType] = useState<FilterType>('all');
  const [showFilters, setShowFilters] = useState<boolean>(false);

  // Chargement des données
  useEffect(() => {
    fetchActivities();
  }, [filterPeriod, filterType]);

  const fetchActivities = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const db = await getFirestore();
      let activitiesQuery = query(
        collection(db, "activity_logs"),
        orderBy("timestamp", "desc"),
        limit(100)
      );
      
      // Appliquer des filtres de période si nécessaire
      if (filterPeriod !== 'all') {
        const dateFilter = getDateFilterForPeriod(filterPeriod);
        if (dateFilter) {
          activitiesQuery = query(
            collection(db, "activity_logs"),
            orderBy("timestamp", "desc"),
            where("timestamp", ">=", dateFilter),
            limit(100)
          );
        }
      }
      
      const querySnapshot = await getDocs(activitiesQuery);
      const activitiesData: ActivityLog[] = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        // S'assurer que toutes les propriétés nécessaires existent
        if (data && data.type) {
          const activity: ActivityLog = {
            id: doc.id,
            type: data.type,
            message: data.message || "Action sans description",
            timestamp: data.timestamp,
            data: data.data || {},
            user: data.data?.user || data.data?.email || data.data?.updatedBy || "Système"
          };
          
          // Filtrer par type si nécessaire
          if (filterType === 'all' || activity.type.includes(filterType)) {
            activitiesData.push(activity);
          }
        }
      });
      
      setActivities(activitiesData);
    } catch (err) {
      console.error("Erreur lors de la récupération des logs d'activité:", err);
      setError("Impossible de charger les logs d'activité. Veuillez réessayer plus tard.");
    } finally {
      setIsLoading(false);
    }
  };

  // Obtenir la date pour filtrer par période
  const getDateFilterForPeriod = (period: FilterPeriod): Date | null => {
    const now = new Date();
    
    switch (period) {
      case 'today':
        return new Date(now.setHours(0, 0, 0, 0));
      case 'week':
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        return lastWeek;
      case 'month':
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        return lastMonth;
      default:
        return null;
    }
  };

  // Formatage de la date
  const formatDate = (timestamp: Timestamp): string => {
    if (!timestamp || !timestamp.toDate) {
      return "Date inconnue";
    }
    
    const date = timestamp.toDate();
    return format(date, "dd/MM/yyyy HH:mm:ss", { locale: fr });
  };

  // Recherche
  const filteredActivities = activities.filter(activity => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      activity.message.toLowerCase().includes(searchLower) ||
      activity.type.toLowerCase().includes(searchLower) ||
      (activity.user && activity.user.toLowerCase().includes(searchLower))
    );
  });

  // Export des logs (version basique pour le moment)
  const exportLogs = () => {
    const csvContent = [
      ["ID", "Type", "Message", "Utilisateur", "Date et heure"].join(","),
      ...filteredActivities.map(a => 
        [
          a.id, 
          a.type, 
          `"${a.message.replace(/"/g, '""')}"`, 
          a.user || "Système", 
          formatDate(a.timestamp)
        ].join(",")
      )
    ].join("\n");
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `activity_logs_${new Date().toISOString().slice(0,10)}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Journal d'Activité</h1>
      <p className="mb-4">Cette page affiche les activités récentes sur votre application.</p>
      
      <div className="mb-6 flex flex-col sm:flex-row justify-between gap-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Rechercher une activité..."
            className="pl-10 pr-4 py-2 border rounded-lg w-full sm:w-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
        
        <div className="flex space-x-2">
          <button 
            className="px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            onClick={() => setShowFilters(!showFilters)}
          >
            Filtrer
          </button>
          <button 
            className="px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            onClick={exportLogs}
          >
            Exporter
          </button>
        </div>
      </div>
      
      {showFilters && (
        <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <h3 className="text-lg font-medium mb-3">Filtres</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Période</label>
              <select 
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                value={filterPeriod}
                onChange={(e) => setFilterPeriod(e.target.value as FilterPeriod)}
              >
                <option value="all">Toutes les périodes</option>
                <option value="today">Aujourd'hui</option>
                <option value="week">7 derniers jours</option>
                <option value="month">30 derniers jours</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Type d'activité</label>
              <select 
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                value={filterType}
                onChange={(e) => setFilterType(e.target.value as FilterType)}
              >
                <option value="all">Tous les types</option>
                <option value="user">Utilisateurs</option>
                <option value="role">Rôles</option>
                <option value="settings">Paramètres</option>
                <option value="ticket">Tickets</option>
                <option value="payment">Paiements</option>
              </select>
            </div>
          </div>
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      ) : filteredActivities.length === 0 ? (
        <div className="bg-white rounded-lg shadow p-6 text-center">
          <p className="text-gray-500">Aucune activité trouvée.</p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Utilisateur</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date et heure</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredActivities.map((activity) => (
                  <tr key={activity.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${activity.type.includes('user') ? 'bg-blue-100 text-blue-800' : 
                        activity.type.includes('role') ? 'bg-purple-100 text-purple-800' : 
                        activity.type.includes('ticket') ? 'bg-yellow-100 text-yellow-800' :
                        activity.type.includes('payment') ? 'bg-green-100 text-green-800' :
                        'bg-gray-100 text-gray-800'}`}>
                        {activity.type}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{activity.message}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{activity.user}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(activity.timestamp)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
      {!isLoading && filteredActivities.length > 0 && (
        <div className="mt-4 text-sm text-gray-500 text-right">
          Affichage de {filteredActivities.length} activité(s)
        </div>
      )}
    </div>
  );
};

export default AdminActivity; 