import React from 'react';
import { motion } from 'framer-motion';

const About: React.FC = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          À Propos de Notre Service
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Nous révolutionnons l'envoi d'emails en masse en permettant aux entreprises 
          d'utiliser leurs propres serveurs SMTP avec des outils puissants et intuitifs.
        </p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
        <motion.div 
          {...fadeIn} 
          transition={{ delay: 0.2 }}
          className="space-y-6"
        >
          <h2 className="text-3xl font-semibold text-gray-900">Notre Mission</h2>
          <p className="text-gray-600">
            Notre mission est de démocratiser l'envoi d'emails professionnels en fournissant 
            une plateforme qui allie puissance et simplicité. Nous croyons que chaque entreprise 
            devrait avoir accès à des outils d'emailing performants tout en gardant le contrôle 
            sur leur infrastructure.
          </p>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Simplifier l'envoi d'emails en masse</li>
            <li>Garantir une délivrabilité optimale</li>
            <li>Offrir un contrôle total sur les serveurs SMTP</li>
            <li>Fournir des analytics détaillés</li>
          </ul>
        </motion.div>

        <motion.div 
          {...fadeIn} 
          transition={{ delay: 0.4 }}
          className="space-y-6"
        >
          <h2 className="text-3xl font-semibold text-gray-900">Notre Histoire</h2>
          <p className="text-gray-600">
            Fondée en 2024, notre entreprise est née d'un constat simple : les solutions 
            d'emailing existantes ne répondaient pas aux besoins des entreprises souhaitant 
            utiliser leur propre infrastructure SMTP. Nous avons donc créé une plateforme 
            qui combine le meilleur des deux mondes : la flexibilité des serveurs SMTP 
            personnalisés et la puissance d'une interface moderne.
          </p>
        </motion.div>
      </div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.6 }}
        className="bg-gray-50 rounded-2xl p-8 mb-16"
      >
        <h2 className="text-3xl font-semibold text-gray-900 mb-8 text-center">
          Nos Valeurs
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="bg-indigo-100 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
              <svg className="w-8 h-8 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Performance</h3>
            <p className="text-gray-600">
              Nous optimisons chaque aspect de notre plateforme pour garantir des performances 
              exceptionnelles.
            </p>
          </div>
          
          <div className="text-center">
            <div className="bg-green-100 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
              <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Sécurité</h3>
            <p className="text-gray-600">
              La sécurité de vos données et de vos serveurs est notre priorité absolue.
            </p>
          </div>
          
          <div className="text-center">
            <div className="bg-blue-100 rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
              <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Transparence</h3>
            <p className="text-gray-600">
              Nous croyons en une communication ouverte et honnête avec nos clients.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.8 }}
        className="text-center"
      >
        <h2 className="text-3xl font-semibold text-gray-900 mb-8">Notre Équipe</h2>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
          Une équipe passionnée d'experts en email marketing, développement et 
          infrastructure cloud, dédiée à votre réussite.
        </p>
        <a 
          href="/careers" 
          className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Rejoignez-nous
        </a>
      </motion.div>
    </div>
  );
};

export default About; 