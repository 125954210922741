import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import About from './pages/About';
import Blog from './pages/Blog';
import Careers from './pages/Careers';
import Press from './pages/Press';
import Help from './pages/Help';
import Contact from './pages/Contact';
import Status from './pages/Status';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import Support from './pages/Support';
import ResetPassword from './pages/ResetPassword';
import EmailVerification from './pages/EmailVerification';
import NotFound from './pages/NotFound';
import Documentation from './pages/Documentation';
import API from './pages/API';
import FAQ from './pages/FAQ';
import AdminLayout from "./pages/admin/AdminLayout";
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminTickets from './pages/admin/AdminTickets';
import AdminUsers from './pages/admin/AdminUsers';
import AdminSettings from './pages/admin/AdminSettings';
import AdminRoles from './pages/admin/AdminRoles';
import AdminAnalytics from './pages/admin/AdminAnalytics';
import AdminSmtp from './pages/admin/AdminSmtp';
import AdminActivity from './pages/admin/AdminActivity';
import TicketDetailsPage from './pages/TicketDetailsPage';
import CreateTicketPage from './pages/CreateTicketPage';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import Profile from './pages/Profile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { UserSynchronizer } from './components/UserSynchronizer';

interface PrivateRouteProps {
  element: React.ReactNode;
}

const App: React.FC = () => {
  const [firebaseError, setFirebaseError] = useState<string | null>(null);

  // Écouteur d'erreurs pour capturer les problèmes d'initialisation de Firebase
  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      if (event.error && event.error.toString().includes('Firebase')) {
        console.error('Firebase error caught:', event.error);
        setFirebaseError('Erreur de connexion à Firebase. Veuillez vérifier votre connexion internet ou réessayer plus tard.');
      }
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  // Si une erreur Firebase est détectée, afficher un message d'erreur convivial
  if (firebaseError) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Erreur de connexion</h2>
          <p className="text-gray-700 mb-4">{firebaseError}</p>
          <p className="text-gray-600 mb-6 text-sm">
            Si le problème persiste, veuillez contacter le support technique.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition"
          >
            Rafraîchir la page
          </button>
        </div>
      </div>
    );
  }

  return (
    <AuthProvider>
      <NotificationProvider>
        <UserSynchronizer />
        <Router>
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Navbar />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={
                  <PrivateRoute element={<Dashboard />} />
                } />
                {/* Nouvelles pages */}
                <Route path="/profile" element={
                  <PrivateRoute element={<Profile />} />
                } />
                <Route path="/documentation" element={<Documentation />} />
                <Route path="/api" element={<API />} />
                <Route path="/faq" element={<FAQ />} />
                
                {/* Pages d'administration avec lazy loading */}
                <Route path="/admin" element={<AdminRoute element={
                  <Fragment>
                    <AdminLayout />
                  </Fragment>
                } />}>
                  <Route index element={<AdminDashboard />} />
                  <Route path="tickets" element={<AdminTickets />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="settings" element={<AdminSettings />} />
                  <Route path="roles" element={<AdminRoles />} />
                  <Route path="analytics" element={<AdminAnalytics />} />
                  <Route path="smtp" element={<AdminSmtp />} />
                  <Route path="activity" element={<AdminActivity />} />
                </Route>
                
                {/* Pages du footer - Entreprise */}
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/press" element={<Press />} />
                {/* Pages du footer - Support */}
                <Route path="/help" element={<Help />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/status" element={<Status />} />
                
                {/* Pages de support */}
                <Route path="/support" element={
                  <PrivateRoute element={<Support />} />
                } />
                <Route path="/tickets/:id" element={
                  <PrivateRoute element={<TicketDetailsPage />} />
                } />
                <Route path="/submit-ticket" element={
                  <PrivateRoute element={<CreateTicketPage />} />
                } />
                
                {/* Pages du footer - Légal */}
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/cookies" element={<Cookies />} />
                
                {/* Autres pages */}
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/verify-email" element={<EmailVerification />} />
                
                {/* Page 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
            <ToastContainer 
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </Router>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;
