import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import useTickets from "../../hooks/useTickets";
import { usePermissions } from "../../hooks/usePermissions";
import { useAuth } from "../../contexts/AuthContext";
import { TICKET_PRIORITY_LABELS, TICKET_CATEGORY_LABELS, TicketPriority, TicketCategory } from "../../types/tickets";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";

interface CreateTicketProps {
  onClose: () => void;
  onTicketCreated: () => void;
}

const CreateTicket: React.FC<CreateTicketProps> = ({ onClose, onTicketCreated }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState<TicketPriority>("low");
  const [category, setCategory] = useState<TicketCategory>("technical");
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [debugInfo, setDebugInfo] = useState<any>(null);
  const { createTicket } = useTickets();
  const permissions = usePermissions();
  const { currentUser } = useAuth();

  // Assurer que l'utilisateur a un rôle lors du chargement du composant
  useEffect(() => {
    if (currentUser && !currentUser.role) {
      ensureUserHasRole();
    }
  }, [currentUser]);

  // Fonction pour attribuer le rôle "client" à l'utilisateur s'il n'en a pas déjà un
  const ensureUserHasRole = async () => {
    if (!currentUser || currentUser.role) return;
    
    try {
      // Vérifier si le document utilisateur existe déjà
      const userRef = doc(db, "users", currentUser.uid);
      
      // Mettre à jour ou créer le document utilisateur
      await setDoc(userRef, { 
        role: "client",
        email: currentUser.email,
        displayName: currentUser.displayName || '',
        updatedAt: new Date()
      }, { merge: true });
      
      console.log("Rôle 'client' attribué à l'utilisateur:", currentUser.uid);
      // Recharger la page pour actualiser le contexte d'authentification
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de l'attribution du rôle client:", error);
      setError("Erreur lors de l'attribution du rôle. Veuillez réessayer ou contacter l'administrateur.");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || !description.trim()) return;

    setIsSubmitting(true);
    setError(null);

    try {
      if (!currentUser) {
        throw new Error("Veuillez vous connecter pour créer un ticket");
      }

      // Si l'utilisateur n'a pas de rôle, lui attribuer le rôle "client"
      if (!currentUser.role) {
        try {
          await ensureUserHasRole();
          toast.info("Le rôle 'client' vous a été attribué. La page va se recharger.");
          return; // Arrêter l'exécution ici, le rechargement de la page va relancer le flux
        } catch (roleError) {
          console.error("Erreur lors de l'attribution du rôle client:", roleError);
          setError("Erreur lors de l'attribution du rôle. Veuillez réessayer ou contacter l'administrateur.");
          setIsSubmitting(false);
          return;
        }
      }

      // Créer le ticket sans vérifier les permissions
      try {
        await createTicket({
          title,
          description,
          priority,
          category,
          userId: currentUser.uid,
          userEmail: currentUser.email || '',
          status: "open"
        }, true); // Passer true pour ignorer la vérification des permissions

        toast.success("Ticket créé avec succès !");
        onClose();
        onTicketCreated();
      } catch (ticketError) {
        console.error("Erreur lors de la création du ticket:", ticketError);
        
        // Messages de debug pour l'administrateur
        console.log("ATTENTION ADMINISTRATEUR: Veuillez mettre à jour manuellement le rôle de l'utilisateur dans Firebase:");
        console.log(`ID utilisateur: ${currentUser.uid}`);
        console.log(`Email: ${currentUser.email}`);
        
        if (ticketError instanceof Error && ticketError.message.includes("Permissions")) {
          setError("Vous n'avez pas les permissions nécessaires pour créer un ticket. L'administrateur a été notifié de ce problème.");
        } else {
          setError("Une erreur est survenue lors de la création du ticket. Veuillez réessayer ou contacter l'administrateur.");
        }
      }
    } catch (err) {
      console.error("Erreur lors de la création du ticket:", err);
      setError(err instanceof Error ? err.message : "Permissions insuffisantes pour créer un ticket");
    } finally {
      setIsSubmitting(false);
    }
  };

  const showDebugInfo = () => {
    setDebugInfo(permissions.debugPermissions());
  };

  const fixPermissions = async () => {
    if (!currentUser) return;
    
    try {
      setIsSubmitting(true);
      await ensureUserHasRole();
      toast.info("Tentative de correction des permissions. La page va se recharger.");
    } catch (error) {
      console.error("Erreur lors de la correction des permissions:", error);
      setError("Erreur lors de la correction des permissions. Veuillez contacter l'administrateur.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div className="relative top-20 mx-auto p-5 border w-full max-w-2xl shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900">Nouveau ticket</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {error}
            <div className="flex mt-2 space-x-2">
              <button 
                onClick={showDebugInfo}
                className="px-2 py-1 text-xs text-blue-700 underline"
              >
                Diagnostiquer
              </button>
              <button 
                onClick={fixPermissions}
                className="px-2 py-1 text-xs bg-blue-100 text-blue-700 rounded hover:bg-blue-200"
              >
                Essayer de corriger les permissions
              </button>
            </div>
          </div>
        )}

        {debugInfo && (
          <div className="mb-4 p-3 bg-gray-100 border border-gray-400 text-gray-700 rounded text-xs overflow-auto max-h-40">
            <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
            <button 
              onClick={() => setDebugInfo(null)}
              className="mt-2 text-xs underline"
            >
              Fermer
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Titre
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div>
            <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
              Priorité
            </label>
            <select
              id="priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value as TicketPriority)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              {Object.entries(TICKET_PRIORITY_LABELS).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700">
              Catégorie
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value as TicketCategory)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              {Object.entries(TICKET_CATEGORY_LABELS).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                isSubmitting ? "bg-indigo-400" : "bg-indigo-600 hover:bg-indigo-700"
              }`}
            >
              {isSubmitting ? "Création..." : "Créer le ticket"}
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default CreateTicket; 