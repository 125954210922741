import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateTicket from "../components/support/CreateTicket";

const CreateTicketPage: React.FC = () => {
  const navigate = useNavigate();
  const [ticketCreated, setTicketCreated] = useState(false);

  const handleClose = () => {
    navigate("/support");
  };

  const handleTicketCreated = () => {
    setTicketCreated(true);
    
    // Rediriger vers la liste des tickets après 2 secondes
    setTimeout(() => {
      navigate("/support");
    }, 2000);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {ticketCreated ? (
        <div className="bg-green-50 border border-green-200 rounded-lg p-6 text-center">
          <h2 className="text-xl font-medium text-green-600 mb-2">
            Ticket créé avec succès!
          </h2>
          <p className="text-green-500 mb-4">
            Votre ticket a été créé et l'équipe support a été notifiée. Vous allez être redirigé vers la liste des tickets.
          </p>
          <div className="inline-block">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500 mx-auto"></div>
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Créer un nouveau ticket</h1>
            <p className="text-gray-600 mt-1">
              Remplissez le formulaire ci-dessous pour soumettre une demande d'assistance.
            </p>
          </div>
          <CreateTicket onClose={handleClose} onTicketCreated={handleTicketCreated} />
        </div>
      )}
    </div>
  );
};

export default CreateTicketPage; 