import React from 'react';

const AdminAnalytics: React.FC = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Statistiques et Analytiques</h1>
      <p className="mb-4">Cette page affiche les statistiques de votre application.</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-2">Utilisateurs</h2>
          <div className="flex justify-between">
            <div>
              <p className="text-gray-500">Total</p>
              <p className="text-2xl font-bold">245</p>
            </div>
            <div>
              <p className="text-gray-500">Actifs ce mois</p>
              <p className="text-2xl font-bold">182</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-2">Sessions</h2>
          <div className="flex justify-between">
            <div>
              <p className="text-gray-500">Total</p>
              <p className="text-2xl font-bold">1,245</p>
            </div>
            <div>
              <p className="text-gray-500">Durée moyenne</p>
              <p className="text-2xl font-bold">5m 27s</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Graphique des utilisateurs (statique)</h2>
        <div className="h-64 w-full bg-gray-100 flex items-center justify-center">
          <p className="text-gray-500">Graphique statique des utilisateurs</p>
        </div>
      </div>
      
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Activité récente (statique)</h2>
        <div className="h-64 w-full bg-gray-100 flex items-center justify-center">
          <p className="text-gray-500">Graphique statique de l'activité récente</p>
        </div>
      </div>
    </div>
  );
};

export default AdminAnalytics; 