import React, { useState } from 'react';
import { motion } from 'framer-motion';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  author: string;
  date: string;
  category: string;
  readTime: string;
  imageUrl: string;
}

const Blog: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');

  const categories = [
    { id: 'all', name: 'Tous les articles' },
    { id: 'email-marketing', name: 'Email Marketing' },
    { id: 'smtp', name: 'Configuration SMTP' },
    { id: 'best-practices', name: 'Bonnes Pratiques' },
    { id: 'tutorials', name: 'Tutoriels' }
  ];

  const blogPosts: BlogPost[] = [
    {
      id: '1',
      title: 'Comment optimiser la délivrabilité de vos emails',
      excerpt: 'Découvrez les meilleures pratiques pour améliorer le taux de délivrabilité de vos campagnes email et éviter les dossiers spam.',
      author: 'Sophie Martin',
      date: '15 Mars 2024',
      category: 'email-marketing',
      readTime: '5 min',
      imageUrl: '/images/blog/deliverability.jpg'
    },
    {
      id: '2',
      title: 'Guide complet de la configuration SMTP',
      excerpt: 'Un guide étape par étape pour configurer correctement vos serveurs SMTP et maximiser les performances de vos envois.',
      author: 'Thomas Dubois',
      date: '12 Mars 2024',
      category: 'smtp',
      readTime: '8 min',
      imageUrl: '/images/blog/smtp-setup.jpg'
    },
    {
      id: '3',
      title: 'Les tendances de l\'email marketing en 2024',
      excerpt: 'Analyse des dernières tendances et innovations dans le domaine de l\'email marketing pour cette année.',
      author: 'Marie Lambert',
      date: '10 Mars 2024',
      category: 'email-marketing',
      readTime: '6 min',
      imageUrl: '/images/blog/trends.jpg'
    }
  ];

  const filteredPosts = selectedCategory === 'all' 
    ? blogPosts 
    : blogPosts.filter(post => post.category === selectedCategory);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <motion.div {...fadeIn} className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Blog & Ressources
        </h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Découvrez nos articles, guides et tutoriels pour maîtriser l'email marketing 
          et optimiser vos campagnes.
        </p>
      </motion.div>

      <div className="mb-12">
        <div className="flex flex-wrap gap-4 justify-center">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-colors
                ${selectedCategory === category.id
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredPosts.map((post, index) => (
          <motion.article
            key={post.id}
            {...fadeIn}
            transition={{ delay: index * 0.1 }}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <div className="h-48 bg-gray-200">
              {/* Image placeholder - Dans un vrai projet, utilisez next/image ou une autre solution d'optimisation */}
              <div className="w-full h-full bg-gray-300"></div>
            </div>
            
            <div className="p-6">
              <div className="flex items-center text-sm text-gray-500 mb-4">
                <span>{post.date}</span>
                <span className="mx-2">•</span>
                <span>{post.readTime} de lecture</span>
              </div>
              
              <h2 className="text-xl font-semibold text-gray-900 mb-3">
                {post.title}
              </h2>
              
              <p className="text-gray-600 mb-4">
                {post.excerpt}
              </p>
              
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-gray-300"></div>
                  <span className="ml-2 text-sm text-gray-600">{post.author}</span>
                </div>
                
                <a
                  href={`/blog/${post.id}`}
                  className="text-indigo-600 hover:text-indigo-800 text-sm font-medium"
                >
                  Lire la suite →
                </a>
              </div>
            </div>
          </motion.article>
        ))}
      </div>

      <div className="mt-12 text-center">
        <button className="px-8 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition">
          Charger plus d'articles
        </button>
      </div>

      <motion.div 
        {...fadeIn} 
        transition={{ delay: 0.4 }}
        className="mt-16 bg-gray-50 rounded-2xl p-8 text-center"
      >
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Abonnez-vous à notre newsletter
        </h2>
        <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
          Recevez nos derniers articles et conseils directement dans votre boîte mail.
        </p>
        <form className="max-w-md mx-auto flex gap-4">
          <input
            type="email"
            placeholder="Votre adresse email"
            className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
          <button
            type="submit"
            className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
          >
            S'abonner
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default Blog; 