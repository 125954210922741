import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform, useMotionValueEvent, AnimatePresence, useInView } from 'framer-motion';
import {
  EnvelopeIcon,
  ArrowPathIcon,
  ServerIcon,
  CodeBracketIcon,
  CogIcon,
  ChartBarIcon,
  ShieldCheckIcon,
  BoltIcon,
  CheckCircleIcon,
  UserGroupIcon,
  LightBulbIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/outline';
import { useAuth } from "../contexts/AuthContext";

// Données des fonctionnalités
const features = [
  {
    id: 1,
    name: 'Rotation Intelligente',
    description: 'Rotation automatique des paramètres d\'envoi pour optimiser la délivrabilité et éviter les limitations.',
    icon: ArrowPathIcon,
    color: 'from-purple-600 to-indigo-600'
  },
  {
    id: 2,
    name: 'Templates Dynamiques',
    description: 'Créez des templates personnalisables avec variables intégrées et sections conditionnelles.',
    icon: CodeBracketIcon,
    color: 'from-blue-600 to-cyan-500'
  },
  {
    id: 3,
    name: 'Multi-SMTP',
    description: 'Connectez plusieurs serveurs SMTP (AWS SES, Sendgrid, SMTP personnalisés, Office 365) simultanément.',
    icon: ServerIcon,
    color: 'from-emerald-600 to-green-500'
  },
  {
    id: 4,
    name: 'Variables Avancées',
    description: 'Plus de 30 variables personnalisables pour des e-mails hautement ciblés et personnalisés.',
    icon: CogIcon,
    color: 'from-orange-500 to-amber-500'
  },
  {
    id: 5,
    name: 'Analytiques Précises',
    description: 'Suivez en temps réel les performances de vos campagnes: taux d\'ouverture, clics, et conversions.',
    icon: ChartBarIcon,
    color: 'from-pink-500 to-rose-500'
  },
  {
    id: 6,
    name: 'Sécurité Maximale',
    description: 'Protection avancée des données, authentification à deux facteurs et conformité RGPD intégrée.',
    icon: ShieldCheckIcon,
    color: 'from-indigo-500 to-indigo-600'
  }
];

// Statistiques améliorées
const stats = [
  { id: 1, name: 'Taux de délivrabilité', value: '99.8%', icon: EnvelopeIcon, prefix: '', suffix: '' },
  { id: 2, name: 'E-mails envoyés', value: '1.2', icon: BoltIcon, prefix: '', suffix: 'M+' },
  { id: 3, name: 'Clients satisfaits', value: '350', icon: UserGroupIcon, prefix: '', suffix: '+' },
  { id: 4, name: 'Temps économisé', value: '85', icon: CursorArrowRaysIcon, prefix: '', suffix: '%' }
];

// Fonctionnalités détaillées
const detailedFeatures = [
  {
    id: 1,
    name: "Automatisation Complète",
    description: "Notre système de rotation intelligent s'adapte en temps réel pour vous garantir les meilleurs taux de délivrabilité, même sur de grands volumes.",
    icon: LightBulbIcon,
    color: "bg-gradient-to-br from-indigo-500 to-purple-600"
  },
  {
    id: 2,
    name: "Personnalisation Avancée",
    description: "Définissez des règles conditionnelles pour vos templates et créez des messages hautement personnalisés qui augmentent l'engagement.",
    icon: CursorArrowRaysIcon,
    color: "bg-gradient-to-br from-blue-500 to-cyan-600"
  },
  {
    id: 3,
    name: "Performances Supérieures",
    description: "Optimisez chaque aspect de vos campagnes grâce à notre système d'analytique avancé et nos algorithmes d'amélioration continue.",
    icon: ChartBarIcon,
    color: "bg-gradient-to-br from-emerald-500 to-green-600"
  }
];

// Plans tarifaires
const pricingPlans = [
  {
    id: 1,
    name: "Essentiels",
    price: "0",
    currency: "€",
    frequency: "/mois",
    description: "Pour débuter et tester toutes les fonctionnalités de base.",
    features: [
      "1000 emails/mois",
      "2 serveurs SMTP",
      "10 templates personnalisables",
      "Rotation de base",
      "Support communautaire"
    ],
    mostPopular: false,
    cta: "Démarrer gratuitement",
    ctaLink: "/register"
  },
  {
    id: 2,
    name: "Professionnel",
    price: "39",
    currency: "€",
    frequency: "/mois",
    description: "Pour les professionnels et petites entreprises.",
    features: [
      "20 000 emails/mois",
      "Serveurs SMTP illimités",
      "Templates illimités",
      "Rotation avancée",
      "Analyses détaillées",
      "API complète",
      "Support prioritaire"
    ],
    mostPopular: true,
    cta: "Essai gratuit de 14 jours",
    ctaLink: "/register"
  },
  {
    id: 3,
    name: "Entreprise",
    price: "149",
    currency: "€",
    frequency: "/mois",
    description: "Solution complète pour les grandes organisations.",
    features: [
      "Volume illimité",
      "Tous les avantages Pro",
      "Serveurs dédiés",
      "Configuration personnalisée",
      "Onboarding individuel",
      "Manager de compte dédié",
      "SLA garanti"
    ],
    mostPopular: false,
    cta: "Contacter les ventes",
    ctaLink: "/contact"
  }
];

// Variantes d'animation
const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
  }
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12,
      delayChildren: 0.2
    }
  }
};

const scaleUp = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.6, ease: [0.22, 1, 0.36, 1] }
  }
};

const slideInRight = {
  hidden: { opacity: 0, x: 50 },
  visible: { 
    opacity: 1, 
    x: 0, 
    transition: { duration: 0.7, ease: [0.22, 1, 0.36, 1] }
  }
};

const slideInLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: { 
    opacity: 1, 
    x: 0, 
    transition: { duration: 0.7, ease: [0.22, 1, 0.36, 1] }
  }
};

// Composant pour l'animation des fonctionnalités
const FeatureAnimation = ({ feature, isVisible }: { feature: typeof detailedFeatures[0], isVisible: boolean }) => {
  if (!isVisible) return null;
  
  return (
    <motion.div
      key={feature.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className={`rounded-xl overflow-hidden h-64 w-full ${feature.color}`}
    >
      <div className="flex flex-col items-center justify-center h-full p-8 text-white">
        <feature.icon className="h-16 w-16 mb-6" />
        <h3 className="text-2xl font-bold mb-2">{feature.name}</h3>
        <p className="text-center text-white/80">{feature.description}</p>
      </div>
    </motion.div>
  );
};

// Ajout d'une fonction pour animer le compteur
interface CounterProps {
  from?: number;
  to: number;
  duration?: number;
  decimals?: number;
  suffix?: string;
  prefix?: string;
}

const Counter: React.FC<CounterProps> = ({ 
  from = 0, 
  to, 
  duration = 2, 
  decimals = 0, 
  suffix = '', 
  prefix = '' 
}) => {
  const [count, setCount] = useState<number>(from);
  const nodeRef = useRef<HTMLSpanElement>(null);
  const inView = useRef<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !inView.current) {
          inView.current = true;
          let startTimestamp: number | null = null;
          const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / (duration * 1000), 1);
            const currentCount = from + progress * (to - from);
            
            setCount(
              decimals > 0
                ? parseFloat(currentCount.toFixed(decimals))
                : Math.floor(currentCount)
            );

            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
        }
      },
      { threshold: 0.25 }
    );

    const currentNode = nodeRef.current;
    if (currentNode) {
      observer.observe(currentNode);
    }

    return () => {
      if (currentNode) {
        observer.unobserve(currentNode);
      }
    };
  }, [from, to, duration, decimals]);

  return (
    <span ref={nodeRef} className="counter">
      {prefix}{count}{suffix}
    </span>
  );
};

const Home: React.FC = () => {
  const { currentUser } = useAuth();
  const [activeFeature, setActiveFeature] = useState(0);
  const { scrollY } = useScroll();
  const heroRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const featureRefs = useRef<(HTMLDivElement | null)[]>([]);
  
  // Parallax effect pour le hero
  const y = useTransform(scrollY, [0, 500], [0, -150]);
  const opacity = useTransform(scrollY, [0, 300], [1, 0.3]);

  // Cycle automatique des fonctionnalités
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % detailedFeatures.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Fonction pour le défilement fluide vers les ancres
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      {/* Hero Section */}
      <div ref={heroRef} className="relative min-h-screen flex items-center">
        {/* Background avec particules */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-indigo-900 via-indigo-800 to-indigo-900"></div>
          <div className="absolute inset-0 opacity-30 bg-gradient-to-b from-indigo-900/20 to-indigo-600/20"></div>
          
          {/* Particules animées */}
          <div className="absolute top-1/3 left-1/4 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
          <div className="absolute top-1/2 right-1/4 w-72 h-72 bg-yellow-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
          <div className="absolute bottom-1/3 left-1/3 w-72 h-72 bg-pink-500 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000"></div>
        </div>

        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <motion.div 
            style={{ y, opacity }}
            className="max-w-4xl mx-auto text-center pt-20"
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <span className="inline-flex items-center rounded-full px-4 py-1 text-sm font-medium bg-indigo-400/10 text-indigo-200 backdrop-blur-sm border border-indigo-400/20 mb-6">
                <span className="flex h-2 w-2 mr-2">
                  <span className="animate-ping absolute h-2 w-2 rounded-full bg-indigo-300 opacity-75"></span>
                  <span className="relative rounded-full h-2 w-2 bg-indigo-400"></span>
                </span>
                Version 2.3 disponible maintenant
              </span>
            </motion.div>

            <motion.h1 
              className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold tracking-tight text-white mb-6 leading-tight"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <span className="block mb-2">Propulsez vos</span>
              <span className="block bg-gradient-to-r from-blue-300 to-purple-300 bg-clip-text text-transparent">
                campagnes email
              </span>
            </motion.h1>

            <motion.p 
              className="mt-6 text-xl leading-8 text-indigo-100 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.5 }}
            >
              Notre plateforme automatise et optimise vos envois d'emails avec 
              une rotation intelligente, des templates dynamiques et une compatibilité
              multi-SMTP pour une délivrabilité maximale.
            </motion.p>

            <motion.div 
              className="mt-12 flex flex-wrap justify-center gap-4"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.7 }}
            >
              {!currentUser ? (
                <React.Fragment>
                  <Link
                    to="/register"
                    className="rounded-full bg-white px-8 py-4 text-base font-semibold text-indigo-700 shadow-md hover:bg-indigo-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-200 group"
                  >
                    Démarrer gratuitement
                    <span className="ml-2 inline-block transition-transform group-hover:translate-x-1">→</span>
                  </Link>
                  <Link
                    to="/login"
                    className="rounded-full px-8 py-4 text-base font-semibold text-white hover:text-indigo-100 border border-indigo-400/30 hover:bg-white/10 transition-all duration-200"
                  >
                    Se connecter
                  </Link>
                </React.Fragment>
              ) : (
                <Link
                  to="/dashboard"
                  className="rounded-full bg-white px-8 py-4 text-base font-semibold text-indigo-700 shadow-md hover:bg-indigo-50 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-200 group"
                >
                  Accéder au dashboard
                  <span className="ml-2 inline-block transition-transform group-hover:translate-x-1">→</span>
                </Link>
              )}
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1 }}
              className="mt-24 relative"
            >
              <div className="absolute -inset-x-20 -top-[10%] -bottom-[10%] px-20 bg-gradient-to-b from-indigo-800/0 via-indigo-800/50 to-indigo-900 backdrop-blur-sm rounded-3xl"></div>
              <div className="relative shadow-2xl rounded-xl overflow-hidden border border-indigo-500/30 bg-indigo-700">
                <div className="w-full h-80 bg-gradient-to-br from-indigo-600 to-blue-700 flex items-center justify-center">
                  <div className="text-white text-center">
                    <div className="w-24 h-24 mx-auto mb-6 bg-white/10 rounded-xl flex items-center justify-center backdrop-blur-sm">
                      <EnvelopeIcon className="h-12 w-12 text-white" />
                    </div>
                    <h3 className="text-2xl font-bold">Interface intuitive</h3>
                    <p className="mt-2 text-indigo-100">Contrôlez toutes vos campagnes email en un seul endroit</p>
                  </div>
                </div>
                <div className="absolute inset-0 ring-1 ring-inset ring-white/10 rounded-xl"></div>
              </div>
            </motion.div>
          </motion.div>
        </div>
        
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce">
          <button 
            onClick={() => scrollToSection('features')}
            className="flex items-center justify-center w-12 h-12 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 text-white hover:bg-white/20 transition-colors"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>
          </button>
        </div>
      </div>

      {/* Logos Section */}
      <div className="bg-gradient-to-b from-indigo-900 to-indigo-800 py-12 relative overflow-hidden">
        <div className="absolute inset-0 opacity-20 bg-gradient-to-b from-indigo-900/30 to-indigo-700/30"></div>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mx-auto max-w-2xl text-center"
          >
            <p className="text-lg font-medium text-indigo-200">
              Ils font confiance à notre solution
            </p>
          </motion.div>
          
          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mt-10 flex flex-wrap justify-center gap-x-16 gap-y-8 sm:gap-x-24"
          >
            {[1, 2, 3, 4, 5].map((i) => (
              <motion.div 
                key={i} 
                variants={fadeInUp}
                className="max-w-[150px] h-12 flex items-center justify-center"
              >
                <div className="h-6 w-28 rounded bg-white/20 backdrop-blur-sm animate-pulse"></div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" ref={featuresRef} className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mx-auto max-w-2xl text-center"
          >
            <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-indigo-100 text-indigo-800">
              Fonctionnalités
            </span>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Tout ce dont vous avez besoin
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Notre plateforme offre des outils puissants pour optimiser vos campagnes 
              d'email marketing et maximiser vos résultats.
            </p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10"
          >
            {features.map((feature) => (
              <motion.div
                key={feature.id}
                variants={fadeInUp}
                className="relative flex flex-col h-full group"
              >
                <div className="rounded-2xl bg-white p-6 shadow-md ring-1 ring-gray-200 h-full hover:shadow-lg transition-shadow duration-300">
                  <div className={`mb-6 rounded-xl bg-gradient-to-r ${feature.color} p-3 w-fit`}>
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-lg font-semibold leading-7 text-gray-900">
                    {feature.name}
                  </h3>
                  <p className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Detailed Feature Showcase */}
      <div className="py-16 sm:py-24 bg-gradient-to-b from-white to-indigo-50 overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mx-auto max-w-2xl text-center"
          >
            <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-indigo-100 text-indigo-800">
              Comment ça marche
            </span>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Une plateforme conçue pour les professionnels
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Découvrez comment notre solution peut transformer votre approche de l'email marketing.
            </p>
          </motion.div>

          <div className="mt-20">
            <div className="flex flex-col lg:flex-row">
              {/* Sélecteur de fonctionnalités */}
              <div className="lg:w-1/3 flex flex-row lg:flex-col">
                {detailedFeatures.map((feature, idx) => (
                  <motion.button
                    key={feature.id}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.1 * idx }}
                    className={`text-left p-6 mb-2 border-l-2 transition-all duration-300 hover:bg-indigo-50/50 ${
                      activeFeature === idx 
                        ? "border-l-indigo-600 bg-indigo-50/80" 
                        : "border-l-transparent"
                    }`}
                    onClick={() => setActiveFeature(idx)}
                  >
                    <div className="flex items-center">
                      <div className="mr-4 rounded-lg bg-indigo-100 p-2">
                        <feature.icon className="h-6 w-6 text-indigo-600" />
                      </div>
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                        <p className="mt-1 text-sm text-gray-500">{feature.description}</p>
                      </div>
                    </div>
                  </motion.button>
                ))}
              </div>
              
              {/* Aperçu de la fonctionnalité */}
              <div className="lg:w-2/3 mt-8 lg:mt-0 relative">
                <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-200 p-2">
                  <div className="relative overflow-hidden rounded-xl h-64">
                    {detailedFeatures.map((feature, index) => (
                      <FeatureAnimation key={feature.id} feature={feature} isVisible={activeFeature === index} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-24 sm:py-32 relative bg-gradient-to-b from-indigo-50 to-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            variants={scaleUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mx-auto max-w-2xl text-center"
          >
            <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-indigo-100 text-indigo-800">
              Résultats prouvés
            </span>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Des chiffres qui parlent d'eux-mêmes
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Notre plateforme est utilisée par des centaines d'entreprises pour leurs campagnes email.
            </p>
          </motion.div>

          <motion.dl 
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mt-16 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4"
          >
            {stats.map((stat) => (
              <motion.div
                key={stat.id}
                variants={fadeInUp}
                className="flex flex-col rounded-xl border border-gray-200 bg-white p-8 shadow-sm hover:shadow-lg transition-all duration-300 group"
              >
                <dt className="text-base font-medium leading-6 text-gray-500 flex items-center">
                  <div className="mr-2 rounded-md bg-indigo-100 p-2 group-hover:bg-indigo-200 transition-colors">
                    <stat.icon className="h-5 w-5 text-indigo-600" aria-hidden="true" />
                  </div>
                  {stat.name}
                </dt>
                <dd className="mt-3 flex items-baseline justify-between">
                  <div className="flex items-baseline text-3xl font-semibold tracking-tight text-indigo-600 group-hover:text-indigo-700 transition-colors">
                    <Counter 
                      from={0} 
                      to={parseFloat(stat.value.replace(/[^\d.-]/g, ''))} 
                      duration={2.5} 
                      decimals={stat.value.includes('.') ? 1 : 0} 
                      prefix={stat.prefix} 
                      suffix={stat.suffix} 
                    />
                  </div>
                </dd>
              </motion.div>
            ))}
          </motion.dl>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="py-24 sm:py-32 relative bg-gradient-to-b from-white to-gray-50">
        <div className="absolute inset-y-0 right-0 hidden lg:block lg:w-1/2">
          <div className="absolute inset-0 bg-gradient-to-l from-indigo-50 to-transparent"></div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mx-auto max-w-2xl text-center"
          >
            <span className="inline-flex items-center rounded-full px-3 py-1 text-sm font-medium bg-indigo-100 text-indigo-800">
              Tarification
            </span>
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Choisissez le plan adapté à vos besoins
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Des plans flexibles qui évoluent avec votre activité, sans engagement ni frais cachés.
            </p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            className="mt-16 isolate gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
          >
            {pricingPlans.map((plan, idx) => (
              <motion.div
                key={plan.id}
                variants={fadeInUp}
                className={`flex flex-col rounded-3xl p-0.5 ${
                  plan.mostPopular 
                    ? 'bg-gradient-to-b from-indigo-500 to-blue-500 shadow-xl lg:scale-105 z-10 relative' 
                    : 'bg-white border border-gray-200 shadow-md'
                }`}
              >
                <div className={`rounded-[calc(1.5rem-1px)] p-8 sm:p-10 bg-white h-full flex flex-col relative`}>
                  {plan.mostPopular && (
                    <div className="absolute -top-3 right-0 left-0 flex justify-center">
                      <span className="inline-flex items-center rounded-full bg-indigo-600 px-4 py-1 text-xs font-semibold text-white shadow-sm">
                        Le plus populaire
                      </span>
                    </div>
                  )}
                  <div className={`${plan.mostPopular ? 'pt-4' : ''}`}>
                    <h3 className="text-lg font-semibold leading-8 text-indigo-600">{plan.name}</h3>
                    <div className="mt-4 flex items-baseline">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">{plan.currency}{plan.price}</span>
                      <span className="text-base font-semibold text-gray-500">{plan.frequency}</span>
                    </div>
                    <p className="mt-4 text-base leading-6 text-gray-600">{plan.description}</p>
                  </div>
                  <div className="mt-8 mb-8 space-y-3">
                    <div className="text-base font-medium text-gray-900 mb-3">Inclus:</div>
                    {plan.features.map((feature, featIdx) => (
                      <div key={featIdx} className="flex">
                        <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-indigo-500" aria-hidden="true" />
                        <span className="ml-3 text-sm text-gray-500">{feature}</span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-auto">
                    <Link
                      to={plan.ctaLink}
                      className={`block w-full rounded-lg px-4 py-3 text-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                        plan.mostPopular
                          ? 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600'
                          : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100'
                      }`}
                    >
                      {plan.cta}
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
          
          <motion.div
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="mt-20 text-center"
          >
            <h3 className="text-xl font-semibold text-gray-900">Besoin d'une solution personnalisée?</h3>
            <p className="mt-4 text-base leading-7 text-gray-600 max-w-2xl mx-auto">
              Contactez notre équipe commerciale pour une offre adaptée à vos besoins spécifiques.
            </p>
            <div className="mt-6">
              <Link
                to="/contact"
                className="inline-flex items-center rounded-full text-sm font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Contacter les ventes
                <span className="ml-2">→</span>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-b from-gray-50 to-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
          <motion.div
            variants={scaleUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className="relative isolate overflow-hidden bg-gradient-to-r from-indigo-800 to-indigo-600 px-6 py-16 sm:py-24 sm:rounded-3xl shadow-2xl"
          >
            <div className="absolute inset-0 opacity-20 bg-gradient-to-b from-indigo-600/30 to-indigo-800/30"></div>
            <div className="mx-auto max-w-3xl flex flex-col items-center relative">
              <motion.h2 
                variants={fadeInUp}
                className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl"
              >
                Prêt à transformer vos campagnes email ?
              </motion.h2>
              <motion.p 
                variants={fadeInUp}
                className="mx-auto mt-6 max-w-xl text-center text-lg leading-8 text-indigo-100"
              >
                Commencez dès aujourd'hui et découvrez comment notre plateforme peut vous aider 
                à atteindre vos objectifs en matière d'email marketing.
              </motion.p>
              <motion.div 
                variants={fadeInUp}
                className="mt-10 flex flex-wrap gap-x-6 gap-y-4 justify-center"
              >
                <Link
                  to="/register"
                  className="rounded-full bg-white px-8 py-4 text-base font-semibold text-indigo-600 shadow-md hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white group transition-all duration-300"
                >
                  Démarrer gratuitement
                  <span className="ml-2 inline-block transition-transform group-hover:translate-x-1">→</span>
                </Link>
                <Link
                  to="/contact"
                  className="rounded-full px-8 py-4 text-base font-semibold text-white border border-white/30 hover:bg-white/10 transition-all duration-300"
                >
                  Demander une démo
                </Link>
              </motion.div>
            </div>
            
            {/* Éléments décoratifs */}
            <div className="absolute -top-10 -left-10 w-72 h-72 bg-indigo-400 rounded-full mix-blend-multiply filter blur-3xl opacity-20"></div>
            <div className="absolute -bottom-10 -right-10 w-72 h-72 bg-blue-400 rounded-full mix-blend-multiply filter blur-3xl opacity-20"></div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Home; 