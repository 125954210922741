import React from "react";

interface Service {
  name: string;
  status: "operational" | "degraded" | "outage";
  uptime: string;
}

interface Incident {
  id: string;
  title: string;
  description: string;
  date: string;
  status: "resolved" | "monitoring" | "identified" | "investigating";
}

const services: Service[] = [
  {
    name: "API",
    status: "operational",
    uptime: "99.99%"
  },
  {
    name: "Interface Web",
    status: "operational",
    uptime: "99.98%"
  },
  {
    name: "Service SMTP",
    status: "operational",
    uptime: "99.97%"
  },
  {
    name: "Base de données",
    status: "operational",
    uptime: "99.99%"
  }
];

const incidents: Incident[] = [
  {
    id: "INC-001",
    title: "Ralentissement de l'API",
    description: "Nous avons détecté un ralentissement de l'API. Nos équipes travaillent sur la résolution.",
    date: "2024-03-15",
    status: "resolved"
  },
  {
    id: "INC-002",
    title: "Maintenance planifiée",
    description: "Une maintenance planifiée aura lieu le 20 mars 2024 de 2h à 4h UTC.",
    date: "2024-03-20",
    status: "monitoring"
  }
];

const Status: React.FC = () => {
  const getStatusColor = (status: Service["status"]) => {
    switch (status) {
      case "operational":
        return "bg-green-100 text-green-800";
      case "degraded":
        return "bg-yellow-100 text-yellow-800";
      case "outage":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getIncidentStatusColor = (status: Incident["status"]) => {
    switch (status) {
      case "resolved":
        return "bg-green-100 text-green-800";
      case "monitoring":
        return "bg-blue-100 text-blue-800";
      case "identified":
        return "bg-yellow-100 text-yellow-800";
      case "investigating":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getIncidentStatusLabel = (status: Incident["status"]) => {
    switch (status) {
      case "resolved":
        return "Résolu";
      case "monitoring":
        return "Surveillance";
      case "identified":
        return "Identifié";
      case "investigating":
        return "En cours d'investigation";
      default:
        return status;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">État du système</h1>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-medium text-gray-900">Services</h2>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {services.map((service, index) => (
              <div
                key={service.name}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}
              >
                <dt className="text-sm font-medium text-gray-500">{service.name}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-between items-center">
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(
                      service.status
                    )}`}
                  >
                    {service.status === "operational" ? "Opérationnel" : service.status === "degraded" ? "Dégradé" : "En panne"}
                  </span>
                  <span className="text-sm text-gray-500">Disponibilité : {service.uptime}</span>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-medium text-gray-900">Incidents récents</h2>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {incidents.map((incident) => (
              <li key={incident.id} className="px-4 py-5 sm:px-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900">{incident.title}</h3>
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getIncidentStatusColor(
                      incident.status
                    )}`}
                  >
                    {getIncidentStatusLabel(incident.status)}
                  </span>
                </div>
                <p className="mt-2 text-sm text-gray-500">{incident.description}</p>
                <p className="mt-2 text-xs text-gray-400">Date : {incident.date}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Status; 