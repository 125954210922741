import React from "react";

const AdminRoles: React.FC = () => {
  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Gestion des Rôles et Permissions</h1>
      <p className="text-gray-600 mb-4">Cette section permet de gérer les rôles utilisateurs et leurs permissions associées.</p>
      
      <div className="bg-gray-100 p-4 rounded-lg mt-4">
        <h2 className="text-lg font-medium mb-2">Rôles Disponibles</h2>
        <ul className="space-y-2">
          <li className="flex items-center p-2 bg-white rounded shadow-sm">
            <span className="font-medium">Administrateur</span>
            <span className="ml-2 px-2 py-1 bg-indigo-100 text-indigo-800 rounded-full text-xs">Accès complet</span>
          </li>
          <li className="flex items-center p-2 bg-white rounded shadow-sm">
            <span className="font-medium">Modérateur</span>
            <span className="ml-2 px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Accès modération</span>
          </li>
          <li className="flex items-center p-2 bg-white rounded shadow-sm">
            <span className="font-medium">Utilisateur</span>
            <span className="ml-2 px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">Accès standard</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminRoles; 