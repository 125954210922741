import React, { useState, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';

// Type pour les résultats de l'API IPQUALITYSCORE
interface EmailValidationResult {
  valid: boolean;
  disposable: boolean;
  spam_trap_score: number;
  overall_score: number;
  smtp_score: number;
  deliverability: 'high' | 'medium' | 'low' | 'unknown';
  frequent_complainer: boolean;
  honeypot: boolean;
  dns_valid: boolean;
  catch_all: boolean;
  generic: boolean;
  common_domain: boolean;
  first_name: string;
  suggested_domain: string;
  sanitized_email: string;
  email: string;
}

interface BatchValidationResults {
  results: EmailValidationResult[];
  valid_percentage: number;
  invalid_percentage: number;
  disposable_percentage: number;
  overall_quality_score: number;
}

const EmailQualityChecker: React.FC = () => {
  const { currentUser } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<BatchValidationResults | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [emailCount, setEmailCount] = useState<number>(0);
  const [scansRemaining, setScansRemaining] = useState<number>(5); // À récupérer depuis la base de données

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setResults(null);
    
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      
      // Vérifier le type de fichier
      if (selectedFile.type !== 'text/plain' && selectedFile.type !== 'text/csv' && !selectedFile.name.endsWith('.csv') && !selectedFile.name.endsWith('.txt')) {
        setError('Veuillez télécharger un fichier CSV ou TXT.');
        return;
      }
      
      // Vérifier la taille du fichier (max 10MB)
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('Le fichier est trop grand. La taille maximale est de 10MB.');
        return;
      }

      // Lire le fichier pour compter les adresses email
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          const content = event.target.result;
          const emails = content.split(/[\n,;]/).filter(email => 
            email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
          );
          
          // Vérifier le nombre d'emails (max 200)
          if (emails.length > 200) {
            setError('Le fichier contient plus de 200 adresses email. Veuillez réduire la taille de votre liste.');
            return;
          }
          
          setEmailCount(emails.length);
          setFile(selectedFile);
        }
      };
      
      reader.readAsText(selectedFile);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const dataTransferRef = {
        target: {
          files: e.dataTransfer.files
        }
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      
      handleFileChange(dataTransferRef);
    }
  };

  const analyzeEmails = async () => {
    if (!file) {
      setError('Veuillez d\'abord télécharger un fichier.');
      return;
    }

    if (scansRemaining <= 0) {
      setError('Vous avez épuisé votre quota de scans. Veuillez recharger votre compte.');
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      // Ici, nous simulons l'appel à l'API IPQUALITYSCORE
      // Dans un environnement réel, vous feriez un appel à l'API avec les données du fichier
      
      // Simulation d'un délai d'attente
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Simuler un résultat de l'API
      const mockResults: BatchValidationResults = {
        results: Array.from({ length: emailCount }).map((_, index) => ({
          valid: Math.random() > 0.2,
          disposable: Math.random() > 0.8,
          spam_trap_score: Math.random(),
          overall_score: Math.random(),
          smtp_score: Math.random(),
          deliverability: ['high', 'medium', 'low', 'unknown'][Math.floor(Math.random() * 4)] as any,
          frequent_complainer: Math.random() > 0.9,
          honeypot: Math.random() > 0.95,
          dns_valid: Math.random() > 0.1,
          catch_all: Math.random() > 0.7,
          generic: Math.random() > 0.7,
          common_domain: Math.random() > 0.3,
          first_name: '',
          suggested_domain: '',
          sanitized_email: `email${index}@example.com`,
          email: `email${index}@example.com`
        })),
        valid_percentage: 80 + Math.random() * 20,
        invalid_percentage: Math.random() * 20,
        disposable_percentage: Math.random() * 15,
        overall_quality_score: 70 + Math.random() * 30
      };
      
      setResults(mockResults);
      setScansRemaining(prev => prev - 1);
      
      // Dans un environnement réel, vous mettriez à jour la base de données pour décrémenter le nombre de scans restants
    } catch (err) {
      setError('Une erreur s\'est produite lors de l\'analyse. Veuillez réessayer.');
      console.error('Error analyzing emails:', err);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    setEmailCount(0);
    setResults(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const renderUploadSection = () => (
    <div 
      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="space-y-1 text-center">
        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <div className="flex text-sm text-gray-600">
          <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <span>Télécharger un fichier</span>
            <input 
              ref={fileInputRef}
              id="file-upload" 
              name="file-upload" 
              type="file" 
              className="sr-only" 
              accept=".csv,.txt" 
              onChange={handleFileChange}
            />
          </label>
          <p className="pl-1">ou glisser-déposer</p>
        </div>
        <p className="text-xs text-gray-500">CSV, TXT jusqu'à 200 emails (maximum 10MB)</p>
      </div>
    </div>
  );

  const renderFileInfo = () => (
    <div className="mt-4 bg-gray-50 p-4 rounded-md">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-900">{file?.name}</p>
          <p className="text-xs text-gray-500">{emailCount} adresses email détectées</p>
        </div>
        <button 
          type="button" 
          className="ml-4 inline-flex text-sm font-medium text-red-600 hover:text-red-500"
          onClick={resetForm}
        >
          Supprimer
        </button>
      </div>
    </div>
  );

  const getQualityColor = (score: number) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 60) return 'text-yellow-600';
    return 'text-red-600';
  };

  const renderResults = () => {
    if (!results) return null;
    
    return (
      <div className="mt-8 space-y-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Résumé de la qualité</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="bg-gray-50 p-4 rounded-md text-center">
              <p className="text-sm text-gray-500">Score global</p>
              <p className={`text-2xl font-bold ${getQualityColor(results.overall_quality_score)}`}>
                {results.overall_quality_score.toFixed(1)}%
              </p>
            </div>
            <div className="bg-gray-50 p-4 rounded-md text-center">
              <p className="text-sm text-gray-500">Emails valides</p>
              <p className="text-2xl font-bold text-green-600">{results.valid_percentage.toFixed(1)}%</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-md text-center">
              <p className="text-sm text-gray-500">Emails invalides</p>
              <p className="text-2xl font-bold text-red-600">{results.invalid_percentage.toFixed(1)}%</p>
            </div>
            <div className="bg-gray-50 p-4 rounded-md text-center">
              <p className="text-sm text-gray-500">Emails jetables</p>
              <p className="text-2xl font-bold text-yellow-600">{results.disposable_percentage.toFixed(1)}%</p>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow overflow-x-auto">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Détails des emails</h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Score
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Délivrabilité
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Problèmes
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {results.results.map((result, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {result.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      result.valid ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {result.valid ? 'Valide' : 'Invalide'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`font-medium ${getQualityColor(result.overall_score * 100)}`}>
                      {(result.overall_score * 100).toFixed(1)}%
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`
                      ${result.deliverability === 'high' ? 'text-green-600' : 
                        result.deliverability === 'medium' ? 'text-yellow-600' : 
                        result.deliverability === 'low' ? 'text-red-600' : 'text-gray-500'}
                    `}>
                      {result.deliverability === 'high' ? 'Élevée' : 
                       result.deliverability === 'medium' ? 'Moyenne' : 
                       result.deliverability === 'low' ? 'Faible' : 'Inconnue'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {[
                      result.disposable && 'Jetable',
                      result.honeypot && 'Honeypot',
                      result.frequent_complainer && 'Plaintes fréquentes',
                      !result.dns_valid && 'DNS invalide'
                    ].filter(Boolean).join(', ') || 'Aucun'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Testeur de qualité d'email</h2>
          <span className="px-4 py-2 bg-green-100 text-green-800 rounded-full text-sm">
            Scans restants: {scansRemaining}
          </span>
        </div>
      </div>
      <div className="p-6">
        {!results && (
          <>
            <div className="mb-6">
              <p className="text-gray-600 mb-4">
                Vérifiez la qualité de votre liste d'emails avant de lancer votre campagne.
                Téléchargez jusqu'à 200 adresses email pour les analyser via IPQUALITYSCORE.
              </p>
              {renderUploadSection()}
              {file && renderFileInfo()}
              {error && (
                <div className="mt-4 text-sm text-red-600">
                  {error}
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <button 
                className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={analyzeEmails}
                disabled={!file || loading || scansRemaining <= 0}
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Analyse en cours...</span>
                  </>
                ) : 'Lancer l\'analyse'}
              </button>
            </div>
          </>
        )}
        
        {renderResults()}
        
        {results && (
          <div className="mt-6 flex justify-end">
            <button 
              className="px-6 py-3 bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 transition mr-4"
              onClick={resetForm}
            >
              Nouvelle analyse
            </button>
            <button className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition">
              Télécharger le rapport (CSV)
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailQualityChecker; 