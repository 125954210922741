import { useState, useEffect, useCallback } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  Timestamp,
  getDoc
} from "firebase/firestore";
import { db } from "../config/firebase";
import { Ticket, TicketMessage, TicketFilter, TicketStats } from "../types/tickets";
import { usePermissions } from "./usePermissions";
import { useAuth } from "../contexts/AuthContext";

const useTickets = (filter?: TicketFilter) => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [stats, setStats] = useState<TicketStats>({
    total: 0,
    openTickets: 0,
    resolvedToday: 0,
    averageResponseTime: 0,
    byStatus: {
      open: 0,
      in_progress: 0,
      resolved: 0,
      closed: 0
    },
    byPriority: {
      low: 0,
      medium: 0,
      high: 0,
      urgent: 0
    },
    byCategory: {
      technical: 0,
      billing: 0,
      feature_request: 0,
      bug: 0,
      other: 0
    }
  });

  const permissions = usePermissions();
  const { currentUser } = useAuth();
  const canViewTickets = permissions.hasPermission("view_tickets");
  const canManageTickets = permissions.hasPermission("manage_tickets");

  const updateStats = useCallback((ticketData: Ticket[]) => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const byStatus = {
      open: 0,
      in_progress: 0,
      resolved: 0,
      closed: 0
    };

    const byPriority = {
      low: 0,
      medium: 0,
      high: 0,
      urgent: 0
    };

    const byCategory = {
      technical: 0,
      billing: 0,
      feature_request: 0,
      bug: 0,
      other: 0
    };

    let resolvedToday = 0;

    ticketData.forEach(ticket => {
      byStatus[ticket.status]++;
      byPriority[ticket.priority]++;
      byCategory[ticket.category]++;

      // Compter tous les tickets avec le statut "resolved"
      if (ticket.status === "resolved") {
        resolvedToday++;
      }
    });

    setStats({
      total: ticketData.length,
      openTickets: byStatus.open + byStatus.in_progress,
      resolvedToday,
      averageResponseTime: calculateAverageResponseTime(ticketData),
      byStatus,
      byPriority,
      byCategory
    });
  }, []);

  useEffect(() => {
    // Si l'utilisateur n'est pas connecté, ne pas essayer de charger les tickets
    if (!currentUser) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let unsubscribe = () => {};

    try {
      // Construction de la requête - seulement récupérer les tickets créés par l'utilisateur si c'est un client
      let q;
      
      // Les utilisateurs standards voient uniquement leurs tickets
      if (currentUser.role === 'client' || !canManageTickets) {
        q = query(
          collection(db, "tickets"), 
          where("userId", "==", currentUser.uid),
          orderBy("updatedAt", "desc") // Utiliser updatedAt au lieu de createdAt pour voir les plus récentes mises à jour en premier
        );
      } else {
        // Les administrateurs et supports voient tous les tickets
        q = query(collection(db, "tickets"), orderBy("updatedAt", "desc"));

        // Application des filtres
        if (filter) {
          if (filter.status && filter.status.length > 0) {
            q = query(q, where("status", "in", filter.status));
          }
          if (filter.priority && filter.priority.length > 0) {
            q = query(q, where("priority", "in", filter.priority));
          }
          if (filter.category && filter.category.length > 0) {
            q = query(q, where("category", "in", filter.category));
          }
          if (filter.assignedTo) {
            q = query(q, where("assignedTo", "==", filter.assignedTo));
          }
        }
      }

      // Souscription aux mises à jour en temps réel
      unsubscribe = onSnapshot(q, snapshot => {
        const ticketData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Ticket[];

        setTickets(ticketData);
        updateStats(ticketData);
        setLoading(false);
      }, err => {
        console.error("Erreur lors de la récupération des tickets:", err);
        setError("Une erreur est survenue lors de la récupération des tickets.");
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (err) {
      console.error("Erreur lors de l'initialisation:", err);
      setError("Une erreur est survenue lors de l'initialisation du service de tickets.");
    }
  }, [filter, canViewTickets, canManageTickets, updateStats, currentUser]);

  const calculateAverageResponseTime = (tickets: Ticket[]): number => {
    const ticketsWithResponse = tickets.filter(ticket => {
      const firstResponse = ticket.messages.find(m => m.userRole === "support");
      return firstResponse !== undefined;
    });

    if (ticketsWithResponse.length === 0) return 0;

    const totalResponseTime = ticketsWithResponse.reduce((sum, ticket) => {
      const firstResponse = ticket.messages.find(m => m.userRole === "support");
      if (!firstResponse) return sum;

      const createdAt = ticket.createdAt.toDate();
      const responseAt = firstResponse.createdAt.toDate();
      return sum + (responseAt.getTime() - createdAt.getTime());
    }, 0);

    return Math.round(totalResponseTime / ticketsWithResponse.length / (1000 * 60)); // Convert to minutes
  };

  const createTicket = async (ticketData: Omit<Ticket, "id" | "createdAt" | "updatedAt" | "messages">, bypassPermissions = false): Promise<string> => {
    try {
      console.log("Tentative de création d'un ticket:", ticketData);
      
      // Vérifier les permissions seulement si bypassPermissions est false
      if (!bypassPermissions) {
        // Permettre aux utilisateurs avec le rôle "client" de créer des tickets
        const userRole = currentUser?.role;
        const canCreateTicket = permissions.hasPermission("manage_tickets") || 
                               permissions.hasPermission("view_tickets") || 
                               userRole === "client";
        
        if (!canCreateTicket) {
          throw new Error("Permissions insuffisantes pour créer un ticket");
        }
      }

      const newTicket = {
        ...ticketData,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        messages: []
      };

      const docRef = await addDoc(collection(db, "tickets"), newTicket);
      console.log("Ticket créé avec succès:", docRef.id);
      return docRef.id;
    } catch (err) {
      console.error("Erreur lors de la création du ticket:", {
        error: err,
        ticketData,
        stack: err instanceof Error ? err.stack : undefined
      });
      throw err; // Passer l'erreur originale plutôt que de la wrapper
    }
  };

  const updateTicket = async (ticketId: string, updates: Partial<Ticket>): Promise<void> => {
    try {
      console.log("Tentative de mise à jour du ticket:", {
        ticketId,
        updates
      });
      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, {
        ...updates,
        updatedAt: Timestamp.now()
      });
      console.log("Ticket mis à jour avec succès:", ticketId);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du ticket:", {
        error: err,
        ticketId,
        updates,
        stack: err instanceof Error ? err.stack : undefined
      });
      throw new Error("Une erreur est survenue lors de la mise à jour du ticket.");
    }
  };

  const addMessageToTicket = async (ticketId: string, content: string, userRole: "user" | "support" | "admin" | "client" | "staff"): Promise<void> => {
    try {
      const ticketRef = doc(db, "tickets", ticketId);
      
      // Récupérer le ticket actuel pour vérifier les destinataires de notification
      const ticketSnap = await getDoc(ticketRef);
      if (!ticketSnap.exists()) {
        throw new Error("Le ticket n'existe pas");
      }
      
      const ticketData = ticketSnap.data() as Ticket;
      const isUserMessage = userRole === "user" || userRole === "client";
      const isStaffMessage = userRole === "admin" || userRole === "support" || userRole === "staff";
      
      // Préparer le nouveau message
      const newMessage: TicketMessage = {
        id: Math.random().toString(36).substring(2, 15),
        content,
        userRole,
        createdAt: Timestamp.now(),
        createdBy: currentUser?.uid || "anonyme",
        createdByName: currentUser?.displayName || "Utilisateur",
        isRead: false
      };
      
      // Mettre à jour le statut si nécessaire
      let statusUpdate = {};
      if (isStaffMessage && ticketData.status === "open") {
        statusUpdate = { status: "in_progress" };
      }
      
      // Marquer pour notification
      let notificationUpdate = {};
      
      if (isStaffMessage) {
        // Si c'est un message du staff, marquer une notification pour l'utilisateur
        notificationUpdate = { 
          userNotified: false, 
          lastStaffReply: Timestamp.now(),
          hasUnreadStaffMessage: true
        };
      } else if (isUserMessage) {
        // Si c'est un message de l'utilisateur, marquer une notification pour le staff
        notificationUpdate = { 
          staffNotified: false,
          lastUserReply: Timestamp.now(),
          hasUnreadUserMessage: true
        };
      }
      
      // Mettre à jour le ticket
      await updateDoc(ticketRef, {
        messages: arrayUnion(newMessage),
        updatedAt: Timestamp.now(),
        ...statusUpdate,
        ...notificationUpdate
      });
      
      // Nous n'avons plus besoin de mettre à jour manuellement l'état local
      // puisque le listener Firestore le fera automatiquement
    } catch (err) {
      console.error("Erreur lors de l'ajout du message:", err);
      throw err;
    }
  };

  // Récupérer un ticket par son ID
  const getTicket = useCallback(async (ticketId: string): Promise<Ticket | null> => {
    try {
      const ticketRef = doc(db, "tickets", ticketId);
      const ticketSnap = await getDoc(ticketRef);
      
      if (ticketSnap.exists()) {
        return {
          id: ticketSnap.id,
          ...ticketSnap.data()
        } as Ticket;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du ticket:", error);
      return null;
    }
  }, []);

  if (!canViewTickets && currentUser?.role !== 'client') {
    return {
      tickets: [],
      loading: false,
      stats,
      error: "Vous n'avez pas les permissions nécessaires pour voir les tickets.",
      createTicket: canManageTickets 
        ? createTicket 
        : () => Promise.reject("Permissions insuffisantes pour créer un ticket"),
      updateTicket: () => Promise.reject("Permissions insuffisantes"),
      addMessage: () => Promise.reject("Permissions insuffisantes"),
      getTicket: () => Promise.reject("Permissions insuffisantes")
    };
  }

  return {
    tickets,
    loading,
    error,
    stats,
    createTicket,
    updateTicket,
    addMessage: addMessageToTicket,
    getTicket
  };
};

export default useTickets; 