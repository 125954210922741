import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Ticket, TICKET_STATUS_LABELS, TICKET_PRIORITY_LABELS, TICKET_CATEGORY_LABELS, TicketStatus, TicketPriority } from "../types/tickets";
import useTickets from "../hooks/useTickets";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { useNotifications } from "../contexts/NotificationContext";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const TicketDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getTicket, addMessage, updateTicket } = useTickets();
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useAuth();
  const { markAsRead } = useNotifications();

  // Déterminer si l'utilisateur peut modifier le statut et la priorité
  const isAdmin = currentUser?.role === "admin";
  const isSupport = currentUser?.role === "support";
  const canEditDetails = isAdmin || isSupport;

  useEffect(() => {
    const fetchTicket = async () => {
      if (!id) return;
      
      try {
        setLoading(true);
        const ticketData = await getTicket(id);
        if (ticketData) {
          setTicket(ticketData);
        } else {
          setError("Ticket non trouvé");
        }
      } catch (err) {
        console.error("Erreur lors de la récupération du ticket:", err);
        setError("Une erreur est survenue lors du chargement du ticket");
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [id, getTicket]);

  // Marquer les messages comme lus lorsque le ticket est ouvert
  useEffect(() => {
    const markTicketAsRead = async () => {
      try {
        if (!currentUser || !ticket) return;
        
        const isStaff = currentUser.role === 'admin' || currentUser.role === 'support';
        
        // Si c'est un utilisateur standard et qu'il y a des messages non lus du staff
        if (!isStaff && ticket.hasUnreadStaffMessage) {
          await updateTicket(ticket.id, { 
            hasUnreadStaffMessage: false 
          });
          await markAsRead(ticket.id);
        }
        // Si c'est un membre du staff et qu'il y a des messages non lus de l'utilisateur
        else if (isStaff && ticket.hasUnreadUserMessage) {
          await updateTicket(ticket.id, { 
            hasUnreadUserMessage: false 
          });
        }
      } catch (error) {
        console.error("Erreur lors du marquage des messages comme lus:", error);
      }
    };
    
    markTicketAsRead();
  }, [ticket, currentUser, updateTicket, markAsRead]);

  const handleGoBack = () => {
    navigate("/support");
  };

  const handleSubmitMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newMessage.trim() || !currentUser || !ticket) return;
    
    setIsSubmitting(true);

    try {
      // Déterminer le rôle réel de l'utilisateur pour le message
      const userRole = currentUser.role === 'admin' || currentUser.role === 'support' 
        ? 'support' 
        : 'user';

      // Utiliser la nouvelle signature de la fonction addMessage
      await addMessage(ticket.id, newMessage, userRole);
      
      setNewMessage("");
      toast.success("Message envoyé avec succès!");
      
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      toast.error("Erreur lors de l'envoi du message. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle status change
  const handleStatusChange = async (status: TicketStatus) => {
    if (!ticket) return;
    
    try {
      await updateTicket(ticket.id, { status });
      toast.success("Statut du ticket mis à jour");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
      toast.error("Erreur lors de la mise à jour du statut");
    }
  };

  // Handle priority change
  const handlePriorityChange = async (priority: TicketPriority) => {
    if (!ticket) return;
    
    try {
      await updateTicket(ticket.id, { priority });
      toast.success("Priorité du ticket mise à jour");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la priorité:", error);
      toast.error("Erreur lors de la mise à jour de la priorité");
    }
  };

  // Fonction pour déterminer le nom d'affichage du rôle
  const getRoleDisplayName = (role: string) => {
    switch(role) {
      case 'admin':
      case 'support':
        return 'Support';
      case 'user':
      case 'client':
      default:
        return 'Utilisateur';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[80vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error || !ticket) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-6 text-center">
          <h2 className="text-xl font-medium text-red-600 mb-2">
            {error || "Ticket non trouvé"}
          </h2>
          <p className="text-red-500 mb-4">
            Le ticket que vous recherchez n'existe pas ou vous n'avez pas les permissions nécessaires pour y accéder.
          </p>
          <button
            onClick={handleGoBack}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
          >
            Retour à la liste des tickets
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white shadow-lg rounded-lg overflow-hidden"
      >
        <div className="p-6">
          <div className="flex items-center mb-6">
            <button
              onClick={handleGoBack}
              className="flex items-center text-indigo-600 hover:text-indigo-800 mr-4"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" />
              Retour
            </button>
            <h1 className="text-2xl font-bold text-gray-900">
              Ticket #{ticket.id.slice(0, 8)}
            </h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-2">Détails du ticket</h2>
              <dl className="space-y-2">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Titre</dt>
                  <dd className="text-sm text-gray-900">{ticket.title}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Description</dt>
                  <dd className="text-sm text-gray-900">{ticket.description}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Statut</dt>
                  <dd className="text-sm text-gray-900">{TICKET_STATUS_LABELS[ticket.status]}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Priorité</dt>
                  <dd className="text-sm text-gray-900">{TICKET_PRIORITY_LABELS[ticket.priority]}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Catégorie</dt>
                  <dd className="text-sm text-gray-900">{TICKET_CATEGORY_LABELS[ticket.category]}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Date de création</dt>
                  <dd className="text-sm text-gray-900">
                    {(ticket.createdAt as Timestamp).toDate().toLocaleDateString()}
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-lg font-medium text-gray-900 mb-2">Actions</h2>
              <div className="space-y-4">
                {canEditDetails ? (
                  <>
                    <div>
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                        Changer le statut
                      </label>
                      <select
                        id="status"
                        value={ticket.status}
                        onChange={(e) => handleStatusChange(e.target.value as TicketStatus)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        {Object.entries(TICKET_STATUS_LABELS).map(([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                        Changer la priorité
                      </label>
                      <select
                        id="priority"
                        value={ticket.priority}
                        onChange={(e) => handlePriorityChange(e.target.value as TicketPriority)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        {Object.entries(TICKET_PRIORITY_LABELS).map(([value, label]) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center mb-2">
                      <span className="text-sm text-gray-500 w-20">Statut:</span>
                      <span
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                          ${ticket.status === "open" ? "bg-yellow-100 text-yellow-800" : ""}
                          ${ticket.status === "in_progress" ? "bg-blue-100 text-blue-800" : ""}
                          ${ticket.status === "resolved" ? "bg-green-100 text-green-800" : ""}
                          ${ticket.status === "closed" ? "bg-gray-100 text-gray-800" : ""}
                        `}
                      >
                        {TICKET_STATUS_LABELS[ticket.status]}
                      </span>
                    </div>
                    
                    <div className="flex items-center mb-2">
                      <span className="text-sm text-gray-500 w-20">Priorité:</span>
                      <span 
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                          ${ticket.priority === "low" ? "bg-green-100 text-green-800" : ""}
                          ${ticket.priority === "medium" ? "bg-blue-100 text-blue-800" : ""}
                          ${ticket.priority === "high" ? "bg-orange-100 text-orange-800" : ""}
                          ${ticket.priority === "urgent" ? "bg-red-100 text-red-800" : ""}
                        `}
                      >
                        {TICKET_PRIORITY_LABELS[ticket.priority]}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Messages</h2>
            <div className="space-y-4 mb-6">
              {ticket.messages && ticket.messages.length > 0 ? (
                ticket.messages.map((message, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg ${
                      message.userRole === "support"
                        ? "bg-blue-50 ml-8"
                        : "bg-gray-50 mr-8"
                    }`}
                  >
                    <div className="flex justify-between items-start">
                      <span className="text-sm font-medium text-gray-900">
                        {getRoleDisplayName(message.userRole)}
                      </span>
                      <span className="text-xs text-gray-500">
                        {(message.createdAt as Timestamp).toDate().toLocaleDateString()} 
                        {(message.createdAt as Timestamp).toDate().toLocaleTimeString()}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-700">{message.content}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center">Aucun message pour le moment.</p>
              )}
            </div>

            <form onSubmit={handleSubmitMessage} className="space-y-4">
              <div>
                <label htmlFor="newMessage" className="sr-only">
                  Nouveau message
                </label>
                <textarea
                  id="newMessage"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  rows={4}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Écrivez votre message ici..."
                  required
                  disabled={isSubmitting}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Envoi en cours..." : "Envoyer le message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TicketDetailsPage; 