import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface PrivateRouteProps {
  element: React.ReactNode;
  requireEmailVerification?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, requireEmailVerification = true }) => {
  const { currentUser, loading, isEmailVerified } = useAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500" />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Si la vérification d'email est requise et que l'email n'est pas vérifié
  if (requireEmailVerification && !isEmailVerified(currentUser)) {
    return <Navigate to="/verify-email" />;
  }

  return <React.Fragment>{element}</React.Fragment>;
};

export default PrivateRoute; 