import React from "react";

const AdminSettings: React.FC = () => {
  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Paramètres de l'Application</h1>
      <p className="text-gray-600 mb-6">Configuration des fonctionnalités de votre application.</p>
      
      <div className="space-y-6">
        {/* Section Fonctionnalités */}
        <div className="border p-4 rounded-md">
          <h2 className="text-lg font-medium mb-3">Fonctionnalités</h2>
          <div className="space-y-2">
            <div className="flex items-center">
              <input 
                type="checkbox" 
                id="enableRegistration" 
                className="mr-2"
                defaultChecked={true}
              />
              <label htmlFor="enableRegistration">
                Activer l'inscription des utilisateurs
              </label>
            </div>
            <div className="flex items-center">
              <input 
                type="checkbox" 
                id="enableTickets" 
                className="mr-2"
                defaultChecked={true}
              />
              <label htmlFor="enableTickets">
                Activer le système de tickets
              </label>
            </div>
            <div className="flex items-center">
              <input 
                type="checkbox" 
                id="requireEmailVerification" 
                className="mr-2"
                defaultChecked={false}
              />
              <label htmlFor="requireEmailVerification">
                Exiger la vérification de l'email
              </label>
            </div>
          </div>
        </div>
        
        {/* Section Catégories */}
        <div className="border p-4 rounded-md">
          <h2 className="text-lg font-medium mb-3">Catégories de Tickets</h2>
          <div className="mb-4">
            <div className="flex flex-wrap gap-2">
              {["technique", "billing", "feature_request", "bug", "other"].map((category) => (
                <div key={category} className="bg-gray-100 px-3 py-1 rounded-full flex items-center">
                  <span>{category}</span>
                  <button className="ml-2 text-gray-500">×</button>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="mt-6">
          <button className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
            Enregistrer les modifications
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings; 