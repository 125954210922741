import React, { useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* En-tête simple */}
      <header className="bg-indigo-600 p-4 text-white">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-xl font-semibold">Panel Administrateur</h1>
          <button 
            onClick={() => setMenuOpen(!menuOpen)}
            className="md:hidden"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" strokeWidth="1.5" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      </header>

      <div className="flex flex-col md:flex-row">
        {/* Navigation latérale simplifiée */}
        <aside className={`w-full md:w-64 bg-indigo-700 text-white ${menuOpen ? 'block' : 'hidden'} md:block`}>
          <nav className="p-4">
            <ul className="space-y-2">
              <li>
                <Link to="/admin" className="block p-2 hover:bg-indigo-600 rounded">
                  Tableau de bord
                </Link>
              </li>
              <li>
                <Link to="/admin/users" className="block p-2 hover:bg-indigo-600 rounded">
                  Utilisateurs
                </Link>
              </li>
              <li>
                <Link to="/admin/roles" className="block p-2 hover:bg-indigo-600 rounded">
                  Rôles & Permissions
                </Link>
              </li>
              <li>
                <Link to="/admin/analytics" className="block p-2 hover:bg-indigo-600 rounded">
                  Analytiques
                </Link>
              </li>
              <li>
                <Link to="/admin/settings" className="block p-2 hover:bg-indigo-600 rounded">
                  Paramètres
                </Link>
              </li>
              <li>
                <Link to="/admin/smtp" className="block p-2 hover:bg-indigo-600 rounded">
                  SMTP
                </Link>
              </li>
              <li>
                <Link to="/admin/activity" className="block p-2 hover:bg-indigo-600 rounded">
                  Journal d'activité
                </Link>
              </li>
              <li className="mt-8">
                <button 
                  onClick={() => navigate('/dashboard')}
                  className="block w-full text-left p-2 hover:bg-indigo-600 rounded"
                >
                  Retour à l'application
                </button>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Contenu principal minimaliste */}
        <main className="flex-grow p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout; 