import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import { collection, query, where, onSnapshot, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { toast } from 'react-toastify';

interface NotificationContextType {
  unreadMessages: number;
  markAsRead: (ticketId: string) => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType>({
  unreadMessages: 0,
  markAsRead: async () => {}
});

export const useNotifications = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setUnreadMessages(0);
      return;
    }

    // Écouter les tickets de l'utilisateur avec des messages non lus
    const q = query(
      collection(db, "tickets"),
      where("userId", "==", currentUser.uid),
      where("hasUnreadStaffMessage", "==", true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const count = snapshot.docs.length;
      setUnreadMessages(count);
      
      // Afficher une notification toast pour chaque nouveau message
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          const ticket = change.doc.data();
          if (ticket.hasUnreadStaffMessage) {
            // Vérifier si le ticket vient d'être modifié avec un nouveau message
            const updatedAt = ticket.updatedAt as Timestamp;
            const now = new Date();
            const diffInSeconds = (now.getTime() - updatedAt.toDate().getTime()) / 1000;
            
            // Seulement notifier si le message est récent (moins de 30 secondes)
            if (diffInSeconds < 30) {
              toast.info(`Nouveau message dans le ticket: ${ticket.title}`, {
                onClick: () => {
                  window.location.href = `/tickets/${change.doc.id}`;
                }
              });
            }
          }
        }
      });
    });

    return () => unsubscribe();
  }, [currentUser]);

  const markAsRead = async (ticketId: string) => {
    if (!currentUser) return;
    
    try {
      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, {
        hasUnreadStaffMessage: false
      });
    } catch (error) {
      console.error("Erreur lors du marquage comme lu:", error);
    }
  };

  return (
    <NotificationContext.Provider value={{ unreadMessages, markAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext; 