import { Timestamp } from "firebase/firestore";

export type TicketStatus = "open" | "in_progress" | "resolved" | "closed";
export type TicketPriority = "low" | "medium" | "high" | "urgent";
export type TicketCategory = "technical" | "billing" | "feature_request" | "bug" | "other";

export interface TicketMessage {
  id: string;
  ticketId?: string;
  userId?: string;
  userRole: "support" | "user" | "admin" | "client" | "staff";
  content: string;
  attachments?: string[];
  createdAt: Timestamp;
  createdBy?: string;
  createdByName?: string;
  isRead?: boolean;
}

export interface Ticket {
  id: string;
  userId: string;
  userEmail?: string;
  title: string;
  description: string;
  category: TicketCategory;
  status: TicketStatus;
  priority: TicketPriority;
  assignedTo?: string;
  assignedToEmail?: string;
  hasUnreadStaffMessage?: boolean;
  hasUnreadUserMessage?: boolean;
  messages: TicketMessage[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
  resolvedAt?: Timestamp;
}

export interface TicketFilter {
  status?: TicketStatus[];
  priority?: TicketPriority[];
  category?: TicketCategory[];
  assignedTo?: string;
  dateRange?: {
    start: Date;
    end: Date;
  };
}

export interface TicketStats {
  total: number;
  openTickets: number;
  resolvedToday: number;
  averageResponseTime: number; // en minutes
  byStatus: Record<TicketStatus, number>;
  byPriority: Record<TicketPriority, number>;
  byCategory: Record<TicketCategory, number>;
}

export const TICKET_STATUS_LABELS: Record<TicketStatus, string> = {
  open: "Ouvert",
  in_progress: "En cours",
  resolved: "Résolu",
  closed: "Fermé"
};

export const TICKET_PRIORITY_LABELS: Record<TicketPriority, string> = {
  low: "Faible",
  medium: "Moyenne",
  high: "Haute",
  urgent: "Urgente"
};

export const TICKET_CATEGORY_LABELS: Record<TicketCategory, string> = {
  technical: "Technique",
  billing: "Facturation",
  feature_request: "Demande de fonctionnalité",
  bug: "Bug",
  other: "Autre"
}; 