import React from 'react';

const Cookies: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Politique des Cookies</h1>
      
      <div className="prose prose-lg">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Qu'est-ce qu'un Cookie ?</h2>
          <p className="text-gray-600 mb-4">
            Un cookie est un petit fichier texte stocké sur votre ordinateur ou appareil mobile 
            lorsque vous visitez un site web. Les cookies nous permettent de reconnaître votre 
            appareil et d'améliorer votre expérience utilisateur.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Types de Cookies Utilisés</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-2">Cookies Essentiels</h3>
              <p className="text-gray-600">
                Nécessaires au fonctionnement du site. Ils permettent la navigation et 
                l'utilisation des fonctionnalités de base.
              </p>
            </div>
            
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-2">Cookies de Performance</h3>
              <p className="text-gray-600">
                Collectent des informations anonymes sur l'utilisation du site pour nous 
                aider à l'améliorer.
              </p>
            </div>
            
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-2">Cookies de Fonctionnalité</h3>
              <p className="text-gray-600">
                Mémorisent vos préférences pour personnaliser votre expérience.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Comment Nous Utilisons les Cookies</h2>
          <p className="text-gray-600 mb-4">
            Nous utilisons les cookies pour :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Maintenir votre session connectée</li>
            <li>Mémoriser vos préférences de langue</li>
            <li>Analyser l'utilisation du site</li>
            <li>Améliorer nos services</li>
            <li>Assurer la sécurité du site</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Gestion des Cookies</h2>
          <p className="text-gray-600 mb-4">
            Vous pouvez contrôler et/ou supprimer les cookies comme vous le souhaitez. 
            Vous pouvez :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Accepter ou refuser les cookies via notre bannière de consentement</li>
            <li>Supprimer tous les cookies déjà présents sur votre appareil</li>
            <li>Configurer votre navigateur pour bloquer les cookies</li>
          </ul>
          <p className="text-gray-600 mb-4">
            Notez que le blocage de certains cookies peut affecter votre expérience sur 
            notre site et limiter certaines fonctionnalités.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Cookies Tiers</h2>
          <p className="text-gray-600 mb-4">
            Nous utilisons également des services tiers qui peuvent placer des cookies 
            sur votre appareil :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Google Analytics (analyse d'audience)</li>
            <li>Stripe (paiement)</li>
            <li>Intercom (support client)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Plus d'Informations</h2>
          <p className="text-gray-600 mb-4">
            Pour plus d'informations sur les cookies et vos droits, vous pouvez consulter :
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>
              <a href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi" 
                 className="text-indigo-600 hover:text-indigo-800">
                Le site de la CNIL
              </a>
            </li>
            <li>
              <a href="https://www.allaboutcookies.org/" 
                 className="text-indigo-600 hover:text-indigo-800">
                All About Cookies
              </a>
            </li>
          </ul>
        </section>

        <section>
          <p className="text-sm text-gray-500">
            Dernière mise à jour : {new Date().toLocaleDateString('fr-FR')}
          </p>
        </section>
      </div>
    </div>
  );
};

export default Cookies; 