import React, { useState, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';

interface EmailTemplate {
  id: string;
  name: string;
  subject: string;
  content: string;
}

interface SMTPServer {
  id: string;
  name: string;
  host: string;
  port: number;
  username: string;
  status: 'connected' | 'error' | 'pending';
}

interface SenderVariation {
  name: string;
  email: string;
}

interface SubjectVariation {
  text: string;
}

const SenderMail: React.FC = () => {
  const { currentUser } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // États pour les données
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [emailCount, setEmailCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  
  // États pour les options rotatives
  const [rotateSmtp, setRotateSmtp] = useState<boolean>(true);
  const [rotateSubject, setRotateSubject] = useState<boolean>(true);
  const [rotateSenderName, setRotateSenderName] = useState<boolean>(true);
  const [rotateSenderFrom, setRotateSenderFrom] = useState<boolean>(true);
  const [rotateTemplate, setRotateTemplate] = useState<boolean>(false);
  
  // Templates et serveurs SMTP simulés
  const templates: EmailTemplate[] = [
    {
      id: '1',
      name: 'Newsletter mensuelle',
      subject: 'Découvrez nos dernières nouveautés',
      content: `<div>Contenu de la newsletter mensuelle...</div>`
    },
    {
      id: '2',
      name: 'Confirmation de commande',
      subject: 'Merci pour votre commande !',
      content: `<div>Contenu de confirmation de commande...</div>`
    },
    {
      id: '3',
      name: 'Rappel de rendez-vous',
      subject: 'Votre rendez-vous approche',
      content: `<div>Contenu de rappel de rendez-vous...</div>`
    }
  ];
  
  const smtpServers: SMTPServer[] = [
    {
      id: '1',
      name: 'Gmail Principal',
      host: 'smtp.gmail.com',
      port: 587,
      username: 'mail@gmail.com',
      status: 'connected'
    },
    {
      id: '2',
      name: 'Serveur SMTP Entreprise',
      host: 'smtp.enterprise.com',
      port: 587,
      username: 'mail@enterprise.com',
      status: 'connected'
    }
  ];
  
  const senderVariations: SenderVariation[] = [
    { name: 'Service Client', email: 'support@example.com' },
    { name: 'Jean Dupont', email: 'jean.dupont@example.com' },
    { name: 'Marie Martin', email: 'marie.martin@example.com' }
  ];
  
  const subjectVariations: SubjectVariation[] = [
    { text: 'Offre exceptionnelle pour vous !' },
    { text: 'Une opportunité à ne pas manquer' },
    { text: 'Découvrez nos dernières nouveautés' }
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      
      // Vérifier le type de fichier
      if (selectedFile.type !== 'text/plain' && selectedFile.type !== 'text/csv' && !selectedFile.name.endsWith('.csv') && !selectedFile.name.endsWith('.txt')) {
        setError('Veuillez télécharger un fichier CSV ou TXT.');
        return;
      }
      
      // Vérifier la taille du fichier (max 10MB)
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('Le fichier est trop grand. La taille maximale est de 10MB.');
        return;
      }

      // Lire le fichier pour compter les adresses email
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          const content = event.target.result;
          const emails = content.split(/[\n,;]/).filter(email => 
            email.trim().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
          );
          
          setEmailCount(emails.length);
          setFile(selectedFile);
        }
      };
      
      reader.readAsText(selectedFile);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const dataTransferRef = {
        target: {
          files: e.dataTransfer.files
        }
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      
      handleFileChange(dataTransferRef);
    }
  };

  const resetFileInput = () => {
    setFile(null);
    setEmailCount(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSendEmail = async () => {
    if (!selectedTemplate) {
      setError('Veuillez sélectionner un template.');
      return;
    }
    
    if (!file) {
      setError('Veuillez télécharger une liste d\'emails.');
      return;
    }
    
    setSending(true);
    setCurrentProgress(0);
    
    try {
      // Simulation d'envoi d'emails
      const totalEmails = emailCount;
      let processed = 0;
      
      while (processed < totalEmails) {
        // Simuler l'envoi par lots de 10% des emails
        const batchSize = Math.ceil(totalEmails * 0.1);
        const toProcess = Math.min(batchSize, totalEmails - processed);
        
        // Simuler un délai d'envoi
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        processed += toProcess;
        const progress = Math.round((processed / totalEmails) * 100);
        setCurrentProgress(progress);
        
        if (processed >= totalEmails) {
          break;
        }
      }
      
      // Envoi terminé
      alert(`${totalEmails} emails ont été envoyés avec succès !`);
    } catch (err) {
      setError('Une erreur s\'est produite lors de l\'envoi des emails. Veuillez réessayer.');
      console.error('Error sending emails:', err);
    } finally {
      setSending(false);
    }
  };

  const renderFileUpload = () => (
    <div 
      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="space-y-1 text-center">
        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
          <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <div className="flex text-sm text-gray-600">
          <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
            <span>Télécharger un fichier</span>
            <input 
              ref={fileInputRef}
              id="file-upload" 
              name="file-upload" 
              type="file" 
              className="sr-only" 
              accept=".csv,.txt" 
              onChange={handleFileChange}
            />
          </label>
          <p className="pl-1">ou glisser-déposer</p>
        </div>
        <p className="text-xs text-gray-500">CSV, TXT jusqu'à 10MB</p>
      </div>
    </div>
  );

  const renderFileInfo = () => (
    <div className="mt-4 bg-gray-50 p-4 rounded-md">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-900">{file?.name}</p>
          <p className="text-xs text-gray-500">{emailCount} adresses email détectées</p>
        </div>
        <button 
          type="button" 
          className="ml-4 inline-flex text-sm font-medium text-red-600 hover:text-red-500"
          onClick={resetFileInput}
        >
          Supprimer
        </button>
      </div>
    </div>
  );

  const renderProgressBar = () => (
    <div className="mt-6">
      <div className="flex justify-between text-sm mb-1">
        <span>Progression</span>
        <span>{currentProgress}%</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div 
          className="bg-indigo-600 h-2.5 rounded-full" 
          style={{ width: `${currentProgress}%` }}
        ></div>
      </div>
    </div>
  );

  const renderRotationOption = (
    label: string, 
    value: boolean, 
    onChange: (checked: boolean) => void,
    items: number
  ) => (
    <div className="border rounded-lg p-4">
      <h4 className="font-medium text-gray-800 mb-2">{label}</h4>
      <div className="flex items-center justify-between">
        <span className="text-sm text-gray-600">
          Rotation active ({items} options)
        </span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input 
            type="checkbox" 
            value="" 
            className="sr-only peer" 
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">Sender Mail</h2>
          <div className="text-sm text-gray-500">
            Solde disponible: <span className="font-semibold text-green-600">250 €</span>
          </div>
        </div>
      </div>
      
      <div className="p-6">
        {/* Options de rotation */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Options rotatives</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {renderRotationOption('SMTP', rotateSmtp, setRotateSmtp, smtpServers.length)}
            {renderRotationOption('Sujet', rotateSubject, setRotateSubject, subjectVariations.length)}
            {renderRotationOption('Sender Name', rotateSenderName, setRotateSenderName, senderVariations.length)}
            {renderRotationOption('Sender From', rotateSenderFrom, setRotateSenderFrom, senderVariations.length)}
            {renderRotationOption('Template', rotateTemplate, setRotateTemplate, templates.length)}
          </div>
        </div>
        
        {/* Configuration de l'envoi */}
        <div className="mb-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Configurer votre envoi</h3>
          
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Template</label>
              <select 
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                <option value="">Sélectionnez un template</option>
                {templates.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>
            
            {selectedTemplate && !rotateTemplate && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Aperçu du template</label>
                <div className="mt-1 p-4 border border-gray-300 rounded-md bg-gray-50">
                  <h4 className="font-medium mb-2">
                    {templates.find(t => t.id === selectedTemplate)?.name}
                  </h4>
                  <p className="text-sm text-gray-600 mb-2">
                    Sujet: {templates.find(t => t.id === selectedTemplate)?.subject}
                  </p>
                </div>
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Liste d'emails</label>
              {!file ? renderFileUpload() : renderFileInfo()}
            </div>
            
            {error && (
              <div className="text-sm text-red-600">
                {error}
              </div>
            )}
          </div>
        </div>
        
        {sending && renderProgressBar()}
        
        <div className="flex justify-end mt-6">
          <button 
            className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSendEmail}
            disabled={!selectedTemplate || !file || sending}
          >
            {sending ? (
              <div className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Envoi en cours...
              </div>
            ) : 'Lancer l\'envoi'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SenderMail; 