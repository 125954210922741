import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../hooks/usePermissions";
import useTickets from "../../hooks/useTickets";
import { Ticket, TICKET_STATUS_LABELS, TICKET_PRIORITY_LABELS, TICKET_CATEGORY_LABELS } from "../../types/tickets";
import { Timestamp } from "firebase/firestore";
import AppLayout from "../../components/AppLayout";

const AdminTickets: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();
  const [isLoading, setIsLoading] = useState(true);
  const { tickets, loading, error, stats, updateTicket } = useTickets();
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [statusFilter, setStatusFilter] = useState<string>("all");

  useEffect(() => {
    // Vérifier si l'utilisateur est un administrateur
    if (!isAdmin()) {
      navigate("/dashboard");
    }
    setIsLoading(false);
  }, [isAdmin, navigate]);

  const filteredTickets = tickets.filter(ticket => {
    if (statusFilter !== "all" && ticket.status !== statusFilter) return false;
    return true;
  });

  const handleChangeStatus = async (ticketId: string, status: string) => {
    try {
      await updateTicket(ticketId, { status: status as any });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
    }
  };

  const handleAssignToMe = async (ticketId: string) => {
    try {
      await updateTicket(ticketId, { assignedTo: "current-admin-id" });
    } catch (error) {
      console.error("Erreur lors de l'assignation du ticket:", error);
    }
  };

  if (isLoading || loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      </div>
    );
  }

  return (
    <AppLayout title="Gestion des tickets" hidePageTitle={true}>
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Total</h3>
            <p className="text-3xl font-bold text-indigo-600">{stats.total}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">En attente</h3>
            <p className="text-3xl font-bold text-yellow-600">{stats.byStatus.open}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">En cours</h3>
            <p className="text-3xl font-bold text-blue-600">{stats.byStatus.in_progress}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Résolus</h3>
            <p className="text-3xl font-bold text-green-600">{stats.byStatus.resolved + stats.byStatus.closed}</p>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Liste des tickets</h3>
            <div className="flex space-x-2">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="all">Tous les statuts</option>
                {Object.entries(TICKET_STATUS_LABELS).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Titre
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Utilisateur
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Statut
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Priorité
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTickets.map((ticket) => (
                  <tr key={ticket.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {ticket.id.slice(0, 8)}...
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {ticket.title}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {ticket.userId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                        ${ticket.status === 'open' ? 'bg-yellow-100 text-yellow-800' : ''}
                        ${ticket.status === 'in_progress' ? 'bg-blue-100 text-blue-800' : ''}
                        ${ticket.status === 'resolved' ? 'bg-green-100 text-green-800' : ''}
                        ${ticket.status === 'closed' ? 'bg-gray-100 text-gray-800' : ''}
                      `}>
                        {TICKET_STATUS_LABELS[ticket.status]}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {TICKET_PRIORITY_LABELS[ticket.priority]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {(ticket.createdAt as Timestamp).toDate().toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex space-x-2">
                        <select
                          value={ticket.status}
                          onChange={(e) => handleChangeStatus(ticket.id, e.target.value)}
                          className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                          {Object.entries(TICKET_STATUS_LABELS).map(([value, label]) => (
                            <option key={value} value={value}>{label}</option>
                          ))}
                        </select>
                        <button
                          onClick={() => handleAssignToMe(ticket.id)}
                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                        >
                          Assigner
                        </button>
                        <button
                          onClick={() => setSelectedTicket(ticket)}
                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                        >
                          Détails
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {selectedTicket && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-900">
                  Détails du ticket: {selectedTicket.title}
                </h2>
                <button
                  onClick={() => setSelectedTicket(null)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Informations</h3>
                  <dl className="space-y-1">
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">ID:</dt>
                      <dd className="text-sm text-gray-900">{selectedTicket.id}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Utilisateur:</dt>
                      <dd className="text-sm text-gray-900">{selectedTicket.userId}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Statut:</dt>
                      <dd className="text-sm text-gray-900">{TICKET_STATUS_LABELS[selectedTicket.status]}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Priorité:</dt>
                      <dd className="text-sm text-gray-900">{TICKET_PRIORITY_LABELS[selectedTicket.priority]}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Catégorie:</dt>
                      <dd className="text-sm text-gray-900">{TICKET_CATEGORY_LABELS[selectedTicket.category]}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Date de création:</dt>
                      <dd className="text-sm text-gray-900">
                        {(selectedTicket.createdAt as Timestamp).toDate().toLocaleString()}
                      </dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="text-sm font-medium text-gray-500">Dernière mise à jour:</dt>
                      <dd className="text-sm text-gray-900">
                        {(selectedTicket.updatedAt as Timestamp).toDate().toLocaleString()}
                      </dd>
                    </div>
                  </dl>
                </div>
                
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Description</h3>
                  <p className="text-sm text-gray-600 bg-gray-50 p-3 rounded">
                    {selectedTicket.description}
                  </p>
                </div>
              </div>
              
              <div className="mb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">Messages</h3>
                {selectedTicket.messages.length > 0 ? (
                  <div className="space-y-4">
                    {selectedTicket.messages.map((message, index) => (
                      <div key={index} className={`flex ${message.userRole === 'support' ? 'justify-end' : 'justify-start'}`}>
                        <div className={`max-w-lg rounded-lg px-4 py-2 ${message.userRole === 'support' ? 'bg-indigo-100' : 'bg-gray-100'}`}>
                          <div className="text-xs text-gray-500">
                            {message.userRole === 'support' ? 'Support' : 'Utilisateur'} - 
                            {(message.createdAt as Timestamp).toDate().toLocaleString()}
                          </div>
                          <div className="mt-1">{message.content}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-500">Aucun message pour ce ticket.</p>
                )}
              </div>
              
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setSelectedTicket(null)}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  Fermer
                </button>
                <button
                  onClick={() => handleChangeStatus(selectedTicket.id, 'resolved')}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
                >
                  Marquer comme résolu
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default AdminTickets; 