import React from 'react';
import { Link } from 'react-router-dom';

const Documentation: React.FC = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          {/* Sidebar */}
          <div className="hidden lg:block lg:col-span-3">
            <nav className="sticky top-6 space-y-1">
              <a href="#introduction" className="bg-gray-100 text-indigo-700 hover:bg-gray-100 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Introduction
              </a>
              <a href="#getting-started" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Démarrer
              </a>
              <a href="#smtp-config" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Configuration SMTP
              </a>
              <a href="#templates" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Templates
              </a>
              <a href="#campaigns" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Campagnes
              </a>
              <a href="#analytics" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Analytiques
              </a>
              <a href="#api-docs" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                Documentation API
              </a>
              <a href="#faq" className="text-gray-900 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                FAQ
              </a>
            </nav>
          </div>

          {/* Main content */}
          <div className="mt-8 lg:mt-0 lg:col-span-9">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <section id="introduction" className="mb-12">
                  <h1 className="text-3xl font-bold text-gray-900 mb-4">Documentation Automatic Pancake</h1>
                  <p className="text-gray-600 mb-6">
                    Bienvenue dans la documentation d'Automatic Pancake, la plateforme d'emailing qui simplifie vos campagnes marketing et maximise votre délivrabilité.
                  </p>
                  <p className="text-gray-600 mb-6">
                    Cette documentation vous guidera à travers toutes les fonctionnalités de notre plateforme, de la configuration initiale à l'analyse de vos campagnes.
                  </p>
                  <div className="bg-indigo-50 p-4 rounded-md">
                    <h3 className="text-indigo-800 text-lg font-medium mb-2">Besoin d'aide supplémentaire ?</h3>
                    <p className="text-indigo-700 mb-3">
                      Si vous ne trouvez pas les réponses que vous cherchez, vous pouvez consulter notre centre d'aide ou contacter directement notre support.
                    </p>
                    <div className="flex space-x-4">
                      <Link to="/help" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200">
                        Centre d'aide
                      </Link>
                      <Link to="/contact" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                        Contacter le support
                      </Link>
                    </div>
                  </div>
                </section>

                <section id="getting-started" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Démarrer avec Automatic Pancake</h2>
                  <div className="prose prose-indigo max-w-none">
                    <h3>1. Créer un compte</h3>
                    <p>
                      Pour commencer, <Link to="/register" className="text-indigo-600 hover:text-indigo-800">créez un compte</Link> sur notre plateforme. Vous pouvez démarrer avec notre offre gratuite et passer à un forfait payant à tout moment.
                    </p>
                    
                    <h3>2. Configurer votre premier serveur SMTP</h3>
                    <p>
                      Après avoir créé votre compte, vous devez configurer au moins un serveur SMTP pour commencer à envoyer des emails. Voir la section <a href="#smtp-config" className="text-indigo-600 hover:text-indigo-800">Configuration SMTP</a> pour plus de détails.
                    </p>
                    
                    <h3>3. Créer un template</h3>
                    <p>
                      Avant de lancer une campagne, vous devrez créer un template d'email. Notre éditeur visuel vous permet de concevoir des emails attrayants sans connaissances techniques.
                    </p>
                    
                    <h3>4. Importer vos contacts</h3>
                    <p>
                      Importez vos contacts depuis un fichier CSV ou utilisez notre API pour synchroniser vos listes depuis votre CRM.
                    </p>
                    
                    <h3>5. Lancer votre première campagne</h3>
                    <p>
                      Une fois ces étapes complétées, vous êtes prêt à lancer votre première campagne!
                    </p>
                  </div>
                </section>

                <section id="smtp-config" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Configuration SMTP</h2>
                  <div className="prose prose-indigo max-w-none">
                    <p>
                      Pour envoyer des emails, vous devez configurer au moins un serveur SMTP. Automatic Pancake prend en charge tous les principaux fournisseurs SMTP.
                    </p>
                    
                    <h3>Informations requises</h3>
                    <ul>
                      <li>Nom du serveur (pour l'identification dans votre compte)</li>
                      <li>Hôte SMTP (par exemple, smtp.gmail.com)</li>
                      <li>Port SMTP (généralement 587 ou 465)</li>
                      <li>Nom d'utilisateur</li>
                      <li>Mot de passe</li>
                      <li>Protocole de sécurité (TLS/SSL)</li>
                    </ul>

                    <h3>Configuration de fournisseurs populaires</h3>
                    <p>
                      Nous proposons des configurations prédéfinies pour les fournisseurs SMTP populaires comme Gmail, Outlook, Amazon SES, Sendgrid, etc.
                    </p>

                    <h3>Test de configuration</h3>
                    <p>
                      Après avoir ajouté vos informations SMTP, utilisez notre outil de test pour vérifier que tout fonctionne correctement avant d'envoyer des campagnes réelles.
                    </p>
                  </div>
                </section>

                <section id="templates" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Création et gestion des templates</h2>
                  <div className="prose prose-indigo max-w-none">
                    <p>
                      Les templates sont au cœur de votre stratégie d'emailing. Automatic Pancake vous offre des outils puissants pour créer, personnaliser et gérer vos templates d'emails.
                    </p>
                    
                    <h3>Types de templates</h3>
                    <ul>
                      <li><strong>Templates prédéfinis</strong> - Utilisez nos modèles professionnels comme point de départ</li>
                      <li><strong>Templates personnalisés</strong> - Créez vos propres designs à partir de zéro</li>
                      <li><strong>Templates HTML</strong> - Importez votre propre code HTML pour un contrôle total</li>
                    </ul>

                    <h3>Éditeur visuel</h3>
                    <p>
                      Notre éditeur visuel par glisser-déposer vous permet de créer des emails attrayants sans connaissances techniques :
                    </p>
                    <ul>
                      <li>Ajoutez facilement des images, boutons, textes et autres éléments</li>
                      <li>Personnalisez les couleurs, polices et espacement</li>
                      <li>Prévisualisez votre email sur différents appareils (desktop, mobile, tablette)</li>
                      <li>Test A/B intégré pour optimiser vos designs</li>
                    </ul>

                    <h3>Variables et personnalisation</h3>
                    <p>
                      Utilisez nos variables dynamiques pour personnaliser chaque email :
                    </p>
                    <div className="bg-gray-100 p-4 rounded-md overflow-auto">
                      <p className="font-mono">
                        Bonjour [prenom] [nom],<br/>
                        Merci pour votre inscription à [nom_entreprise].
                      </p>
                    </div>
                    <p className="mt-4">
                      Nos variables incluent :
                    </p>
                    <ul>
                      <li>Informations de contact (prénom, nom, entreprise, etc.)</li>
                      <li>Dates personnalisées avec formatage</li>
                      <li>Contenu conditionnel basé sur les attributs des contacts</li>
                      <li>Variables personnalisées que vous pouvez définir</li>
                    </ul>

                    <h3>Rotation de contenu</h3>
                    <p>
                      Pour optimiser la délivrabilité, vous pouvez configurer plusieurs variantes d'un même élément qui seront alternées automatiquement :
                    </p>
                    <ul>
                      <li>Sujets d'emails alternatifs</li>
                      <li>Noms d'expéditeurs différents</li>
                      <li>Variantes d'introduction et de conclusion</li>
                      <li>Images alternatives</li>
                    </ul>
                    <p>
                      Notre système choisira aléatoirement entre ces variantes pour chaque envoi, améliorant ainsi vos taux de délivrabilité.
                    </p>
                  </div>
                </section>

                <section id="campaigns" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Gestion des campagnes</h2>
                  <div className="prose prose-indigo max-w-none">
                    <p>
                      Une campagne est une série d'emails envoyés à un groupe spécifique de destinataires. Automatic Pancake offre des fonctionnalités avancées pour planifier, exécuter et analyser vos campagnes.
                    </p>
                    
                    <h3>Types de campagnes</h3>
                    <ul>
                      <li><strong>Campagne ponctuelle</strong> - Envoi unique à une liste de contacts</li>
                      <li><strong>Campagne automatisée</strong> - Séquence d'emails déclenchée par des actions spécifiques</li>
                      <li><strong>Campagne récurrente</strong> - Emails envoyés régulièrement (newsletter hebdomadaire, etc.)</li>
                      <li><strong>Campagne A/B testing</strong> - Test de différentes versions pour optimiser les performances</li>
                    </ul>

                    <h3>Création d'une campagne</h3>
                    <ol>
                      <li>Choisissez le type de campagne</li>
                      <li>Sélectionnez ou créez un template</li>
                      <li>Définissez l'objet et les paramètres d'expéditeur (ou utilisez la rotation)</li>
                      <li>Sélectionnez votre liste de contacts ou segments</li>
                      <li>Définissez le planning d'envoi (immédiat, programmé ou récurrent)</li>
                      <li>Configurez les paramètres avancés (tracking, réponses, etc.)</li>
                    </ol>

                    <h3>Paramètres avancés</h3>
                    <p>
                      Personnalisez chaque aspect de votre campagne :
                    </p>
                    <ul>
                      <li><strong>Limitation de débit</strong> - Contrôlez le nombre d'emails envoyés par heure</li>
                      <li><strong>Rotation de serveurs SMTP</strong> - Répartissez les envois entre plusieurs serveurs</li>
                      <li><strong>Options de suivi</strong> - Configurez le tracking des ouvertures, clics, et conversions</li>
                      <li><strong>Gestion des rebonds</strong> - Paramètres de traitement des emails non délivrés</li>
                      <li><strong>Gestion des désabonnements</strong> - Personnalisez le processus de désabonnement</li>
                    </ul>

                    <h3>Planification et déclencheurs</h3>
                    <p>
                      Pour les campagnes automatisées, configurez des déclencheurs basés sur :
                    </p>
                    <ul>
                      <li>Actions des utilisateurs (inscription, achat, etc.)</li>
                      <li>Comportement email (ouverture, clic, non-ouverture)</li>
                      <li>Temps écoulé depuis un événement</li>
                      <li>Modifications d'attributs des contacts</li>
                    </ul>

                    <h3>Test et prévisualisation</h3>
                    <p>
                      Avant de lancer votre campagne, utilisez nos outils de test :
                    </p>
                    <ul>
                      <li>Envoi d'emails de test à vous-même ou à votre équipe</li>
                      <li>Prévisualisation sur différents appareils et clients de messagerie</li>
                      <li>Vérification des liens et des variables</li>
                      <li>Test anti-spam pour évaluer la délivrabilité</li>
                    </ul>
                  </div>
                </section>

                <section id="analytics" className="mb-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Analytiques et rapports</h2>
                  <div className="prose prose-indigo max-w-none">
                    <p>
                      Automatic Pancake offre des outils d'analyse complets pour mesurer et optimiser les performances de vos campagnes.
                    </p>
                    
                    <h3>Métriques clés</h3>
                    <ul>
                      <li><strong>Taux de délivrabilité</strong> - Pourcentage d'emails correctement délivrés</li>
                      <li><strong>Taux d'ouverture</strong> - Pourcentage d'emails ouverts par les destinataires</li>
                      <li><strong>Taux de clic</strong> - Pourcentage de destinataires ayant cliqué sur un lien</li>
                      <li><strong>Taux de conversion</strong> - Pourcentage de destinataires ayant effectué l'action souhaitée</li>
                      <li><strong>Taux de désabonnement</strong> - Pourcentage de destinataires s'étant désabonnés</li>
                      <li><strong>Taux de rebond</strong> - Pourcentage d'emails non délivrés</li>
                    </ul>

                    <h3>Tableaux de bord</h3>
                    <p>
                      Consultez vos performances en temps réel via nos tableaux de bord intuitifs et personnalisables.
                    </p>

                    <p>
                      Cette section sera développée dans une prochaine mise à jour.
                    </p>
                  </div>
                </section>

                <div className="text-center mt-16">
                  <p className="text-gray-600 mb-6">
                    Cette documentation est régulièrement mise à jour. N'hésitez pas à consulter notre centre d'aide pour des informations complémentaires.
                  </p>
                  <Link to="/help" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                    Consulter le centre d'aide
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation; 