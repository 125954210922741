import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { usePermissions } from '../hooks/usePermissions';

interface AdminRouteProps {
  element: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const { currentUser, loading } = useAuth();
  const { hasPermission } = usePermissions();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500" />
      </div>
    );
  }

  // Vérifier si l'utilisateur est connecté, a vérifié son email, et a les permissions admin
  if (!currentUser || currentUser.role !== 'admin' || !hasPermission('manage_users')) {
    return <Navigate to="/dashboard" />;
  }

  return <React.Fragment>{element}</React.Fragment>;
};

export default AdminRoute; 