import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  // Fonction pour gérer les liens d'ancrage
  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>, anchor: string) => {
    e.preventDefault();
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      // Si on n'est pas sur la page d'accueil, rediriger vers la page d'accueil avec l'ancre
      window.location.href = `/#${anchor}`;
    }
  };

  return (
    <footer className="bg-gray-900 text-white pt-12 pb-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Section Produit */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Produit</h3>
            <ul className="space-y-2">
              <li>
                <a 
                  href="/#features" 
                  onClick={(e) => handleAnchorClick(e, "features")}
                  className="text-gray-300 hover:text-white"
                >
                  Fonctionnalités
                </a>
              </li>
              <li>
                <a 
                  href="/#pricing" 
                  onClick={(e) => handleAnchorClick(e, "pricing")}
                  className="text-gray-300 hover:text-white"
                >
                  Tarifs
                </a>
              </li>
              <li><Link to="/documentation" className="text-gray-300 hover:text-white">Documentation</Link></li>
              <li><Link to="/api" className="text-gray-300 hover:text-white">API</Link></li>
            </ul>
          </div>

          {/* Section Support */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/help" className="text-gray-300 hover:text-white">Centre d'aide</Link></li>
              <li><Link to="/contact" className="text-gray-300 hover:text-white">Contact</Link></li>
              <li><Link to="/status" className="text-gray-300 hover:text-white">Statut</Link></li>
              <li><Link to="/faq" className="text-gray-300 hover:text-white">FAQ</Link></li>
            </ul>
          </div>

          {/* Section Entreprise */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Entreprise</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-300 hover:text-white">À propos</Link></li>
              <li><Link to="/blog" className="text-gray-300 hover:text-white">Blog</Link></li>
              <li><Link to="/careers" className="text-gray-300 hover:text-white">Carrières</Link></li>
              <li><Link to="/press" className="text-gray-300 hover:text-white">Presse</Link></li>
            </ul>
          </div>

          {/* Section Newsletter */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
            <p className="text-gray-300 mb-4">
              Abonnez-vous pour recevoir les dernières actualités
            </p>
            <form className="flex">
              <input
                type="email"
                placeholder="Votre email"
                className="px-4 py-2 w-full text-gray-900 rounded-l-md focus:outline-none"
                required
              />
              <button
                type="submit"
                className="bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-r-md text-white font-medium"
              >
                S'abonner
              </button>
            </form>
          </div>
        </div>

        {/* Section du bas */}
        <div className="mt-8 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm">
              © 2024-2025 Automatic Pancake. Tous droits réservés.
            </div>
            <div className="flex gap-6 mt-4 md:mt-0">
              <Link to="/privacy" className="text-gray-400 hover:text-white text-sm">Confidentialité</Link>
              <Link to="/terms" className="text-gray-400 hover:text-white text-sm">Conditions</Link>
              <Link to="/cookies" className="text-gray-400 hover:text-white text-sm">Cookies</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 