import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { Ticket, TICKET_STATUS_LABELS, TICKET_PRIORITY_LABELS, TICKET_CATEGORY_LABELS } from "../types/tickets";
import { Timestamp } from "firebase/firestore";
import { ExclamationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import CreateTicket from "../components/support/CreateTicket";
import useTickets from "../hooks/useTickets";
import { usePermissions } from "../hooks/usePermissions";
import { useAuth } from "../contexts/AuthContext";

const Support: React.FC = () => {
  const { tickets, stats, loading, error } = useTickets();
  const [isCreating, setIsCreating] = useState(false);
  const [filter, setFilter] = useState<string>("all");
  const [priorityFilter, setPriorityFilter] = useState<string>("all");
  const [categoryFilter, setCategoryFilter] = useState<string>("all");
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { currentUser } = useAuth();
  
  // Vérifier si l'utilisateur est un administrateur ou a le rôle "support"
  const isAdmin = currentUser?.role === "admin";
  const isSupport = currentUser?.role === "support" || permissions.hasPermission("manage_tickets");
  
  const fadeIn = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const filteredTickets = tickets.filter(ticket => {
    // Filtre de statut
    if (filter !== "all" && ticket.status !== filter) return false;
    
    // Filtre de priorité
    if (priorityFilter !== "all" && ticket.priority !== priorityFilter) return false;
    
    // Filtre de catégorie
    if (categoryFilter !== "all" && ticket.category !== categoryFilter) return false;
    
    return true;
  });

  const handleTicketClick = (ticket: Ticket) => {
    navigate(`/tickets/${ticket.id}`);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center text-red-600">
          Une erreur est survenue lors du chargement des tickets.
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.div {...fadeIn} className="mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">Support</h1>
          {!isAdmin && !isSupport && (
            <button
              onClick={() => setIsCreating(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center"
            >
              <PlusIcon className="w-5 h-5 mr-1" />
              Nouveau ticket
            </button>
          )}
        </div>
      </motion.div>

      {/* Statistiques pour les administrateurs et le support */}
      {(isAdmin || isSupport) && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Total des tickets</h3>
            <p className="text-3xl font-bold text-indigo-600">{stats?.total || 0}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Tickets ouverts</h3>
            <p className="text-3xl font-bold text-yellow-600">{stats?.openTickets || 0}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Tickets résolus</h3>
            <p className="text-3xl font-bold text-green-600">{stats?.resolvedToday || 0}</p>
          </div>
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Temps de réponse moyen</h3>
            <p className="text-3xl font-bold text-blue-600">
              {stats?.averageResponseTime ? `${stats.averageResponseTime} min` : "N/A"}
            </p>
          </div>
        </div>
      )}

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <h2 className="text-xl font-semibold text-gray-900">Liste des tickets</h2>
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => setFilter("all")}
                className={`px-3 py-2 text-sm rounded-md ${
                  filter === "all"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                Tous
              </button>
              <button
                onClick={() => setFilter("open")}
                className={`px-3 py-2 text-sm rounded-md ${
                  filter === "open"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                Ouverts
              </button>
              <button
                onClick={() => setFilter("in_progress")}
                className={`px-3 py-2 text-sm rounded-md ${
                  filter === "in_progress"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                En cours
              </button>
              <button
                onClick={() => setFilter("resolved")}
                className={`px-3 py-2 text-sm rounded-md ${
                  filter === "resolved"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                Résolus
              </button>
              <button
                onClick={() => setFilter("closed")}
                className={`px-3 py-2 text-sm rounded-md ${
                  filter === "closed"
                    ? "bg-indigo-100 text-indigo-800"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                Fermés
              </button>
            </div>
          </div>
          
          {/* Filtres supplémentaires pour admin et support */}
          {(isAdmin || isSupport) && (
            <div className="mt-4 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
              <select
                value={priorityFilter}
                onChange={(e) => setPriorityFilter(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 bg-white text-gray-700"
              >
                <option value="all">Toutes les priorités</option>
                {Object.entries(TICKET_PRIORITY_LABELS).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>

              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 bg-white text-gray-700"
              >
                <option value="all">Toutes les catégories</option>
                {Object.entries(TICKET_CATEGORY_LABELS).map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </select>
            </div>
          )}
        </div>

        {filteredTickets.length === 0 ? (
          <div className="p-6 text-center">
            <p className="text-gray-500">Aucun ticket ne correspond à vos critères.</p>
            {!isAdmin && !isSupport && (
              <div className="mt-4">
                <button
                  onClick={() => setIsCreating(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Créer un nouveau ticket
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sujet
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Statut
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Priorité
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Catégorie
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date de création
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Dernière mise à jour
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTickets.map((ticket) => (
                  <tr
                    key={ticket.id}
                    onClick={() => handleTicketClick(ticket)}
                    className={`hover:bg-gray-50 cursor-pointer ${
                      (currentUser?.role === 'client' && ticket.hasUnreadStaffMessage) || 
                      ((isAdmin || isSupport) && ticket.hasUnreadUserMessage) ? "bg-blue-50" : ""
                    }`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-indigo-600">
                        {ticket.title}
                        {((currentUser?.role === 'client' && ticket.hasUnreadStaffMessage) || 
                         ((isAdmin || isSupport) && ticket.hasUnreadUserMessage)) && (
                          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            Nouveau
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                          ${ticket.status === "open" ? "bg-yellow-100 text-yellow-800" : ""}
                          ${ticket.status === "in_progress" ? "bg-blue-100 text-blue-800" : ""}
                          ${ticket.status === "resolved" ? "bg-green-100 text-green-800" : ""}
                          ${ticket.status === "closed" ? "bg-gray-100 text-gray-800" : ""}
                        `}
                      >
                        {TICKET_STATUS_LABELS[ticket.status]}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {TICKET_PRIORITY_LABELS[ticket.priority]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {TICKET_CATEGORY_LABELS[ticket.category]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {(ticket.createdAt as Timestamp).toDate().toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {(ticket.updatedAt as Timestamp).toDate().toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isCreating && (
        <CreateTicket
          onClose={() => setIsCreating(false)}
          onTicketCreated={() => setIsCreating(false)}
        />
      )}
    </div>
  );
};

export default Support; 